import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";
// import { alpha, useTheme } from '@mui/material/styles';
// import { setDoc, doc, getDocs, getDoc, onSnapshot, collection } from "firebase/firestore";
// import { auth, db } from '../firebase/index';
import { Sectionheader, Figurecaption } from './reportBlocks';
import { Capitalize } from "./utils";
import { FinalScoreDist, IndustryTopTable } from './tableblocks';
import { FinalScoreHist, FinalSector, ScoreHeat, RadarScore } from './figureBlocks';
import { indigo } from "@mui/material/colors";

export default function Conclusion({input, sector}) {

    // const theme = useTheme()
    
    const Sector = Capitalize(sector)
    const [loading, setLoading] = useState(true)

    const heat = input.heat
    const histo = input.histo
    const industries = input.industries
    const FINS = input.scores
    const Statistics = input.stats
    const tops = input.tops

    // Score distributions
    const most_common_score = Math.max(...histo.nos)
    const no_highest_scores = histo.nos.at(-1)
    const most_common_bin = histo.nos.indexOf(Math.max(...histo.nos))
 
    const most_com_bin_1 = Math.round(10 * histo.bins[most_common_bin]) /10

    const most_com_bin_2 = Math.round(10 * histo.bins[most_common_bin + 1]) /10

    const highest_bin = Math.round(10 * histo.bins[histo.bins.length - 2] ) / 10

    const no_score_sum = histo.nos.reduce((partialSum, a) => partialSum + a, 0)
    const score_percentile = Math.round(100 * most_common_score /no_score_sum)

    const [finalScore, setFinalScore] = useState({
        series: [],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })

    const [finalTick, setFinalTick] = useState({
        "Series1": []
        }
    )

    const [figRange, setFigrange] = useState([])

    const Table10Caption = 'Table 10: Average total score for the industries in the ' + sector + ' sector'
    // const Table10Caption = 'Table 10: ' + sector + ' industries - average total score vs. average 1-year return'

    useEffect( () => {

        // console.log(FINS)

        let final_dataset = []
        let final_tickers = []

        let max_score = 0
        let min_score = 70

        for (const [key, value] of Object.entries(FINS)) {

            let fsdt = []
            let tkdt = []

            for (const [key2, value2] of Object.entries(value)) {

                if (value2[1] >= max_score) {
                    max_score = value2[1]
                }

                if (value2[1] <= min_score) {
                    min_score = value2[1]
                }

                fsdt.push( [value2[0], value2[1], value2[2]] )
                tkdt.push( key2 )
            }

            final_dataset.push({
                name: key,
                data: fsdt
            })

            final_tickers.push({tkdt})

        }


        setFigrange([min_score, max_score])
        // console.log(max_score)
        // console.log(min_score)

        setFinalScore(
            final_dataset
            // {
            // series: [final_dataset],
            //     }
            )

        setFinalTick(final_tickers)

        setLoading(false)
        return (() => {})

    }, [])


    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md: 18}, 
                        fontWeight: '300', 
                        mt: '1%',
                        lineHeight: 1.5}}>
                        In the previous chapters, ratio analysis was performed on a variety of financial metrics, covering <Box 
                        fontWeight='fontWeightMedium' display='inline'>7</Box> categories
                        of the business operations. Through rigorous statistical analysis, the distribution of each metric, including
                        average and standard deviation, was calculated for every company in the energy sector. The collected
                        information provides a more distinct view of the operational dynamics within the sector. Building upon this
                        foundation, our proprietary scoring system came into play. This system was meticulously applied to assess
                        and assign composite scores, which are weighted averages of the individual metrics within specific categories,
                        to every company in each category. These scores have been thoughtfully crafted to emphasize specific aspects
                        of a company's performance, enabling readers to make meaningful comparisons and evaluate each company's
                        standing relative to its peers in those specific areas.
                </Typography>

                <Sectionheader head={'4.1 Total score distribution'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    In the first step, the final score for each company is calculated by adding up its composite scores 
                    in each ratio category (liquidity, efficiency, etc.). This final score offers a holistic view of each 
                    company's standing in comparison to others in the sector. <Box fontWeight='fontWeightMedium' 
                    display='inline'>Figure 45</Box> presents the total scores of each company in the {sector} sector, 
                    categorized by the industry to which the company belongs, against their winsorized 1-year return.
                </Typography>

                <FinalSector series={finalScore} ticks={finalTick} range={figRange} name={''} />

                <Figurecaption caption={'Figure 45: Companies final score versus winsorized 1-year return'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Table 9</Box> displays the statistics of the final score 
                    distribution across the sector. It shows that the final scores range from a low of <Box  display='inline'
                    fontWeight='fontWeightMedium'>{Statistics.min}</Box> to a high of <Box fontWeight='fontWeightMedium' display='inline'
                    >{Statistics.max}</Box>, out of a possible perfect score of 70 (based on 7 ratio groups with a maximum score of 10 
                    each). The average (mean) final score is calculated to be <Box fontWeight='fontWeightMedium' display='inline'>
                    {Statistics.mean}</Box>.
                </Typography>

                <Figurecaption caption={'Table 9: Statistic of final score distribution'}/>

                <Box sx={{
                    border: 1, 
                    mb: '2%', 
                    width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <FinalScoreDist sector={Sector} data={ Statistics } />
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Figure 46</Box> provides a visual representation of the distribution of total scores across the sector by 
                    presenting  the number of companies within narrow score ranges. It reveals that <Box  display='inline'
                    fontWeight='fontWeightMedium'>{most_common_score}</Box> companies, approximately <Box display='inline' 
                    fontWeight='fontWeightMedium' >{score_percentile}%</Box> of the sector, fall within the score range of 
                    <Box fontWeight='fontWeightMedium' display='inline'> {most_com_bin_1} - {most_com_bin_2}</Box>, with 
                    <Box fontWeight='fontWeightMedium' display='inline'> {no_highest_scores}</Box> companies achieving a final score 
                    above <Box fontWeight='fontWeightMedium' display='inline'>{highest_bin}</Box>. On the low end, <Box fontWeight='fontWeightMedium' 
                    display='inline'>{histo.nos[0]}</Box> companies scored the final score less than <Box fontWeight='fontWeightMedium' display='inline'>
                    {Math.round( 10 * histo.bins[1] ) / 10}</Box>.
                </Typography>
                
                <FinalScoreHist histo={histo} name={''}/>

                <Figurecaption caption={'Figure 46: Distribution of total score across the sector'}/>

                <Sectionheader head={'4.2 Companies with highest final score'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Figure 47</Box> presents the breakdown of the total 
                    score for the top 10 companies with highest final score. In this figure, each column represents the 
                    composite score achieved by the company in each ratio category.
                </Typography>

                <ScoreHeat scores={heat}/>

                <Figurecaption caption={'Figure 47: Final score breakdown for the top 10 companies'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    As reader can observe, among the <Box fontWeight='fontWeightMedium' display='inline'>{no_score_sum}</Box> companies 
                    the total score was computed for, currently ticker <Box fontWeight='fontWeightMedium' display='inline'>
                    {tops.tickers[0]}</Box> ranks first with overal score of <Box fontWeight='fontWeightMedium' display='inline'>
                    {tops.final[0]}</Box>. 
                    Tickers <Box fontWeight='fontWeightMedium' display='inline'>{tops.tickers[1]}</Box> and 
                    <Box fontWeight='fontWeightMedium' display='inline'> {tops.tickers[2]}</Box> ranks second and third with scores of
                    <Box fontWeight='fontWeightMedium' display='inline'> {tops.final[1]}</Box> and 
                    <Box fontWeight='fontWeightMedium' display='inline'> {tops.final[2]}</Box>, respectively. 
                </Typography>

                <Sectionheader head={'4.3 Score distribution across the industries'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    After investigating individual companies and the sector as a whole, this section shifts its focus to the average
                    total scores for each industry within the healthcare sector. <Box fontWeight='fontWeightMedium' display='inline'>
                    Table 10</Box> provides a detailed breakdown of the average total scores and total market capitalization (in 
                    millions of dollars) for each industry.
                </Typography>

                <Figurecaption caption={Table10Caption}/>

                <Box sx={{
                    border: 1, 
                    mb: '2%', 
                    width: {xs: '98%', sm: '85%', md: '75%'}
                    }}>
                    <IndustryTopTable tops={industries.tops} />
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    At present, the <Box fontWeight='fontWeightMedium' display='inline'>{industries.tops[0].id}</Box> industry boasts the 
                    highest average total score within the {sector} sector, standing at <Box fontWeight='fontWeightMedium' display='inline'>
                    {industries.tops[0].s}</Box>. In contrast, the <Box fontWeight='fontWeightMedium' display='inline'>
                    {industries.tops.at(-1).id}</Box> industry holds the lowest average total score, at <Box fontWeight='fontWeightMedium' 
                    display='inline'> {industries.tops.at(-1).s}</Box>.
                </Typography>
                
                <RadarScore radar={industries.radar}/>
                <Figurecaption caption={'Figure 48: Average ratio category performance for industries with highest and lowest final score '}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    In conclusion, this comprehensive analysis has provided valuable insights into the financial performance of
                    companies within the healthcare sector, offering a deep understanding of their strengths and weaknesses. The
                    assessment of various financial metrics, scoring systems, and composite scores has shed light on the sector’s
                    operational dynamics and has allowed for meaningful comparisons between companies and industries. We
                    hope these insights can prove valuable for readers seeking to better understand the dynamic of the sector. As
                    the sector continues to evolve, the ongoing monitoring of these financial indicators will be crucial for staying
                    ahead in the ever-changing landscape of the market.
                </Typography>

            </Box>
            }
        </div>
    )

}