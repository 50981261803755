import React from "react";
import Layout from '../components/layout';
import { Container, Box, Typography } from "@mui/material";
import { Helmet } from 'react-helmet';
import CapStone from "../components/capStone";
import { useTheme } from '@mui/material/styles';
import SiteAlert from "../components/sitealert";


export default function Aboutpage() {

    const theme = useTheme()

    return(
        <div >
            <Helmet>
                <title>About FARSIGHT studies</title>
                <meta name="description" content={'affordable sector reports for purchase'} />
                <meta name="keywords" content="sector report, ratio analysis, online purchase, weekly" />

                <meta property="og:title" content={'About FARSIGHT studies'} />
                <meta property="og:description" content={'affordable sector reports for purchase'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://farsight.report/about'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'About FARSIGHT studies'} />
                <meta name="twitter:description" content={'affordable sector reports for purchase'} />
            </Helmet>
            <Layout>
                <>
                <SiteAlert />
                <Container maxWidth='lg' sx={{background: theme.palette.background.default}}>

                    <CapStone title='About FARSIGHT' />

                    <Box sx={{display: "flex", width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: 32, 
                                fontWeight: 400, 
                                lineHeight: 2,
                                pt: '4%',
                                pb: '4%',
                                width: '100%', 
                                height: '100%'}}>
                                Motivation
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex",  flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md:18}, 
                                    fontWeight: 300, 
                                    pb: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    FARSIGHT studies are driven by a strong desire to understand what makes "good performance" in each sector of the market. Despite the 
                                    numerous free and paid online resources available, clear, analytical, verifiable, and reliable data that provides a comprehensive view 
                                    and detailed information is often lacking.
                            </Typography>
                            <Typography 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md:18}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                    We understand your interest in straightforward and clear information that can be easily understood by a wide audience. At FARSIGHT, we 
                                    are committed to quantitative simplicity and qualitative clarity. Therefore, we have embarked on this journey, taking on the task of 
                                    analyzing data across every part of the market every week to deliver digestible insights to you on a weekly basis. Your quest for valuable 
                                    financial information is what motivates us, and we remain dedicated to meeting that need.
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: 32, 
                                fontWeight: 400, 
                                lineHeight: 2,
                                pt: '4%',
                                pb: '4%',
                                width: '100%', 
                                height: '100%'}}>
                                Why sector analysis
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md:18}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                The primary goal of our sector analysis is to equip readers with a comprehensive assessment of how efficiently companies within the sector operate. 
                                By exploring this analysis, readers gain a clearer understanding of the competitive dynamics that shape the sector. This process extends to recognizing 
                                supply and demand statistics, gauging the intensity of competition within the sector, and observing the impact of external factors on its overall 
                                performance. 
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: 32, 
                                fontWeight: 400, 
                                lineHeight: 2,
                                pt: '4%',
                                pb: '4%',
                                width: '100%', 
                                height: '100%'}}>
                                Current products
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                            <Typography 
                                paragraph
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md:18}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                For those who are interested, our reports are currently available for individual purchase. However, we are also diligently working on introducing 
                                a subscription service. Subscribers, with a convenient flat monthly fee, will enjoy exclusive access to the latest reports delivered fresh every 
                                week. This subscription not only ensures timely updates but also offers the best value for staying informed.
                                </Typography>

                                <Typography 
                                paragraph
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md:18}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                Each report delivers a comprehensive sector analysis, offering insights into market capitalization, market returns across various timeframes, and an 
                                extensive ratio analysis of the sector. This in-depth analysis examines various financial and performance metrics, categorizing them into six key areas, 
                                ranging from liquidity and cash flow to management performance.
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: 32, 
                                fontWeight: 400, 
                                lineHeight: 2,
                                pt: '4%',
                                pb: '4%',
                                width: '100%', 
                                height: '100%'}}>
                                What do we not provide
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md:18}, 
                                    fontWeight: 300, 
                                    pb: '4%',
                                    width: '100%', 
                                    height: '100%'}}>
                                IT'S IMPORTANT TO NOTE THAT FARSIGHT DOES NOT PROVIDE RECOMMENDATIONS TO BUY OR SELL ANY SECURITY. WE HAVE NO PLANS TO DO SO NOW OR IN THE FUTURE, 
                                NOR ARE WE LICENSED FOR SUCH ACTIVITIES. OUR RESEARCH HIGHLIGHTS COMPANIES PURELY BASED ON DATA, AND WE DO NOT RECEIVE COMMISSIONS AND FINANCIAL 
                                BENEFITS FROM ANY COMPANY WE ANALYZE. THIS STUDY REFRAINS FROM OFFERING SPECIFIC RECOMMENDATIONS TO BUY OR SELL INDIVIDUAL COMPANIES OR STOCKS. IT 
                                DOESN'T FORECAST THE PERFORMANCE OF ANY SECURITIES, NOR DOES IT SUGGEST PICKING PARTICULAR STOCKS. OUR SCORING SYSTEM IS SOLELY BASED ON MATHEMATICAL 
                                CALCULATIONS AND STATISTICAL ANALYSIS, AND IT MAY BE SUBJECT TO CHANGE WITHOUT PRIOR NOTICE. OUR AIM IS TO PROVIDE YOU WITH UNBIASED, DATA-DRIVEN 
                                INSIGHTS TO EXPAND YOUR HORIZONS.

                                {/* It's important to note that FARSIGHT does not provide recommendations to buy or sell any security. We have no plans to do so now or in the future, 
                                nor are we licensed for such activities. Our research highlights companies purely based on data, and we do not receive commissions and financial 
                                benefits from any company we analyze. This study refrains from offering specific recommendations to buy or sell individual companies or stocks. It 
                                doesn't forecast the performance of any securities, nor does it suggest picking particular stocks. Our scoring system is solely based on mathematical 
                                calculations and statistical analysis, and it may be subject to change without prior notice. Our aim is to provide you with unbiased, data-driven 
                                insights that empower your strategic decisions. */}
                            </Typography>
                        </Box>
                       
                    </Box>
                    
                </Container>
                </>
            </Layout>
        </div>
    )
}