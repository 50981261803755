import { useRef, useState, useEffect } from "react"
import { Box, 
    TextField, 
    Button, 
    Container, 
    Typography, 
    Link, 
    Alert, 
    AlertTitle
 } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { purple, grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { auth, functions, db } from "../firebase/index";
import { createUserWithEmailAndPassword, updateProfile, update } from "firebase/auth";
import { setDoc, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormik } from 'formik';
import { httpsCallable } from "firebase/functions";
import * as yup from 'yup';
import { useTheme } from '@mui/material/styles';


const ColorButton = styled(Button)(({ theme }) => ({
    marginBottom: 2,
    marginTop: 2,
    color: theme.palette.common.white,
    alignItems: 'center',
    fontSize: 14,
    // minWidth: '40vw',
    backgroundColor: purple[200],
    borderColor: purple[200],
    '&:hover': {
      backgroundColor: purple[400],
      borderColor: purple[400]
    },
  }));

export default function Signup() {

    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()
    const theme = useTheme()

    const [err, setErr] = useState('')
    const [loading, setLoading] = useState(false)
    // const [isActive, setIsActive] = useState(true)
    const navigate = useNavigate()

    const [checked, setChecked] = useState(false)

    const ror = httpsCallable(functions, 'userFBIntegration')
    // const welcomeEmail = httpsCallable(functions, 'welcomeEmail')

    const handleCheckBox = (event) => {
        setChecked(event.target.checked);
      };

    const validationSchema = yup.object({
        name: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
        password: yup
            .string('Enter your password')
            // .min(8, 'Password should be of minimum 8 characters length')
            .required('Password is required'),
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            lastName: '',
            email: '',
            password: '',
        },
        initialErrors: {
            name: '1',
            lastName: '2',
            email: '3',
            password: '4',
        },
        validateOnMount: false,
        validationSchema: validationSchema,
        handleChange: values => {console.log(values)},
        onSubmit: values => {
            setLoading(true)
            createUserWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)
            .then(async (userCredential) => {
                const user = userCredential.user;
                
                // console.log('ID:', user.uid)
                // console.log('ID:', user.displayName)
                // 
                
                // const payload = {
                //     'firstName': values.name,
                //     'lastName': values.lastName,
                //     'email': values.email,
                //     'metadata': {},
                //     }

                // console.log('2 sec(s) started.')
                // setTimeout(() => {}, 2000)
                // console.log('2 sec(s) ended.')
                    
                // console.log(payload)
                // const docRef = doc(db, "customers", user.uid)
                // await updateDoc(docRef, {
                //     timestamp: serverTimestamp(),
                //     'firstName': values.name,
                //     'lastName': values.lastName,
                //     'email': values.email,
                //     'metadata': {},
                // })
                // await setDoc(doc(db, "customers", user.uid), payload)

                // console.log('2 sec(s) started.')
                // setTimeout(() => {}, 2000)
                // console.log('2 sec(s) ended.')

                ror({
                    firstName: values.name,
                    lastName: values.lastName,
                    metadata: {},
                    })
                .then(res => {
                    setTimeout(() => {}, 1500)
                    // console.log('Borrowed time', res)

                    setLoading(false)
                    navigate("/")
                    
                })
    
                // setLoading(false)
                // navigate("/")
                }
            )
            .catch( (error) => {
                const errorCode = error.code;
                console.log(errorCode)
                const errorMessage = error.message;
                const errorDetails = error.details;
                setErr(errorMessage)
            })

            console.log('Name is:', values.name)
        }

      });

    useEffect(() => {

        return() => {}
    })

    return(
        <div>
            <Helmet>
                <title>Sign up to FARSIGHT</title>
                <meta name="description" content={'signup page for farsight website'} />
                <meta name="keywords" content="sector report, ratio analysis, online purchase, weekly" />

                <meta property="og:title" content={'Sign up to FARSIGHT studies'} />
                <meta property="og:description" content={'affordable sector reports for purchase'} />
                <meta property="og:url" content={'https://farsight.report/signup'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Sign up to FARSIGHT studies'} />
                <meta name="twitter:description" content={'affordable sector reports for purchase'} />
            </Helmet>
            <Layout>
                <Container maxWidth='lg' sx={{background: theme.palette.background.default}}>
                    <div style={{ 
                        display: 'grid',
                        justifyContent:'center', 
                        height: '90vh', 
                        alignContent: 'center' 
                        }}>
                        <Box sx={{
                            display: "flex", 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            height: '100%', 
                            width: '100%'}}>
                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 36, md: 48}, 
                                    fontWeight: 800, 
                                    lineHeight: 2,
                                    color: grey[900],
                                    pt: '4%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    LET'S SEE FURTHER
                            </Typography>
                        </Box>

                        { err && 
                        <Alert variant='filled' severity="error">
                            <AlertTitle>Signup error</AlertTitle>
                            {err}
                        </Alert>
                        }

                        <form onSubmit={formik.handleSubmit}>
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2, mb: 1, }}>
                                <TextField
                                    // fullWidth
                                    sx={{width: {xs: '100%', lg: '85%', xl: '70%'}, alignSelf: 'center'}}
                                    id="name"
                                    name="name"
                                    label="Name"
                                    variant='outlined'
                                    inputRef={firstNameRef} 
                                    InputProps={{sx: {bgcolor: 'white'}}}
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}/>
                            </Box>

                            <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                                <TextField
                                    sx={{width: {xs: '100%', lg: '85%', xl: '70%'}, alignSelf: 'center'}}
                                    InputProps={{sx: {bgcolor: 'white'}}}
                                    id="lastName"
                                    name="lastName"
                                    label="Last name"
                                    inputRef={lastNameRef} 
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName} /> 
                            </Box>

                            <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                                <TextField
                                    sx={{width: {xs: '100%', lg: '85%', xl: '70%'}, alignSelf: 'center'}}
                                    InputProps={{sx: {bgcolor: 'white'}}}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    inputRef={emailRef} 
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email} />
                            </Box>

                            <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                                <TextField
                                    sx={{width: {xs: '100%', lg: '85%', xl: '70%'}, alignSelf: 'center'}}
                                    InputProps={{sx: {bgcolor: 'white'}}}
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    inputRef={passwordRef} 
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password} />                                
                            </Box>

                            <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>

                                <FormGroup>
                                    <FormControlLabel 
                                        required 
                                        control={
                                            <Checkbox 
                                                checked={checked}
                                                onChange={handleCheckBox}
                                                sx={{    
                                                '&.Mui-checked': {
                                                    color: '#d827ff',
                                                    },
                                                }}/>
                                            } 
                                        label={
                                            <Typography>
                                                I agree with FARSIGHT {
                                                    <Link to={'/terms'} style={{color: grey[900], fontWeight: 'bold'}}>terms and conditions</Link>
                                                    } and {
                                                        <Link to={'/privacy'} style={{color: grey[900], fontWeight: 'bold'}}>privacy policies</Link>
                                                        }.
                                            </Typography>
                                        } />
                                </FormGroup>

                            </Box>

                            <Box sx={{textAlign: 'center', mt: 2, mb: 1}}>
                                <ColorButton 
                                    disableElevation
                                    // onClick={formik.on} 
                                    type='submit'
                                    disabled={!(formik.isValid && checked)}
                                    variant='outlined'
                                    sx={{
                                        width: {xs: '100%', lg: '85%', xl: '70%'},
                                        fontFamily: 'inherit',
                                        fontSize: 16,
                                        fontWeight: 400,
                                        color: 'white',  
                                        // borderColor: grey[400],                              
                                        // background: grey[400],
                                        // '&:hover': {
                                        //     borderColor: '#d827ff',
                                        //     background: '#d827ff', 
                                        //     color: 'white'},
                                        mt: '5%', mb: '5%'}}>
                                    Signup!
                                </ColorButton>
                            </Box>

                        </form>

                        <Box sx={{
                            textAlign: 'center', 
                            height: '5vh', 
                            mt: 2, mb: 2}}>
                            <Typography>Already have an account? <Link underline='hover' color={'textSecondary'} href="/signin"> Signin </Link></Typography>
                        </Box>

                    </div>

                </Container>
            </Layout>
        </div>
    )
}