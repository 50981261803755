import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { grey } from "@mui/material/colors";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
      backgroundColor: grey[800],
      color: theme.palette.common.white,
    },
    // [`&.${tableCellClasses.body}`]: {
    //   fontSize: 14,
    // },
  }));

export function createData(ticker, m1, m2, m3, score) {
    return { ticker, m1, m2, m3, score };
  }

export function createGeneral(arr){
    const aa = {}
    arr.forEach( (element, index) => {aa[index] = element})
        return aa;
}

export function IndustryTopTable({tops}) {

    return(
        <>
        <TableContainer component={'div'}>
            <Table sx={{ minWidth: 300 }} aria-label="sector statistics table">

                <TableHead>
                    <TableRow>
                        <StyledTableCell>Industry</StyledTableCell>
                        {/* <StyledTableCell align="right">Industry</StyledTableCell> */}
                        <StyledTableCell align="right">Return (%)</StyledTableCell>
                        <StyledTableCell align="right">Score</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {tops.map((row) => (
                    <TableRow
                        key={row.id}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                                backgroundColor: grey[100],
                                }, 
                            '&:nth-of-type(odd)': {
                                backgroundColor: 'white',
                                }, 
                        }}
                        >
                        <TableCell component="th" scope="row">
                            {row.id}
                        </TableCell>
                        <TableCell align="right">{row.r}</TableCell>
                        <TableCell align="right">{row.s}</TableCell>
                        {/* <TableCell align="right">{row.price}</TableCell> */}
                    </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
        </>
    )
}
    

export function IndustryMeanTable({header, data}) {
    return(
        <>
        <TableContainer component={'div'}>
            <Table sx={{ minWidth: 300 }} aria-label="sector statistics table">

                <TableHead>
                    <TableRow>
                        {header.map( (h) => (
                            <StyledTableCell key={h} align="center">{h}</StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.map((row) => (
                    <TableRow
                        key={row.id1}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                                backgroundColor: grey[100],
                                }, 
                            '&:nth-of-type(odd)': {
                                backgroundColor: 'white',
                                }, 
                        }}
                        >
                        <TableCell component="th" scope="row">
                            {row.id1}
                        </TableCell>
                        <TableCell align="right">{row.id2}</TableCell>
                        <TableCell align="right">{row.id3}</TableCell>
                        {/* <TableCell align="right">{row.price}</TableCell> */}
                    </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
        </>
    )

}

export function FinalScoreDist({sector, data}) {

    return(
        <>
        <TableContainer component={'div'}>
            <Table sx={{ minWidth: 300 }} aria-label="Final score statistics table">

                <TableHead>
                    <TableRow>
                        <StyledTableCell>Sector</StyledTableCell>
                        <StyledTableCell align='center'>Min.</StyledTableCell>
                        {/* <StyledTableCell align='center'>Median</StyledTableCell> */}
                        <StyledTableCell align='center'>Mean</StyledTableCell>
                        <StyledTableCell align='center'>Max.</StyledTableCell>
                        <StyledTableCell align='center'>Std. Dev.</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    <TableRow
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                                backgroundColor: grey[100],
                                }, 
                            '&:nth-of-type(odd)': {
                                backgroundColor: 'white',
                                }, 
                        }}
                        >
                        <TableCell component="th" scope="row">
                            {sector}
                        </TableCell>
                        <TableCell align='center'>{data.min}</TableCell>
                        {/* <TableCell align='center'>{data.med}</TableCell> */}
                        <TableCell align='center'>{data.mean}</TableCell>
                        <TableCell align='center'>{data.max}</TableCell>
                        <TableCell align='center'>{data.std}</TableCell>
                    </TableRow>

                </TableBody>

            </Table>
        </TableContainer>
        </>
    )
}

export function GeneralTable({header, data}) {
    return(
        <>
        <TableContainer component={'div'}>
            <Table sx={{ minWidth: 560 }} aria-label="sector statistics table">

                <TableHead>
                    <TableRow>
                        {header.map( (h) => (
                            <StyledTableCell key={h} align="center">h</StyledTableCell>
                        ))}
                        {/* <StyledTableCell>Return horizon</StyledTableCell>
                        <StyledTableCell align="right">Simple average</StyledTableCell>
                        <StyledTableCell align="right">Weighted average</StyledTableCell>
                        <StyledTableCell align="right">Price ($)</StyledTableCell> */}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.map((row) => (
                    <TableRow
                        key={row.h}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                                backgroundColor: grey[100],
                              }, 
                            '&:nth-of-type(odd)': {
                                backgroundColor: 'white',
                              }, 
                        }}
                        >
                        <TableCell component="th" scope="row">
                            {row.h}
                        </TableCell>
                        <TableCell align="right">{row.s}</TableCell>
                        <TableCell align="right">{row.w}</TableCell>
                        <TableCell align="right">{row.price}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
        </>
    )

}

export function AverageReturn({averages}) {

    return(
        <>
        <TableContainer component={'div'}>
            <Table sx={{ minWidth: 560 }} aria-label="sector statistics table">

                <TableHead>
                    <TableRow>
                        <StyledTableCell>Return horizon</StyledTableCell>
                        <StyledTableCell align="right">Simple average</StyledTableCell>
                        <StyledTableCell align="right">Weighted average</StyledTableCell>
                        {/* <StyledTableCell align="right">Price ($)</StyledTableCell> */}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {averages.map((row) => (
                    <TableRow
                        key={row.h}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                                backgroundColor: grey[100],
                              }, 
                            '&:nth-of-type(odd)': {
                                backgroundColor: 'white',
                              }, 
                        }}
                        >
                        <TableCell component="th" scope="row">
                            {row.h}
                        </TableCell>
                        <TableCell align="right">{row.s}</TableCell>
                        <TableCell align="right">{row.w}</TableCell>
                        {/* <TableCell align="right">{row.price}</TableCell> */}
                    </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
        </>
    )
}

export function IndustryTable({tops}) {

    const formater = new Intl.NumberFormat('en-US')

    return(
        <>
        <TableContainer component={'div'}>
            <Table sx={{ minWidth: 300 }} aria-label="sector statistics table">

                <TableHead>
                    <TableRow>
                        <StyledTableCell>Industry</StyledTableCell>
                        <StyledTableCell align="right">Ticker</StyledTableCell>
                        <StyledTableCell align="right">MCAP ($B)</StyledTableCell>
                        <StyledTableCell align="right">Price ($)</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {tops.map((row) => (
                    <TableRow
                        key={row.industry}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                                backgroundColor: grey[100],
                              }, 
                            '&:nth-of-type(odd)': {
                                backgroundColor: 'white',
                              }, 
                        }}
                        >
                        <TableCell component="th" scope="row">
                            {row.industry}
                        </TableCell>
                        <TableCell align="right">{row.ticker}</TableCell>
                        <TableCell align="right">{formater.format(row.mcap)}</TableCell>
                        <TableCell align="right">{row.price}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
        </>
    )
}

export function ScoreTable(props) {

    let internal_table = []

    for (const [key, value] of Object.entries(props.table)) {

        internal_table.push(
            createData(key, value.sc1, value.sc2, value.sc3, value.total)
            )

    }

    return(
        <>
        <TableContainer component={'div'}>
            <Table sx={{ minWidth: 560 }} aria-label="sector statistics table">

                <TableHead>
                    <TableRow>
                        <StyledTableCell>Ticker</StyledTableCell>
                        <StyledTableCell align="right">{props.score[0]}</StyledTableCell>
                        <StyledTableCell align="right">{props.score[1]}</StyledTableCell>
                        <StyledTableCell align="right">{props.score[2]}</StyledTableCell>
                        <StyledTableCell align="right">Total Score</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {internal_table.map((row) => (
                    <TableRow
                        key={row.ticker}
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(odd)': {
                                // backgroundColor: theme.palette.action.hover,
                                backgroundColor: grey[100],
                              }, 
                            '&:nth-of-type(even)': {
                                // backgroundColor: theme.palette.action.hover,
                                backgroundColor: 'white',
                              }, 
                        }}
                        >
                        <TableCell component="th" scope="row">
                            {row.ticker}
                        </TableCell>
                        <TableCell align="right">{row.m1}</TableCell>
                        <TableCell align="right">{row.m2}</TableCell>
                        <TableCell align="right">{row.m3}</TableCell>
                        <TableCell align="right">{row.score}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
        </>
    )
}

export function SectorStats({sector, table}) {

    const formater = new Intl.NumberFormat('en-US')

    let internal_table = []

    for (const [key, value] of Object.entries(table)) {

        internal_table.push(createData(key, value.mean, value.med, value.std, value.skew))

    }

    return(
        <>
        <TableContainer component={'div'}>
            <Table sx={{ minWidth: 300 }} size='small' aria-label="sector statistics table">

                <TableHead>
                    <TableRow>
                        <StyledTableCell>Sector</StyledTableCell>
                        <StyledTableCell align="right">Mean ($M)</StyledTableCell>
                        <StyledTableCell align="right">Median ($M)</StyledTableCell>
                        <StyledTableCell align="right">Std. Dev. ($M)</StyledTableCell>
                        <StyledTableCell align="right">Skew (-)</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow
                        key={sector}
                        sx={{ 
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                                backgroundColor: grey[100],
                              }, 
                            '&:nth-of-type(odd)': {
                                backgroundColor: 'white',
                              }, 
                        }}
                        >
                        <TableCell component="th" scope="row">
                            {sector}
                        </TableCell>
                        <TableCell align="right">{formater.format(table.mean)}</TableCell>
                        <TableCell align="right">{formater.format(table.med)}</TableCell>
                        <TableCell align="right">{formater.format(table.std)}</TableCell>
                        <TableCell align="right">{formater.format(table.skew)}</TableCell>
                    </TableRow>
                    {/* ))} */}
                </TableBody>

            </Table>
        </TableContainer>
        </>
    )
}

export function MetricsStats(props) {

    let internal_table = []

    for (const [key, value] of Object.entries(props.table)) {

        internal_table.push(createData(key, value.min, value.mean, value.max, value.std))

    }

    return(
        <>
        <TableContainer component={'div'}>
            <Table sx={{ minWidth: 300 }} aria-label="sector statistics table">

                <TableHead>
                    <TableRow>
                        <StyledTableCell>Metric</StyledTableCell>
                        <StyledTableCell align="right">Min.</StyledTableCell>
                        <StyledTableCell align="right">Mean</StyledTableCell>
                        <StyledTableCell align="right">Max.</StyledTableCell>
                        <StyledTableCell align="right">Std. Dev.</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {internal_table.map((row) => (
                    <TableRow
                        key={row.ticker}
                        sx={{ 
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:nth-of-type(even)': {
                                // backgroundColor: theme.palette.action.hover,
                                backgroundColor: grey[100],
                              }, 
                            '&:nth-of-type(odd)': {
                                // backgroundColor: theme.palette.action.hover,
                                backgroundColor: 'white',
                              }, 
                        }}
                        >
                        <TableCell component="th" scope="row">
                            {row.ticker}
                        </TableCell>
                        <TableCell align="right">{row.m1}</TableCell>
                        <TableCell align="right">{row.m2}</TableCell>
                        <TableCell align="right">{row.m3}</TableCell>
                        <TableCell align="right">{row.score}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
        </>
    )
}

export function SimpleTable(props) {

    return(
        <>
        <TableContainer component={'div'}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">

                <TableHead>
                    <TableRow>
                        <TableCell>{props.header[0]}</TableCell>
                        <TableCell align="right">{props.header[1]}</TableCell>
                        <TableCell align="right">{props.header[2]}</TableCell>
                        <TableCell align="right">{props.header[3]}</TableCell>
                        <TableCell align="right">{props.header[4]}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.table.map((row) => (
                    <TableRow
                        key={row.ticker}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.ticker}
                        </TableCell>
                        <TableCell align="right">{row.m1}</TableCell>
                        <TableCell align="right">{row.m2}</TableCell>
                        <TableCell align="right">{row.m3}</TableCell>
                        <TableCell align="right">{row.score}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}