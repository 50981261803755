import React from "react";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import { buttonColors, contrastTextColors } from '../theme/colors';
import { grey } from "@mui/material/colors";
import { alpha } from "@mui/material";
// import { useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";

function SubscriptionBlocks(sub, index, buttonColors) {

    let mtop = 0
    const sector_subscribed = sub.items[0].price.product.metadata.sector 
    const Cap_sector = sector_subscribed.charAt(0).toUpperCase() + sector_subscribed.slice(1)
    // console.log(sector_subscribed.replace(/\s+/g, ''))

    if (index >= 1) {
        mtop = '4%'
    } else {
        mtop = 0
    }

    // console.log('Subscription is:', sub)
    // console.log('Index is ', index, mtop)

    // async function SideOpen(sector) {

    //     const SignedURL = httpsCallable(functions, 'SignedURL')
    
    //     const fullName = sector.replace(' ', '')
    //     const docRef = doc(db, "updates", fullName)
    //     const docSnap = await getDoc(docRef)
    
    //     if (docSnap.exists()) {
    //         const report = docSnap.data().latestReport
    //         const storagePath = `${fullName}/${report}`
    
    //         SignedURL({path: storagePath})
    //         .then( result => {
    //             const url = result.data.url[0]
    //             window.open(url, "_blank")
    //         })
    //         .catch( (error) => {
    //             const errorCode = error.code;
    //             const errorMessage = error.message;
    //             console.log('Error code: ', errorCode)
    //             console.log('Error Msg.: ', errorMessage)
    //         })
    
    //     } else {
    
    //     }
    
    // }

    return(

        <Box key={index} sx={{
            width: {xs: '80%', sm: '66%'},
            maxWidth: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center', 
            mt: mtop,   
            }}>

                <Button 
                    // onClick={(e) => SideOpen(sector_subscribed)}
                    // onClick={(e) => SideOpen(sector_subscribed)}
                    variant='outlined'
                    component={Link}
                    to={'/'+sector_subscribed.replace(/\s+/g, '')}
                    fullWidth
                    sx={{
                        // mb: '5%',
                        pt: '2%',
                        pb: '2%',
                        border: 0.5,
                        fontFamily: 'inherit',
                        color: contrastTextColors[index+1],
                        borderColor: alpha(buttonColors[index], 0.6),
                        background: alpha(buttonColors[index], 0.6),
                        '&:hover': {
                            border: 0.5,
                            color: contrastTextColors[index],
                            background: alpha(buttonColors[index], 0.9),
                            borderColor: alpha(buttonColors[index], 0.9),
                            // fontWeight: 500,
                            },
                        }}>
                    <Typography 
                        textAlign={'center'} 
                        sx={{
                            fontSize: {xs: 14, sm: 16},
                            fontWeight: 400,
                            }}>
                        {Cap_sector} Sector
                    </Typography>
                </Button>
            
        </Box>
    )
}


export default function ActiveSubBlock(props) {

    // const theme = useTheme()

    // console.log(props.activeSubs)

    return(
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column', 
            alignItems: 'center',
            justifyItems: 'center',

            }}>
            { 
                props.subLoading  
                ? 
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    mt: {xs: '8%', sm: '2%'},
                    mb: {xs: '4%', sm: '4%'},
                    width: '100%'}}>
                    <CircularProgress size={'3rem'}/>
                </Box>
                : 

                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    mt: '4%',
                    mb: '4%',
                    width: '100%'}}>
                    {
                        props.activeSubs.length > 0 
                        ?

                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            width: '100%'}}>
                                { 
                                    props.activeSubs.map(
                                        (sub, index) => 
                                        SubscriptionBlocks(sub, index, buttonColors)
                                    )
                                }
                        </Box>

                        :
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                            width: '100%'}}>
                            <Typography 
                                sx={{
                                    fontSize: {xs: 16, sm: 24},
                                    fontWeight: 400, 
                                    color: grey[800]
                                    }}>
                                You have no active subscriptions.
                            </Typography>
                        </Box>
                    }
                </Box>
            }
        </Box>
    )
}