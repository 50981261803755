// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import { getStripePayments } from "@stripe/firestore-stripe-payments";
import { getStripePayments } from "@invertase/firestore-stripe-payments";
import { getStorage } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize SDKs
const analytics = getAnalytics(app);
const db = getFirestore(app)
// connectFirestoreEmulator(db, '127.0.0.1', 8080);
const auth = getAuth(app)
const storage = getStorage(app)
const functions = getFunctions(app)
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);

// Initilize Stripe
const payments = getStripePayments(app, {
  // Production vieww
  productsCollection: "products", 
  // Testing view
  // productsCollection: "fakeproducts",
  customersCollection: "customers",
});

export {
  auth,
  db,
  analytics,
  storage,
  payments,
  functions
}