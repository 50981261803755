import { useEffect, useRef, useState} from "react"
import { Button, Typography, TextField, Alert, Container, Grid, Link, Fade, Grow, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { grey, purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Layout  from '../components/layout';
import { useTheme } from '@mui/material/styles';
import { UserAuth } from "../context/userContext";

const ColorButton = styled(Button)(({ theme }) => ({
    marginBottom: 2,
    marginTop: 2,
    color: theme.palette.common.white,
    alignItems: 'center',
    fontSize: 16,
    fontFamily: 'inherit',
    width: '100%',
    backgroundColor: purple[200],
    borderColor: purple[200],
    '&:hover': {
      backgroundColor: purple[400],
      borderColor: purple[400]
    },
  }));

export default function Signin() {

    const { signIn, passwordReset } = UserAuth()
    const theme = useTheme()

    const [err, setErr] = useState('')

    const emailRef = useRef()
    const passwordRef = useRef()
    const navigate = useNavigate()

    async function handleSubmit(email, password) {

        // console.log('email is ', email.current.value)

        signIn(email.current.value, password.current.value)

        .then((userCredential) => {
            navigate('/user')
        })
        .catch((error) => {
            const errorCode = error.code
            const errorMsg = error.message
            console.log('Error code:', errorCode)
            console.log('Error msg: ', errorMsg)
            setErr(errorMsg)
        })
    
    }

    
    async function Reset(email, password) {

        // signIn(email.current.value)
        // .then( ( ) => {
        //     console.log('JI')
        // })
        // .catch((error) => {
        //     const errorCode = error.code
        //     const errorMsg = error.message
        //     console.log('Error code:', errorCode)
        //     console.log('Error msg: ', errorMsg)
        //     setErr(errorMsg)
        // })
    
    }

    useEffect(()=> {

        return() => {
            console.log('Cleaning up from Signin.js!')
        }
    }, [])

    return (
        <Layout>
            <Container 
                maxWidth='lg' 
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '85vh',
                    bgcolor: theme.palette.background.default,
                    }}>

                <Box sx={{
                    display: 'grid', 
                    height: '20vh', 
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    }}>

                </Box>

                <Box sx={{
                    display: 'grid', 
                    height: '45vh', 
                    width: '100%',
                    // height: '100%',
                    // background: 'red',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    }}>

                    <Box sx={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        width: '100%'}}>
                        <Fade in={true} timeout={2000}>
                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontSize: {xs: 36, md: 48}, 
                                    fontWeight: 800, 
                                    // lineHeight: 2,
                                    color: grey[900],
                                    pt: '4%',
                                    // width: '80%', 
                                    height: '100%'}}>
                                    WELCOME BACK!
                            </Typography>
                        </Fade>
                    </Box>  

                    <Box sx={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        height: '100%', 
                        // background: 'green',
                        width: '100%'}}>
                        { err && <Alert severity="error" sx={{width: '100%'}}>{err}</Alert>}
                    </Box>

                    <Box sx={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        // lineHeight: 2,
                        height: '100%', 
                        width: '100%'}}>
                        <Grow in={true} timeout={2500}>
                            <TextField 
                                size='small' 
                                fullWidth 
                                type="email" 
                                color="primary" 
                                variant="outlined" 
                                label="Email" 
                                inputRef={emailRef}
                                // sx={{width: {xs: '100%', md: '80%'}}}
                                InputProps={{sx: {bgcolor: 'white'}}}>
                                    Email
                            </TextField>
                        </Grow>
                    </Box>

                    <Box sx={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        height: '100%', 
                        width: '100%'}}>
                        <Grow in={true} timeout={3000}>
                            <TextField 
                                size='small' 
                                type='password'
                                fullWidth 
                                color="primary" 
                                variant="outlined" 
                                label="Password" 
                                inputRef={passwordRef} 
                                InputProps={{sx: {bgcolor: 'white', lineHeight: 2}}}
                                >
                                    Password
                            </TextField>
                        </Grow>
                    </Box>

                    <Box sx={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        height: '100%', 
                        width: '100%'}}>
                        <Grow in={true} timeout={3500}>
                            <ColorButton 
                                onClick={(e) => {handleSubmit(emailRef, passwordRef)}} 
                                sx={{
                                    fontFamily: 'inherit',
                                    fontSize: 16,
                                }} 
                                variant='outlined'>
                                    Signin
                            </ColorButton>
                        </Grow>
                    </Box>

                    <Box sx={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        height: '100%', 
                        width: '100%'}}>
                        <Grow in={true} timeout={4000}>
                            <Typography 
                            textAlign={'center'}
                            sx={{lineHeight: 2}}
                            >
                                Need an account? { 
                                <Link
                                    underline='hover' 
                                    color={'textSecondary'} 
                                    href="/signup">
                                        Signup 
                                </Link>}
                            </Typography>
                        </Grow>
                    </Box>

                    <Box sx={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        height: '100%', 
                        width: '100%'}}>
                        <Grow in={true} timeout={4500}>
                            <Link
                                underline='hover' 
                                color={'textSecondary'} 
                                href="/forget">
                                <Typography sx={{
                                    fontSize: 16,
                                    fontFamily: 'inherit',
                                    }}>
                                    Forgot your password?
                                </Typography>
                            </Link>
                        </Grow>
                    </Box>

                </Box>

                <Box sx={{
                    display: 'grid', 
                    height: '20vh', 
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    }}>

                </Box>



                {/* <Box sx={{
                    display: 'grid', 
                    height: '80vh', 
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    }}> */}

                    {/* <Grid 
                        container 
                        direction='column' 
                        spacing={3}>

                        <Grid 
                            item
                            xs={12}
                            md={9}
                            lg={7}
                        >
                            <Fade in={true} timeout={2000}> 

                                <Box sx={{
                                    display: "flex", 
                                    alignItems: 'center', 
                                    justifyContent: 'center', 
                                    height: '100%', 
                                    width: '100%'}}>
                                    <Typography 
                                        textAlign={'center'} 
                                        sx={{
                                            fontSize: {xs: 36, md: 48}, 
                                            fontWeight: 800, 
                                            lineHeight: 2,
                                            color: grey[900],
                                            pt: '4%',
                                            width: '100%', 
                                            height: '100%'}}>
                                            WELCOME BACK!
                                    </Typography>
                                </Box>                         

                            </Fade>
                        </Grid>

                        <Grid item>
                            { err && <Alert severity="error">{err}</Alert>}
                        </Grid>

                        <Grid                            item
                            xs={12}
                            md={9}
                            lg={7}
                            sx={{alignItems: 'center', justifyContent: 'center'}}
                            >

                            <Grow in={true} timeout={2500}>
                                <TextField 
                                    size='small' 
                                    // fullWidth 
                                    type="email" 
                                    color="primary" 
                                    variant="outlined" 
                                    label="Email" 
                                    inputRef={emailRef}
                                    // sx={{width: {xs: '100%', md: '80%'}}}
                                    InputProps={{sx: {bgcolor: 'white'}}}>
                                        Email
                                </TextField>
                            </Grow>

                        </Grid>

                        <Grid item>
                            <Grow in={true} timeout={3000}>
                                <TextField 
                                    size='small' 
                                    type='password'
                                    fullWidth 
                                    color="primary" 
                                    variant="outlined" 
                                    label="Password" 
                                    inputRef={passwordRef} 
                                    InputProps={{sx: {bgcolor: 'white'}}}
                                    >
                                        Password
                                </TextField>
                            </Grow>
                        </Grid>

                        <Grid item>
                            <Grow in={true} timeout={3500}>
                                <ColorButton 
                                    onClick={(e) => {handleSubmit(emailRef, passwordRef)}}  
                                    variant='outlined'>
                                        Signin
                                </ColorButton>
                            </Grow>
                        </Grid>

                        <Grid item>
                            <Grow in={true} timeout={4000}>
                                <Typography textAlign={'center'}>
                                    Need an account? { 
                                    <Link
                                        underline='hover' 
                                        color={'textSecondary'} 
                                        href="/signup">
                                            Signup 
                                    </Link>}
                                </Typography>
                            </Grow>
                        </Grid>

                    </Grid> */}

                {/* </Box> */}

            </Container>
        </Layout>
    )
}