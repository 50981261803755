import React from "react";
import { Typography, Box } from "@mui/material";
import { Sectionheader } from "./reportBlocks";

export default function Structure({sector}) {

    return(

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center', 
            alignItems: 'center', 
            width: '100%',}}>

                <Sectionheader head={'The goals of the study'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    In this study, our objective is to assess the performance of the {sector} sector and establish a benchmark for the 
                    evaluation of various financial metrics of companies through ratio analysis. By setting this benchmark, we 
                    aim to enable readers to compare the financial ratios of any given company within the sector against the 
                    sector statistics, such as mean and standard deviation+. This comparison will facilitate a deeper understanding of the supply-demand dynamics within 
                    the sector, thereby empowering readers to develop their own criteria for determining what constitutes good 
                    performance.
                </Typography>

                <Sectionheader head={'What this study does not provide'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    It is important to note that this study does not offer specific recommendations to buy or sell individual
                    companies, ETFs or securities in general (stocks). It does not attempt to forecast the performance of
                    any securities or provide stock-picking suggestions. Our scoring system is purely based on mathematical
                    calculations and statistical analysis and may be subject to change without prior notice.
                </Typography>

                <Sectionheader head={'Structure of the study'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    This study is divided into five chapters, each focusing on different aspects of sector evaluation. Additionally,
                    we include a bonus chapter on dividend analysis.
                </Typography>
                <Sectionheader head={'Chapter 1: Industry and Market Capitalization Breakdown'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    This chapter investigates the industries within the sector and examines their market capitalization. The 
                    aim is to provide readers with insights into the overall value and industry sizes of the companies.
                </Typography>

                <Sectionheader head={'Chapter 2: Sector’s Return Analysis'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    In this chapter, we analyze the returns of companies within the sector to identify their distribution over
                    different time horizons. Please note that these are past performances and do not guarantee future returns.
                </Typography>

                <Sectionheader head={'Chapter 3: Ratio Analysis'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    This chapter focuses on the financial ratios of companies within the sector, conducting a detailed analysis
                    across seven (7) categories. Throughout the investigation, we compute the statistics of these ratios for the 
                    entire sector and assign a score to each company based on our proprietary, developed system.
                </Typography>

                <Sectionheader head={'Chapter 4: Conclusion and Final Remarks'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The final chapter serves as a summary of the report, evaluating the companies’ final scores and rankings
                    based on their past performance.
                </Typography>

                <Sectionheader head={'Chapter 5: Dividened Analysis'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    As a bonus feature, this chapter explores the dividend payout in the sector. However, it is not included as 
                    an eligible metric for our proposed ratio analysis as not all companies pay dividends.
                </Typography>

        </Box>
    )
}





