import React from "react";
import { Box, Typography, Grid, Card, CardMedia } from "@mui/material";
import { grey } from "@mui/material/colors";
import bb from '../assets/Box2.png';
import cc from '../assets/radar_final.png';
import { useTheme } from '@mui/material/styles';
import { RatioPie, IntroHeat, IntroRadar } from './figureBlocks'

function Explanation(){

    const theme = useTheme()

    return(
        <div style={{width: '100%'}}>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                }}>
                <Typography 
                    textAlign={'center'} 
                    sx={{
                        mb: '2%',
                        fontSize: {xs: 36, md: 48},  
                        fontWeight: 800, 
                        lineHeight: 2,
                        color: grey[900]
                        }}>
                        Study Features
                </Typography>
            </Box>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                borderRadius: '0.8em', 
                border: 1, 
                borderColor: theme.palette.border.main,
                background: theme.palette.background.card,
                mb: '4%'}}>

                <Grid container spacing={1}>

                    <Grid item xs={12} md={6}>

                        <Box sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                paddingLeft: '5%', 
                                paddingRight: '5%',
                                pb: {xs: 0, md: '24px'}
                                }}>

                            <Box sx={{width: '100%', pt: '24px', pb: '24px', }}>
                                <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        fontSize: {xs: 28, md: 32}, 
                                        fontWeight: 500,
                                        color: grey[900],
                                        lineHeight: 2, 
                                        }}>
                                    {/* 🔍 RATIO ANALYSIS */}
                                    RATIO ANALYSIS
                                </Typography>
                            </Box>

                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md: 18}, 
                                    fontWeight: 300
                                    }}>
                                Ratio analysis is your trusty compass for analyzing a company's financial well-being and stacking 
                                it up against the competition. In every report, we dive into ratio analysis for all companies in 
                                the sector, spanning six critical categories: Liquidity, Solvency, Profitability, Efficiency, market 
                                value and finally, Cash flow. 
                            </Typography>              

                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md: 18}, 
                                    fontWeight: 300
                                    }}>
                                In each analysis section, we kick things off with a quick introduction to the ratios and their purposes, 
                                ensuring you're not left in the dark. Then, we crunch the numbers for all the sector's players. We dissect 
                                the metrics' distribution, get our hands dirty with statistical parameters, and serve it all up on a platter. 
                                It's like your financial buffet, ready to feast upon! 
                            </Typography> 

                        </Box>

                    </Grid>

                    <Grid item xs={12} md={6}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            }}>
                                <Box sx={{
                                    width: {xs: '350px', sm: '480px', md: '420px', lg: '500px'},
                                    height: {xs: '350px', sm: '480px', md: '420px', lg: '500px'}}}>
                                    <RatioPie/>
                                </Box>

                            {/* <Card 
                                variant='outlined' 
                                sx={{
                                    borderWidth: 0, 
                                    marginTop: {xs: 0, md: '48px'}, 
                                    marginBottom: {xs: '12px', md: '48px'},
                                    //  background: grey[50]
                                     }}>
                                
                                <CardMedia image={aa} sx={{ 
                                    height: {xs: '350px', sm: '480px', md: '420px', lg: '500px'}, 
                                    width: {xs: '350px', sm: '480px', md: '420px', lg: '500px'},
                                    }}/>
                            </Card> */}

                        </Box>

                    </Grid>

                </Grid>

            </Box>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                borderRadius: '0.8em',
                border: 1, 
                borderColor: theme.palette.border.main,
                background: theme.palette.background.card, 
                mb: '4%'}}>

                <Grid container spacing={1}>

                    <Grid item xs={12} md={6} sx={{display: {md: 'none'}}}>
                        <Box sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            paddingLeft: '5%', 
                            paddingRight: '5%'}}>

                            <Box sx={{width: '100%', pt: '24px', pb: '24px'}}>
                                <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        fontSize: {xs: 28, md: 32}, 
                                        fontWeight: 500,
                                        color: grey[900],
                                        lineHeight: 2, 
                                        }}>
                                    {/* 🌟 CONVENIENT SCORING */}
                                    CONVENIENT SCORING
                                </Typography>
                            </Box>

                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md: 18}, 
                                    fontWeight: 300
                                    }}>
                                We've turned the complex into a walk in the park, making your performance assessments a breeze!
                            </Typography>

                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md: 18}, 
                                    fontWeight: 300
                                    }}>
                                For simple and straightforward comparisons within any sector, we've crafted a nifty proprietary scoring system here at FARSIGHT. 
                                This system dishes out scores on a scale from 1 to 10 for each of 21 financial metric, all based on where a company stacks up against 
                                its peers and how that metric is spread across the sector.
                            </Typography>  

                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md: 18}, 
                                    fontWeight: 300}}>
                                After scoring individual ratios in each category, we cook up a total composite score for each company. We sprinkle in different weights 
                                for each metric to give you the full flavor. As the grand finale, we rank all the companies based on these composite scores. Voila! 
                                It's like a well-orchestrated symphony, making it crystal clear and effortlessly understandable to see where each company takes the 
                                stage.
                            </Typography> 

                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{display: {md: 'none'}}}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            height: '100%',
                            // background: grey[50]
                            }}>

                                <Box sx={{
                                    width: {xs: '350px', sm: '480px', md: '420px', lg: '500px'},
                                    height: {xs: '350px', sm: '480px', md: '420px', lg: '500px'}}}>
                                    <IntroHeat />
                                </Box>

                            {/* <Card 
                                variant='outlined' 
                                sx={{
                                    borderWidth: 0, 
                                    marginTop: {xs: 0, md: '48px'}, 
                                    marginBottom: {xs: '12px', md: '48px'}, 
                                    // background: grey[50]
                                    }}>
                                <CardMedia image={bb} sx={{ 
                                    height: {xs: '350px', sm: '450px'}, 
                                    width: {xs: '350px', sm: '450px'},
                                    }}/>
                            </Card> */}

                        </Box>
                    </Grid>

                    <Grid item xs={1} md={6} sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Box 
                            sx={{
                                display: 'flex',
                                // background: grey[50],
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                            }}>


                            <Card 
                            variant='outlined' 
                            sx={{
                                borderWidth: 0, 
                                marginTop: '48px', 
                                marginBottom: '48px', 
                                // background: grey[50]
                                }}>
                                {/* <CardMedia image={bb} sx={{ 
                                    height: {md: '420px', lg: '500px'}, 
                                    width: {md: '420px', lg: '500px'},
                                    }}/> */}
                                <Box sx={{
                                    width: {xs: '350px', sm: '480px', md: '420px', lg: '500px'},
                                    height: {xs: '350px', sm: '480px', md: '420px', lg: '500px'}}}>
                                    <IntroHeat />
                                </Box>
                            </Card>

                        </Box>
                    </Grid>

                    <Grid item xs={1} md={6} sx={{display: {xs: 'none', md: 'flex'}}}>
                        <Box sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            paddingLeft: '5%', 
                            paddingRight: '5%'}}>

                            <Box sx={{width: '100%', pt: '24px', pb: '24px'}}>
                                <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        fontSize: {xs: 28, md: 32}, 
                                        fontWeight: 500,
                                        color: grey[900],
                                        lineHeight: 2, 
                                        }}>
                                    {/* 🌟 CONVENIENT SCORING  */}
                                    CONVENIENT SCORING 
                                </Typography>
                            </Box>

                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md: 18}, 
                                    fontWeight: 300
                                    }}>
                                We've turned the complex into a walk in the park, making your performance assessments a breeze!
                            </Typography>

                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md: 18}, 
                                    fontWeight: 300
                                    }}>
                                For simple and straightforward comparisons within any sector, we've crafted a nifty proprietary scoring system here at FARSIGHT. 
                                This system dishes out scores on a scale from 1 to 10 for each of 21 financial metric, all based on where a company stacks up against 
                                its peers and how that metric is spread across the sector.
                            </Typography>  

                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md: 18}, 
                                    fontWeight: 300}}>
                                After scoring individual ratios in each category, we cook up a total composite score for each company. We sprinkle in different weights 
                                for each metric to give you the full flavor. As the grand finale, we rank all the companies based on these composite scores. Voila! 
                                It's like a well-orchestrated symphony, making it crystal clear and effortlessly understandable to see where each company takes the 
                                stage.
                            </Typography> 
                        </Box>
                    </Grid>

                </Grid>

            </Box>

            <Box 
            sx={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                border: 1, 
                borderColor: theme.palette.border.main,
                background: theme.palette.background.card,
                borderRadius: '0.8em', 
                mb: '4%'}}>

                <Grid container spacing={1}>

                    <Grid item xs={12} md={6}>

                        <Box sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                paddingLeft: '5%', 
                                paddingRight: '5%'
                                }}>


                            <Box sx={{width: '100%', pt: '24px', pb: '24px'}}>
                                <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        fontSize: {xs: 28, md: 32},  
                                        fontWeight: 500,
                                        color: grey[900],
                                        lineHeight: 2, 
                                        }}>
                                    {/* 📊 INDUSTRY REVIEW */}
                                    INDUSTRY REVIEW
                                </Typography>
                            </Box>

                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{                                        
                                    fontSize: {xs: 16, md: 18}, 
                                    fontWeight: 300
                                    }}>
                                    After we've done our detective work on individual companies and delved into the sector as a whole, each report serves up the average 
                                    total score and other useful statistics for every industry. We don't just throw numbers at you; we turn them into stylish tables and 
                                    engaging infographics that make digesting a wealth of data a piece of cake.                             
                            </Typography>              

                            <Typography 
                                paragraph 
                                textAlign={'justify'} 
                                sx={{
                                    fontSize: {xs: 16, md: 18}, 
                                    fontWeight: 300
                                    }}>
                                This treasure trove of information paints a complete picture of the industries in the sector, giving our readers a backstage pass to 
                                fine-tune their understanding of what makes each sector tick. Whether some sectors are swimming in cash, or others flaunt naturally high 
                                profit margins, we've got it all laid out for you.
                            </Typography> 

                        </Box>

                    </Grid>

                    <Grid item xs={12} md={6}>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            // width: '100%',
                            // background: grey[50],
                            }}>

                            <Box sx={{
                                width: {xs: '350px', sm: '480px', md: '420px', lg: '500px'},
                                height: {xs: '350px', sm: '480px', md: '420px', lg: '500px'}}}>
                                <IntroRadar />
                            </Box>

                            {/* <Card 
                                variant='outlined' 
                                sx={{
                                    borderWidth: 0,  
                                    marginTop: '48px', 
                                    marginBottom: '48px', 
                                    // background: grey[50]
                                    }}>
                                <CardMedia image={cc} sx={{ 
                                    height: {xs: '300px', sm: '400px', md: '325px', lg: '430px'}, 
                                    width: {xs: '360px', sm: '460px', md: '380px', lg: '500px'},
                                    }}/>
                            </Card> */}

                        </Box>

                    </Grid>

                </Grid>

            </Box>

        </div>
    )
}

export default Explanation