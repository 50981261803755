import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";
// import { alpha, useTheme } from '@mui/material/styles';
// import { setDoc, doc, getDocs, getDoc, onSnapshot, collection } from "firebase/firestore";
// import { auth, db } from '../firebase/index';
import { Sectionheader, Figurecaption } from './reportBlocks';
import { MetricsStats } from './tableblocks';
import { MetricSector, ScoreSector, ScoreTop10 } from './figureBlocks';

export default function Management({sector, input}) {

    // const theme = useTheme()
    const [loading, setLoading] = useState(true)

    const CFS = input.data
    const Statistics = input.stats
    const highScore = input.highScore

    const ngl = ( (Statistics['ROA (%)']['mean'] > 0) ? 'income' : 'loss')
    const ngl2 = ( (Statistics['ROE (%)']['mean'] > 0) ? 'income' : 'loss')

    const adj = ((highScore.data[0] === 10) ? ' perfect score' : ' score')


    const [roaRatio, setroaRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [roaTick, setroaTick] = useState({
        "Series1": []
        }
    )

    const [roeRatio, setroeRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [roeTick, setroeTick] = useState({
        "Series1": []
        }
    )

    const [roceRatio, setroceRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [roceTick, setroceTick] = useState({
        "Series1": []
        }
    )

    const [mgmRatio, setmgmRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [mgmTick, setmgmTick] = useState({
        "Series1": []
        }
    )

    useEffect( () => {
        let roa_ratio_dataset = []
        let roa_ratio_tickers = []

        let roe_ratio_dataset = []
        let roe_ratio_tickers = []

        let roce_ratio_dataset = []
        let roce_ratio_tickers = []

        let mgm_score_dataset = []
        let mgm_score_tickers = []
        for (const [key, value] of Object.entries(CFS)) {
            
            roa_ratio_dataset.push([value[3], Math.round(100 * value[0]), value[4]])
            roa_ratio_tickers.push({'ticker': key})

            roe_ratio_dataset.push([value[3], Math.round(100 * value[1]), value[4]])
            roe_ratio_tickers.push({'ticker': key})
            
            roce_ratio_dataset.push([value[3], Math.round(100 * value[2]), value[4]])
            roce_ratio_tickers.push({'ticker': key})

            mgm_score_dataset.push([value[3], value[8], value[4]])
            mgm_score_tickers.push({'ticker': key})
        }

        setroaRatio({
            series: [{  
                    name: 'ROA Ratio',
                    data: roa_ratio_dataset,
                    }],
                }
            )
        setroaTick({
            "Series1": roa_ratio_tickers,
        })

        setroeRatio({
            series: [{  
                name: 'ROE Ratio',
                data: roe_ratio_dataset,
            }],            
        })
        setroeTick({
            "Series1": roe_ratio_tickers,
        })

        setroceRatio({
            series: [{  
                name: 'ROCE Ratio',
                data: roce_ratio_dataset,
            }],            
        })
        setroceTick({
            "Series1": roce_ratio_tickers,
        })

        setmgmRatio({
            series: [{  
                name: 'Management Score',
                data: mgm_score_dataset,
            }], 
        })
        setmgmTick({
            "Series1": mgm_score_tickers,
        })

        setLoading(false)
        return (() => {})
    }, [])


    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md: 18}, 
                        fontWeight: '300', 
                        mt: '1%',
                        lineHeight: 1.5}}>
                        Now that we've examined the variety of financial ratios, it's time to shift our attention to the return 
                        margins (ratios) of the companies in the sector, which are indicative of their ability to generate returns 
                        for shareholders. Though originally considered a subset of profitability ratios, we have dedicated a section 
                        to analyzing them owing to their critical importance. These ratios measure the effectiveness of an investment, 
                        allowing shareholders to evaluate whether the highest possible return is being generated on their investment. 
                        In line with this, three crucial return metrics for the companies in the sector have been computed: return on
                        assets (ROA), return on equity (ROE), and return on capital employed (ROCE).
                </Typography>

                <Sectionheader head={'3.7.1 Metric Definition'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Return on Assets (ROA): </Box>Return on assets is 
                        a type of return on investment (ROI) metric that measures the profitability of a business in relation to its 
                        total assets. This ratio indicates how well a company is performing by comparing the profit (net income) it's 
                        generating to the capital it's invested in assets. The higher the return, the more productive and efficient 
                        management is in utilizing economic resources. 
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Return on Assets = Net Income &frasl; Average Assets
                    </Typography>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Return on Equity (ROE): </Box>Return on equity expresses the 
                        percentage of net income relative to stockholders' equity, or the rate of return on the money that equity investors 
                        have put into the business. The ROE ratio is one that is particularly watched by stock analysts and investors. A 
                        favorably high ROE ratio is often cited as a reason to purchase a company's stock. Companies with a high return on 
                        equity are usually more capable of generating cash internally, and therefore less dependent on debt financing. 
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Return on Equity = Net Income &frasl; Shareholders' Equity
                    </Typography>
                </Box>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Return on Capital Employed (ROCE):</Box> ROCE measures a company's 
                        profitability in terms of all of its capital. In other words, this ratio can help to understand how well a company is 
                        generating profits from its capital as it is put to use. Unlike other return metrics such as ROE, which only analyzes 
                        profitability related to a company's shareholders' equity, ROCE considers both debt and equity. This can help neutralize 
                        financial performance analysis for companies with significant debt. 
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Return on Capital Employed = Earnings before interest and tax (EBIT) &frasl; Total Asset - Total Current Liabilities
                    </Typography>
                </Box>

                <Sectionheader head={'3.7.2 Sector Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The metrics for companies within the sector were calculated and winsorized at the top (1.5) and bottom (-1.5). 
                    Analyzing these metrics along with other financial data enables readers to evaluate the effectiveness of its
                    capital utilization by management. <Box fontWeight='fontWeightMedium' display='inline'> Figure 40 </Box> to 
                    <Box fontWeight='fontWeightMedium' display='inline'> 43</Box> illustrate the relationship between the margins 
                    and 1-year returns for all companies in the {sector} sector.
                </Typography>

                <MetricSector data={roaRatio} name={''} mjsn={roaTick}/>

                <Figurecaption caption={'Figure 40: ROA margin (%) versus winsorized 1-year return'}/>

                <MetricSector data={roeRatio} name={''} mjsn={roeTick}/>

                <Figurecaption caption={'Figure 41: ROE margin (%) against 1-year return across the sector'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                    An extremely high ROE may be considered positively if it results from a substantially high net income 
                    (nominator in the ROE formula) relative to equity (denominator), indicative of robust company and management 
                    performance. Nonetheless, such high ROE levels are frequently attributable to a relatively small equity base 
                    in comparison to net income, thereby signaling potential risk. One possible concern associated with a high ROE 
                    is the volatility of profits. 
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                    In instances where a company's equity base is minimal or diminishing, and its net income has seen considerable 
                    growth in recent quarters, the denominator in the ROE formula becomes significantly smaller after prolonged 
                    periods of losses, rendering the ROE disproportionately high and potentially misleading.
                </Typography>

                <MetricSector data={roceRatio} name={''} mjsn={roceTick}/>

                <Figurecaption caption={'Figure 42: ROCE margin (%) versus 1-year winsorized return'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Table 9</Box> provides statistics on the distribution of 
                    management (return margin) metrics for the sector, showcasing them in percentages (multiplied by 100).
                </Typography>

                <Figurecaption caption={'Table 8: Statistics of cashflow ratios distribution'}/>

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats table={Statistics}/>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}> 
                    It serves as a helpful resource for readers to establish their expectations regarding the sector's average 
                    return margins. For example, when examining the mean ROA, investors can anticipate that, on average, every 
                    $1,000 of company asset generated net {ngl} of <Box fontWeight='fontWeightMedium' display='inline'>${
                    Math.round(1000 * Statistics['ROA (%)']['mean'])/100}</Box>. Additionally, a typical company in the {sector} 
                    has produced net {ngl2} of <Box fontWeight='fontWeightMedium' display='inline'>${Math.round(1000 * 
                    Statistics['ROE (%)']['mean'])/100}</Box> of every $,1000 in shareholders' equity. For those assessing 
                    companies in capital-intensive sectors, ROCE can be an especially informative metric to consider.
                </Typography>

                <Sectionheader head={'3.7.3 Scoring Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>

                    The level of asset intensity can significantly impact the return on investment (ROI) and return on equity
                    (ROE) of various companies, depending on their specific sector, industry, and individual characteristics. A
                    score ranging from 1 to 10 is assigned to each management ratio (return margin), taking into account the 
                    sector-wide distribution of these ratios. In the final phase, a composite score is calculated for each company, 
                    representing the weighted average of each individual score. <Box fontWeight='fontWeightMedium' display='inline'>
                    Figure 43</Box> illustrates the distribution of the composite score in relation to winsorized 1-year return values 
                    for companies in the sector.

                </Typography>

                <ScoreSector series={mgmRatio} labels={mgmTick}/>

                <Figurecaption caption={'Figure 43: Composite (total) management score vs. 1-year return'}/>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Lastly, the highest composite scores for thestocks in the sector were extracted and displayed in <Box 
                    fontWeight='fontWeightMedium' display='inline'>Figure 44</Box>. Currently, ticker <Box fontWeight='fontWeightMedium' 
                    display='inline'>{highScore.names[0]}</Box> ranks first with the {adj} of <Box fontWeight='fontWeightMedium' 
                    display='inline'>{highScore.data[0]}</Box> among the selecte companies.
                </Typography>

                <ScoreTop10 data={highScore.data} names={highScore.names} label={''}/>

                <Figurecaption caption={'Figure 44: Companies with highest total cashflow score'} />

                {/* <Figurecaption caption={'Companies with highest market value score'}/>

                <Box>
                    <ScoreTable table={scoreBreakdown} score={['P/B', 'P/S', 'P/CF']}/>
                </Box> */}

            </Box>
            }
        </div>
    )

}