import React from "react";
import Layout from "../components/layout";
import Emailform from "../components/emailForm";
import { Box, Container, Typography } from "@mui/material";
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';

function Contactpage() {

    const theme = useTheme()
    
    return (
        <div>
            <Helmet>
                <title>Contact us</title>
                <meta name="description" content={'Contact FARSIGHT studies'} />
                <meta name="keywords" content="sector report, online purchase, contact us" />

                <meta property="og:title" content={'Contact us'} />
                <meta property="og:description" content={'Contact FARSIGHT studies'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://farsight.report/contact'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Contact us'} />
                <meta name="twitter:description" content={'Contact FARSIGHT studies'} />
            </Helmet>
            <Layout>
                <Container maxWidth='md' sx={{background: theme.palette.background.default}}>

                    <Box sx={{
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center'}}>
                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: 48, 
                                fontWeight: 800, 
                                pt: '4%',
                                pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                Contact Us
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        height: '100%', 
                        width: '100%'}}>
                        <Typography textAlign={'justify'} sx={{
                            mt: '4%', 
                            mb: '10%', 
                            fontSize: 20, 
                            fontWeight: 300,
                            width: '90%'}}>
                            Need more information? We'd love to hear from you! We are here to answer any and all questions you might have.
                        </Typography>
                    </Box>

                    <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                        <Emailform />
                    </Box>

                    <Box sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '30vh', width: '100%'}}>
                        
                    </Box>


                </Container>

            </Layout>
        </div>
    )

}

export default Contactpage