import React from "react";
import { Typography, Container, Box } from "@mui/material";
import Layout from "../components/layout";
import { Helmet } from 'react-helmet';

export default function Privacypage() {

        //     {/* <div>
        //     <p>

        //         1. What Information is Collected & How is it Used?

        //         2. How is Collected Information Used?

        //         3. Third Party Websites.

        //         4. How is Information Shared

        //         5. How is my Data Stored and Kept Secure?

        //         6. Your Choices: How Do I Review or Revise Account Information or Withdraw Consent?

        //         7. COPPA and Access by Minors.

        //         8. EU-U.S. and Swiss-US Privacy Shield Frameworks Summary

        //         FARSIGHT complies with the EU-US and Swiss-US Privacy Shield Frameworks as set forth by the US Department of Commerce regarding the collection, use, and retention of personal information from European Union and Switzerland to the United States, respectively. FARSIGHT has certified that it adheres to the Privacy Shield Principles of Notice, Choice, Accountability for Onward Transfer, Security, Data Integrity and Purpose Limitation, Access, and Recourse, Enforcement and Liability.  If there is any conflict between the policies in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern.  To learn more about the Privacy Shield program, and to view our certification page, please visit PrivacyShield.gov

        //         In compliance with the EU-US and Swiss-US Privacy Shield Principles, FARSIGHT commits to resolve complaints about your privacy and our collection or use of your personal information Subjects of the European Union i with inquiries or complaints regarding this privacy policy should first contact FARSIGHT at support@FARSIGHT.com.

        //         FARSIGHT has further committed to refer unresolved privacy complaints under the Privacy Shield Principles to an independent dispute resolution mechanism, the BBB EU PRIVACY SHIELD, operated by BBB National Programs. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed, please visit https://bbbprograms.org/privacy-shield-complaints/ for more information and to file a complaint. This service is provided free of charge to you.

        //         Please note that if your complaint is not resolved through these channels, under limited circumstances, EU and/or Swiss individuals may seek a binding arbitration option before a Privacy Shield Panel.

        //         Consistent with the terms of this Privacy Policy, FARSIGHT collects personal data from client, customers and applicants for the sake of logging into our system (email and password) and then further data is collected in order to run startup program competitions and other similar programs. The data collected is primarily determined by our clients in their application forms that are hosted on our website, and our Privacy Policy and Terms and Conditions govern the use of this data

        //         FARSIGHT receives information from users, in our case startups, and then that information is shared with the organizers of the programs, which are our direct clients. When startup users share information with FARSIGHT about their startup, they are fully aware and intentional in providing it to the organizers of the programs.

        //         User have the right to access, correct and delete their personal information as stated in our Privacy Policy and Terms of Service.

        //         Users have the right to edit or delete their information from their Account page and request additional information be deleted unless otherwise permitted by law.

        //         Additionally, on this page, users can opt-into or out of email communications. Finally, when a user provides any information to program organizers using our website, they can always access and view the information. Before a program deadline they can access, view, edit and delete any information. After a program deadline, they are unable to edit that information, but they continue to have access. If needed they can always contact either FARSIGHT and/or the organizer of the program to delete the information, where permitted by law..

        //         FARSIGHT is subject to the investigatory and enforcement powers of the Federal Trade Commission (FTC).

        //         FARSIGHT also may be required to disclose an individual’s personal information in response to a lawful request by public authorities, including to meet national security or law enforcement requirements.

        //         In cases of onward transfer to third parties of data of EU and Swiss individuals received pursuant to the EU-US and Swiss Privacy Shield Frameworks, FARSIGHT is potentially liable.

        //         9. How Does FARSIGHT Respond to "Do Not Track" Signals?

        //         10. How Will I Be Notified of Any Changes to this Privacy Policy?

        //         11. Questions?

        //     </p>
        // </div>
        //          */}



    return(
        <div>
            <Helmet>
                <title>FARSIGHT privacy policy</title>
                <meta name="description" content={'FARSIGHT privacy policy'} />
                <meta name="keywords" content="sector report, online purchase, FARSIGHT privacy policy" />

                <meta property="og:title" content={'FARSIGHT privacy policy'} />
                <meta property="og:description" content={'FARSIGHT privacy policy'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://farsight.report/privacy'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'FARSIGHT privacy policy'} />
                <meta name="twitter:description" content={'FARSIGHT privacy policy'} />
            </Helmet>
            <Layout>
                <Container maxWidth='lg'>
                    
                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center'}}>
                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: 48, 
                                fontWeight: 800, 
                                pt: '4%',
                                pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                FARSIGHT PRIVACY POLICY
                        </Typography>

                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: 32, 
                                fontWeight: 600, 
                                width: '100%', 
                                height: '100%'}}>
                                Effective Date: May 12, 2023
                        </Typography>
                    </Box>

                    <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: {xs: 16, md:20}, 
                                fontWeight: 300,
                                pt: '2%', 
                                width: '100%', 
                                height: '100%'}}>
                                Welcome to FARSIGHT! This Privacy Policy ("Policy") applies to your access and use of farsight.report ("the Site") and the software and services 
                                available on the Site or offered by our subsidiaries ("FARSIGHT Services"). This Policy expressly discloses 1) the types of personal and non-personal 
                                information collected as a result of your use of the Site and the FARSIGHT Services; 2) how such data is used and stored; and 3) your choices and 
                                options regarding sharing and use of your data. This Privacy Policy is incorporated as part of the FARSIGHT Terms of Service. We take your privacy 
                                seriously. If you have any questions about this policy or about privacy at FARSIGHT, please contact us at support@FARSIGHT.report.                              
                            </Typography>
                        </Box>

                    </Box>

                    <Box sx={{display: "flex", width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>

                        <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                                <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                    <Typography paragraph textAlign={'justify'} sx={{
                                        fontSize: 20, 
                                        fontWeight: 400, 
                                        lineHeight: 2,
                                        pt: '2%',
                                        width: '100%', 
                                        height: '100%'}}>
                                        I. Collected Information and Usage
                                    </Typography>
                                </Box>

                                <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                    <Typography paragraph textAlign={'justify'} sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300, 
                                        width: '100%', 
                                        height: '100%'}}>
                                        Data Collected When You Visit Our Site:

                                        Tracking Data ("Tracking Information")

                                        Beacons (also known as Web bugs, pixel tags or clear GIFs): When a user visits the Site'’'s homepage, FARSIGHT utilizes an electronic file, called a web beacon, to count 
                                        users that have visited the Site. Beacons are tiny graphics with a unique identifier that may be included on our Site Services for several purposes, including to deliver 
                                        or communicate with Cookies, to track and measure the performance of the FARSIGHT Services, to monitor how many visitors view our Site. Unlike Cookies, which are stored 
                                        on the user's device, Web Beacons are typically embedded invisibly on web pages (or in an e-mail).We do not use web beacons to track any activity related to user accounts.

                                        Cookies and our Cookie Policy

                                        FARSIGHT Cookie Policy. We use cookies, small strings of numbers and letters that are placed on your browser. We use a persistent cookie that contains login information which 
                                        automatically logs you in when you return to our website. We also use the Google analytics and Cookiebot cookies to help us understand how users use our Site and to improve 
                                        FARSIGHT Services. If you login with your Facebook, Twitter and LinkedIn account, cookies may also be placed in accordance with their privacy policies. You can set your browser 
                                        to remove or reject cookies; however, some Site features or Services may not work properly without cookies.

                                        Personal Information

                                        IP Addresses: When you visit the Site or use Services our servers automatically record information that your browser sends whenever you visit a website ("Log Data"). This Log 
                                        Data may include information such as your computer's Internet Protocol ("IP") address, browser type or the webpage you were visiting before you came to the Services, pages of 
                                        the Site that you visit, the time spent on those pages, information you search for on the Services, access times and dates and other statistics.

                                        Data Collected During Account Creation: We will collect your name and e-mail address and password when you set up an account with us as a customer or as a competition entrant 
                                        ("Account Information"). When you create an account to access the FARSIGHT Services, you may sign up either with your email or through certain social media accounts. If you sign 
                                        up directly with your email address, we will collect your First and Last Name, Email Address and ask you to create and confirm an account password. If you sign up using your 
                                        Facebook, Twitter or LinkedIn accounts, you will be asked for your account login information. By using third party social media accounts to create an account, you are allowing 
                                        FARSIGHT to access personal information in those accounts in order to pre-populate your account sign-up process with information from those accounts. All information is accessed 
                                        by FARSIGHT in accordance with the privacy policies of Facebook, Twitter and LinkedIn.

                                        Data Collected When You Set Up a Profile: After you set up your account, we may give you the option of setting up a profile to describe yourself. 

                                        {/* Data Collected When You Apply to Enter A Competition. When you apply to enter a competition that is powered by the FARSIGHT platform, you may be required to provide both Personal Information (such as your name, address, passport identification number, contact information, email, phone number, resume or biography) and information about your startup (such as a mission statement, pitch deck, financials, market strategy, etc.) ("Competition Information"). */}

                                        {/* With respect to Competition Information, FARSIGHT and our subsidiaries are generally the Data Processor acting on behalf of the competition organizer or organizers identified in each applicable contest ("Competition Organizer’).  The type of Competition Information collected is determined by the Competition Organizer in their sole and absolute discretion, and their collection and use of that information is governed by the rules, regulations, guidelines and privacy policy of a given Competition Organizer. Entrants should review the applicable rules and policies of a Competition Organizer to understand the scope of their collection, use and storage practices. */}

                                        {/* Competitions Sponsored and Organized by FARSIGHT. In the event that a competition is sponsored and organized by us or a subsidiary, we will clearly identify and disclose how we intend to use the information from our competitions so that you can decide if you want to enter and provide your Competition Information to us as well as know who to contact regarding your Competition Information.  For competitions that we sponsor, we will not ask for your race, ethnicity or health information or any other data that is classified as "Sensitive Information" under Article 9 of the General Data Privacy Regulation. */}

                                        Data Collected When You Subscribe to or Purchase FARSIGHT Services: When you subscribe to or purchase any FARSIGHT Services, FARSIGHT may additionally collect your email, first and 
                                        last name, billing address, credit card number and security code in order to process the transaction. FARSIGHT may also, upon request, mail an invoice to the mailing address provided 
                                        by a user to facilitate payment. 

                                        {/* Data Collected When You Like or Share Competitions. Users may like, share or follow various competitions powered by the FARSIGHT platform on Facebook, Twitter, or LinkedIn. Users should review the privacy policies of Facebook, Twitter and LinkedIn for more detail about information collected from these sites. For an updated list of third party social plug-ins, click here. */}

                                        Data Collected for Purchases and Subscriptions. When someone makes a payment related to our Services, we collect and transmit Account Information and credit card payment information and 
                                        transmit it our payments processor.  We do not store credit card payment information.

                                    </Typography>
                                </Box>

                        </Box>


                        <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    width: '100%', 
                                    height: '100%'}}>
                                    II. How is Collected Information Used?
                                </Typography>
                            </Box>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                                <Typography 
                                    paragraph 
                                    textAlign={'justify'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300, 
                                        width: '100%', 
                                        height: '100%'}}>

                                    Subsidiaries:  Any information we share with subsidiaries will be subject to intra-company data sharing agreements that incorporate EU Model Contractual Clauses.

                                    Account Information: FARSIGHT and its subsidiaries may utilize email addresses provided during the creation of an account to (i) confirm account creation and confirm 
                                    the validity of information provided, (ii) send secure links to users to access the Site or FARSIGHT Services, (iii) send confirmation of financial transactions; (iv) 
                                    communicate with our users to provide information regarding FARSIGHT Services or respond to user inquiries, (v) notify users of material changes to our Terms of Service 
                                    and Privacy Policy or (vi) inform user of updated product and services. With your consent, users will receive FARSIGHT's monthly newsletters or targeted notifications 
                                    regarding new products and services available by FARSIGHT. 
                                    {/* Users have the option to opt-out of these email updates if they prefer from the Account page. */}

                                    {/* Competition Information: You must provide your Competition Information in order to enter into a competition as it is necessary to administer your applicable entry and the competition. We use your Account Information, Competition Information and Tracking Information to administer the Services, competitions,  provide customer services, improve the Site and Services and recommend other competitions that might be of interest to you. When you do enter a competition, you authorize FARSIGHT to share your Account Information and applicable Competition Information with applicable Competition administrators and judges solely for the purpose of administering the competition unless you authorize or consent for the Competition Organizers to do otherwise. Except for the Competition Organizers, judges and administrators, We do not share your Competition Information with any third parties. In addition, FARSIGHT and its subsidiaries may anonymize and aggregate Competition Information , Tracking Information and Account Information to which it has access to generate reports regarding trends in vertical markets.  With the consent of the competition entrant, FARSIGHT may prepare profiles of top startups and to facilitate matchmaking and other communications between relevant third party entities and relevant startups.

                                    Sharing Your Profile. We or our subsidiaries may offer the opportunity for you to share information in your profile with organizations that may be interested in supporting your efforts. We will only do this with your consent, which you may withdraw at any time from your account preferences. */}

                                    Credit or Debit Card Information: FARSIGHT and its subsidiaries do not store any credit card data associated with any purchases processed on the Site. FARSIGHT utilize Stripe 
                                    to process recurring payments and utilizes Stripe as a payment gateway for those payments. When you enter your payment information, Stripe sends us back a token that we store 
                                    in our servers. This token is linked to but cannot be converted to your credit or debit card information. You should review Stripe's Privacy Policy for more detail about how 
                                    your information is collected, stored and maintained by these third party payment processors.

                                    Anonymous Data Analytics: FARSIGHT companies utilizes Tracking Information, Log Information and Google Analytics to access anonymous data to help us understand how our Services 
                                    are used. We use Tracking Information to customize content for you and improve our Services. Google Analytics is a web analytics tool that helps FARSIGHT understand how users 
                                    engage with our Site, so that we can review and improve our Services. Google Analytics collects information anonymously. It provides a report to FARSIGHT with website trends 
                                    without identifying individual visitors.

                                    Data Retention: We retain data as long as your account is active. If you do not use your account for one year, we reserve the right to delete your information. We may de-identify 
                                    and anonymize data you provide to us to use for the purposes of research and analyzing trends which we may share with third parties, and improving the Services.

                                </Typography>
                            </Box>

                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    width: '100%', 
                                    height: '100%'}}>
                                    III. Third Party Websites
                                </Typography>
                            </Box>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                    Our site contains links to other websites. When you click on one of these links, you are 'clicking' to another website. FARSIGHT does not 
                                    control the data collection or privacy practices of such third party sites. We encourage you to read the privacy policies of any third party 
                                    sites, as their collection, use and storage practices and policies may differ from ours.                               
                                </Typography>
                            </Box>

                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    width: '100%', 
                                    height: '100%'}}>
                                    IV. How is Information Shared
                                </Typography>
                            </Box>

                            <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography 
                                textAlign={'justify'}
                                paragraph 
                                sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                    Third Party Services
                                </Typography>
                            </Box>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography 
                                textAlign={'justify'}
                                paragraph 
                                sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                    We may work with third parties to administer the Site and Services. We use data storage services in the United States (Google Firebase Storage) to store your Account 
                                    Information, to allow us to provide our Services. We use customer management tools to help us manage our customer relationships and manage our projects using Account 
                                    Information, Profiles, to provide the Services from servers in the United States. We take steps to ensure that these parties take protecting your privacy as seriously 
                                    as we do, including entering into Data Processing Addendum, EU Model Clauses and/or ensuring they have EU-U.S. and Swiss-US Privacy Shield certification. In addition, 
                                    we also use the following third party services that provide Personal Data to us. 
                                    Google Analytics: FARSIGHT utilizes Google Analytics to collect information about the 
                                    use of this service only on the Site. Google Analytics collects information such as how often users visit this site, what pages they visit when they do so, and what 
                                    other sites they used prior to coming to this site. We use the information we get from Google Analytics only to improve this site, but in anonymous form. Google 
                                    Analytics collects only the IP address assigned to you on the date you visit this site and assigns a user ID code, rather than your name or other identifying information. 
                                    We do not combine the information collected through the use of Google Analytics with personally identifiable information. Google uses this information to analyze your 
                                    use of the website, to generate reports about website activities for website operators and to provide further services related to website and internet use. Google may 
                                    also share such information with third parties to the extent it is legally required to do so and/or to the extent third parties process data on behalf of Google. Although 
                                    Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this site, the cookie cannot be used by anyone but 
                                    Google. Google's ability to use and share information collected by Google Analytics about your visits to this site is restricted by the Google Analytics Terms of Use and 
                                    the Google Privacy Policy as well as the Google Analytics Data Processing Agreement. Site usage is tracked using Google Analytics in accordance with their Privacy Policy. 
                                    However, if you do not want your data to be used by Google Analytics, you may opt-out by installing Google Analytics Opt-out Browser Add-on. Data processing takes place in 
                                    the United States and Google is self-certified under the EU-U.S. and Swiss-US Privacy Shield. You may deactivate Google Analytics with the help of a browser add-on if you 
                                    do not want this website analysis.

                                    reCapcha: FARSIGHT uses reCapcha, a service from Google, to protect the Site from spam and abuse. Users should review reCapcha's Privacy Policy through Google for more 
                                    information. Data processing takes place in the United States and Google is self-certified under the EU-U.S. and Swiss-US Privacy Shield.

                                    Stripe: FARSIGHT utilizes Stripe as a payment gateway for payments collecting Account Information and payments information to validate payment and return an anonymous token. 
                                    FARSIGHT does not store your credit card information. Data Processing takes place in the United States and Stripe is self-certified under the EU-U.S. and Swiss-US Privacy Shield. 
                                    Users should review Stripe's Security Policy before initiating transactions on the Site.

                                    Additional Circumstances When Disclosures May Be Made to Third Parties

                                    Information collected by FARSIGHT may be disclosed when (i) we are required to do so by law, or (ii) in the event of a change of ownership.

                                    When Disclosure or Use is Otherwise Permitted. Account Information may be provided where we are required to do so by law, or if we believe in good faith that it is reasonably 
                                    necessary (i) to respond to claims asserted against FARSIGHT or to comply with the legal process (for example, discovery requests, subpoenas or warrants); (ii) to enforce or 
                                    administer our policies and agreements with users; (iii) for fraud prevention, risk assessment, investigation, customer support, product development and de-bugging purposes; or 
                                    (iv) to protect the rights, property or safety of FARSIGHT, its users or members of the general public. We will use commercially reasonable efforts to notify users about law 
                                    enforcement or court ordered requests for data unless otherwise prohibited by law. However, nothing in this Privacy Policy is intended to limit any legal defenses or objections 
                                    that you may have to any third party request to compel disclosure of your information.

                                    Acquisition/Merger/Assignment or other Business Transfer: FARSIGHT may sell, transfer or otherwise share some or all of its assets, including your Personal Information, in connection 
                                    with a merger, acquisition, reorganization or sale of assets or in the event of bankruptcy. Under such circumstances, FARSIGHT will use commercially reasonable efforts to notify its 
                                    users if their personal information is to be disclosed or transferred and/or becomes subject to a different privacy policy.

                                </Typography>
                            </Box>
                        </Box>   

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    width: '100%', 
                                    height: '100%'}}>
                                    V. How is my Data Stored and Kept Secure?
                                </Typography>
                            </Box>

                            <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>

                                    Security Measures: We have implemented reasonable administrative, technical and physical security measures to protect your personal information 
                                    against unauthorized access, destruction or alteration. For example:

                                    – SSL encryption (https) everywhere where we deal with personal data.

                                    – You cannot retrieve a lost password; you can only set a new one once you receive a reset email.

                                    – Data is kept on secure servers, located in the US.

                                    Restricting staff access to Collected Information and Account Information

                                    Payments Encryption: FARSIGHT utilizes only PCI-DSS compliant third party payment processors to ensure the security of your personal information. Users 
                                    should review Stripe's Security Policy before initiating transactions on the Site.

                                    Abuse Prevention: FARSIGHT uses reCapcha, a service from Google, to protect the Site from spam and abuse. Users should review reCapcha's Privacy Policy 
                                    through Google for more information.

                                    Despite our best efforts, users should be advised that no method of transmission over the Internet and no method of storing electronic information can ever by 100% secure.
                    
                                </Typography>
                            </Box>

                        </Box>                 

                        <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    width: '100%', 
                                    height: '100%'}}>
                                    VI. Review or Revise Account Information or Withdraw Consent
                                </Typography>
                            </Box>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                    Viewing or Correcting Your Information

                                    You can access and update Account Information in your account at any time by logging into your account. If you need assistance to delete inaccuracies within your personal 
                                    information or would like to know what information about you was collected, please contact us at support@FARSIGHT.com. We will respond to your request within 10 business days. 
                                    We reserve the right to charge for copies of data undergoing processing.

                                    {/* Removal or Withdrawing Consent

                                    Since we use your Account Information to provide the Services to you, if you completely delete all such information, then your account may become deactivated. If you would like us 
                                    to delete your Account Information, follow the link from your account page to submit a request that we delete your Account Information. In such cases we will only retain your Account 
                                    Information as required by law, or for legitimate business purposes permitted by law or de-identify and anonymize information that we wish to retain so that it cannot be connected to you. */}


                                    All deletion and rectification requests are subject to FARSIGHT procedures to confirm your identity.

                                    {/* Marketing Communications

                                    Users have the option to opt-out of email updates if they prefer from their Account page. */}

                                    {/* Data Portability

                                    If you would like us to transmit your personal data to another company providing similar services, we will work with them to do so upon request and verification of such request with both the requestor and the company receiving the Personal Data. */}

                                    {/* Right to Redress.

                                    FARSIGHT has further committed to refer unresolved privacy complaints under the Privacy Shield Principles to an independent dispute resolution mechanism, the BBB EU PRIVACY SHIELD, operated by BBB National Programs. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed, please visit https://bbbprograms.org/privacy-shield-complaints/ for more information and to file a complaint. This service is provided free of charge to you. */}

                                    {/* If you provided your Personal Information in connection with a competition sponsored by a third party and wish to delete your information or withdraw from a competition, please contact the Competition Organizer.  If we are organizing the competition you may make the request directly to us via support@FARSIGHT.com.com */}

                                    Your California Privacy Rights

                                    California residents who have an established business relationship with FARSIGHT may make a written request to FARSIGHT about whether FARSIGHT has disclosed any Personal Information to 
                                    any third parties for the third parties' direct marketing purposes during the prior calendar year. To make such a request, please send an email:

                                    support@FARSIGHT.report

                                </Typography>
                            </Box>

                        </Box>

                        <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    width: '100%', 
                                    height: '100%'}}>
                                    VII. COPPA and Access by Minors
                                </Typography>
                            </Box>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                    FARSIGHT does not knowingly collect or store any personal information from or about children under the age of 18. If you believe a child under the age of 18 has under any 
                                    circumstances provided us with personal information and data, a parent or legal guardian can email us at support@FARSIGHT.report to request that their children's information 
                                    be deleted from our records.
                                </Typography>
                            </Box>

                        </Box> 

                        <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    width: '100%', 
                                    height: '100%'}}>
                                    VIII. FARSIGHT Respond to "Do Not Track" Signals
                                </Typography>
                            </Box>
                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                    "Do Not Track" or DNT is a feature enabled on some browsers that sends a signal to request that a web application disable its tracking or cross-site user tracking. At present, 
                                    our Site does not respond to or alter its practices when a DNT signal is received.
                                </Typography>
                            </Box>

                        </Box>

                        <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    width: '100%', 
                                    height: '100%'}}>
                                    IX. Notification of Any Changes to this Privacy Policy
                                </Typography>
                            </Box>
                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                    FARSIGHT reserves the right to amend this Privacy Policy at any time. If FARSIGHT makes material changes to its Privacy Policy, we will notify you by (1) 
                                    changing the Effective Date on our Privacy Policy and provide additional notification either (1) via email or other means as we may deem commercially 
                                    reasonable. Your continued use our Site and/or the FARSIGHT Services after such changes have been made to the Privacy Policy constitutes your consent to our 
                                    modified data collection and use practices.
                                </Typography>
                            </Box>

                        </Box>

                        <Box sx={{
                            display: "flex", 
                            flexDirection: 'column',
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            height: '100%', 
                            width: '100%', mb: '5%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    // pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    X. Questions
                                </Typography>
                            </Box>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography paragraph textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    width: '100%', 
                                    height: '100%'}}>
                                    If you have any questions about our Privacy Policy or need to contact us for any reason, we can be reached:

                                    By Email: support@FARSIGHT.com    
                                </Typography>
                            </Box>

                        </Box>

                    </Box>

                </Container>
            </Layout>
        </div>
    )
}


