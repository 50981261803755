import { useEffect, useRef, useState} from "react"
import { Button, Typography, TextField, Alert, Container, Box, Stack } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Layout  from '../components/layout';
import { useTheme } from '@mui/material/styles';
import { UserAuth } from "../context/userContext";

const ColorButton = styled(Button)(({ theme }) => ({
    marginBottom: 2,
    marginTop: 2,
    color: theme.palette.common.white,
    alignItems: 'center',
    fontSize: 16,
    fontFamily: 'inherit',
    width: '100%',
    backgroundColor: purple[200],
    borderColor: purple[200],
    '&:hover': {
      backgroundColor: purple[400],
      borderColor: purple[400]
    },
  }));

export default function ForgetPass() {

    const { passwordReset } = UserAuth()
    const theme = useTheme()
    // const [err, setErr] = useState('')
    const [resetAlert, setResetAlert] = useState(false)

    const emailRef = useRef()

    function handleReset(emailRef) {

        console.log('I am gere!')

        setResetAlert(true)

    }

    useEffect(()=> {


        return() => {
            console.log('Leaving Forget page!')
        }

    }, [resetAlert])

    return(
        <Layout>
            <Container 
                maxWidth='lg' 
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '85vh',
                    bgcolor: theme.palette.background.default,
                    }}>

                        <Stack spacing={2} sx={{ width: {xs: '100%', md: '50%'} }}>

                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize:  {xs: 36, md: 48}, 
                                fontWeight: 800
                                }}>
                                Forget password?
                            </Typography>
                        </Box>

                        <Box>
                            <Typography 
                            textAlign={'justify'} 
                            sx={{
                                fontFamily: 'inherit', 
                                fontSize: {xs: 16, md: 18}, 
                                fontWeight: 300}}>
                            Lost your password? Please enter your username or email address. You will receive a link to create a new password via email.
                            </Typography>
                        </Box>

                        {
                            resetAlert 
                            ? 
                            <>
                            <Alert severity="info">
                                An email was sent to your account with reset instructions.
                            </Alert>
                            </> 
                            : 
                            <>
                            {/* <Alert severity="warning">False</Alert> */}
                            </>
                        }

                        <Box sx={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        height: '100%', 
                        width: '100%'}}>
                        <TextField 
                            size='small' 
                            fullWidth 
                            type="email" 
                            color="primary" 
                            variant="outlined" 
                            label="Email" 
                            inputRef={emailRef}
                            InputProps={{sx: {bgcolor: 'white'}}}>
                                Email
                        </TextField>
                    </Box>

                    <Box sx={{
                        display: "flex", 
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        height: '100%', 
                        width: '100%'}}>
                        <ColorButton 
                            variant='contained'
                            disableElevation
                            onClick={() => handleReset(emailRef)}>
                            Reset
                        </ColorButton >
                    </Box>

                        </Stack>





            </Container>
        </Layout>
    )

}