import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";
// import { alpha, useTheme } from '@mui/material/styles';
// import { setDoc, doc, getDocs, getDoc, onSnapshot, collection } from "firebase/firestore";
// import { auth, db } from '../firebase/index';
import { Sectionheader, Figurecaption } from './reportBlocks';
import { MetricsStats, ScoreTable } from './tableblocks';
import { MetricSector, ScoreSector, ScoreTop10 } from './figureBlocks';

export default function Cashflow({sector, input}) {

    // const theme = useTheme()
    const [loading, setLoading] = useState(true)

    const CFS = input.data
    const Statistics = input.stats
    const highScore = input.highScore
    // const scoreBreakdown = input.topScores

    const mysign = ( (Statistics['OCF ratio']['mean'] > 0) ? 'positive' : 'negative')

    const [ocfRatio, setocfRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [ocfTick, setocfTick] = useState({
        "Series1": []
        }
    )

    const [cfdRatio, setcfdRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [cfdTick, setcfdTick] = useState({
        "Series1": []
        }
    )

    const [fcocRatio, setfcocRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [fcocTick, setfcocTick] = useState({
        "Series1": []
        }
    )

    const [cashflow, setcashflow] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [cashflowTick, setcashflowTick] = useState({
        "Series1": []
        }
    )

    useEffect( () => {
        let ocf_ratio_dataset = []
        let ocf_ratio_tickers = []

        let cfd_ratio_dataset = []
        let cfd_ratio_tickers = []

        let fcoc_ratio_dataset = []
        let fcoc_ratio_tickers = []

        let cashflow_score_dataset = []
        let cashflow_score_tickers = []
        for (const [key, value] of Object.entries(CFS)) {
            
            ocf_ratio_dataset.push([value[3], value[0], value[4]])
            ocf_ratio_tickers.push({'ticker': key})

            cfd_ratio_dataset.push([value[3], value[1], value[4]])
            cfd_ratio_tickers.push({'ticker': key})
            
            fcoc_ratio_dataset.push([value[3], value[2], value[4]])
            fcoc_ratio_tickers.push({'ticker': key})

            cashflow_score_dataset.push([value[3], value[8], value[4]])
            cashflow_score_tickers.push({'ticker': key})
        }

        setocfRatio({
            series: [{  
                    name: 'OCF Ratio',
                    data: ocf_ratio_dataset,
                    }],
                }
            )
        setocfTick({
            "Series1": ocf_ratio_tickers,
        })

        setcfdRatio({
            series: [{  
                name: 'CFD Ratio',
                data: cfd_ratio_dataset,
            }],            
        })
        setcfdTick({
            "Series1": cfd_ratio_tickers,
        })

        setfcocRatio({
            series: [{  
                name: 'FCOC Ratio',
                data: fcoc_ratio_dataset,
            }],            
        })
        setfcocTick({
            "Series1": fcoc_ratio_tickers,
        })

        setcashflow({
            series: [{  
                name: 'Cashflow Score',
                data: cashflow_score_dataset,
            }], 
        })
        setcashflowTick({
            "Series1": cashflow_score_tickers,
        })

        setLoading(false)
        return (() => {})
    }, [])


    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md: 18}, 
                        fontWeight: '300', 
                        mt: '1%',
                        lineHeight: 1.5}}>
                        In prelude to the final section, we'll look at cashflow ratios, which play a crucial role in assessing a company's 
                        cash generation and management. These ratios investigate cash inflows and outflows from three main sources, operating, 
                        investing, and financing activities. They offer meaningful observations into how effectively a company generates cash 
                        from its core operations, allocates its resources through investments, and finances its various activities. Moreover, 
                        they shed light on a company's cash management practices and its potential for sustainable growth. Understanding 
                        cashflow ratios is crucial for investors, analysts, and stakeholders alike. They offer insights beyond profit and loss 
                        statements, emphasizing the importance of cash flow in sustaining and expanding a business. We'll focus on three key 
                        ratios: operating cashflow (OCF), cashflow to debt (CFD), and free cashflow to operating cashflow (FCOC) ratios.
                </Typography>

                <Sectionheader head={'3.6.1 Metric Definition'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Operating Cashflow Ratio (OCF): </Box> The operating cashflow ratio, 
                        is a measure of how well a company can pay off its current liabilities with the cash flow generated from its core business 
                        operations. This financial metric shows how much a company earns from its operating activities, per dollar of current 
                        iabilities.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Operating Cashflow Ratio = Operating Cashflow &frasl; Current Liabilities
                    </Typography>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Cashflow to Debt Ratio (CFD): </Box> The cashflow to debt ratio tells 
                        investors how much cashflow the company generated from its regular operating activities compared to the total debt it has. 
                        For instance, if the ratio is 0.25, then the operating cash flow was one fourth of the total debt the company has on its 
                        books. This debt includes interest payments, principal payments and even lease payments to cover off balance sheet financing.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Cashflow to Debt Ratio = Operating Cashflow &frasl; Total Debt
                    </Typography>
                </Box>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Free Cashflow to Operating Cashflow Ratio (FCOC): </Box> This ratio tells 
                        the investors about how much free cashflow is being generated for every dollar of operating cashflow. A higher ratio means 
                        company is not investing too much in capital expenditure and therefore maybe a mature company that is not seeking any more 
                        growth but rather seeking to sustain its operations.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Free Cashflow to Operating Cashflow Ratio = Free Cashflow &frasl; Operating Cashflow
                    </Typography>
                </Box>

                <Sectionheader head={'3.6.2 Sector Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Cashflow ratios have been computed for the companies in the sector and are illustrated in <Box 
                        fontWeight='fontWeightMedium' display='inline'> Figures 35</Box> through <Box fontWeight='fontWeightMedium' 
                        display='inline'> 37</Box>. The Operating Cashflow (OCF) ratio assesses a company's ability to generate cash 
                        from its core business operations, reflecting how efficiently it can convert sales into actual cash. The Cashflow
                        to Debt (CFD) ratio evaluates a company's capacity to repay its debt obligations using its cashflow, enabling 
                        investors to gauge whether the company can comfortably meet its debt commitments. The Free Cashflow to Operating 
                        Cashflow (FCOC) ratio measures how effectively a company can convert its operating cashflow into free cashflow, 
                        highlighting its capacity to reinvest or distribute cash to shareholders after covering operational needs. 
                    {/* <Box fontWeight='fontWeightMedium' display='inline'> </Box>
                    <Box fontWeight='fontWeightMedium' display='inline'> </Box> */}
                </Typography>

                <MetricSector data={ocfRatio} name={''} mjsn={ocfTick}/>

                <Figurecaption caption={'Figure 35: OCF ratio versus winsorized 1-year return'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>NOTE:</Box> Extreme OCF ratios can raise concerns. An excessively 
                    high ratio may indicate potential overestimation of a company's cash generation or unsustainable practices. On the other 
                    hand, an extremely low OCF ratio signals insufficient cash flow, leading to potential liquidity issues and financial 
                    distress. Readers should carefully analyze the reasons behind these extremes and consider the broader financial context 
                    before making conclusions about a company's financial health.
                </Typography>

                <MetricSector data={cfdRatio} name={''} mjsn={cfdTick}/>

                <Figurecaption caption={'Figure 36: CFD ratio vs. 1-year return across the sector'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>NOTE:</Box> An very high CFD ratio may suggest 
                    that a company has a significant capacity to repay its debt using cash flow, potentially signaling strong financial health. 
                    However, it could also indicate an overemphasis on debt repayment at the expense of other strategic initiatives like investment 
                    or expansion. Conversely, an excessively low CFD ratio may raise concerns about a company's ability to meet its debt obligations, 
                    indicating potential financial strain.
                </Typography>

                <MetricSector data={fcocRatio} name={''} mjsn={fcocTick}/>

                <Figurecaption caption={'Figure 37: FCOC ratio vs. 1-year return (winsorized)'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>NOTE:</Box> A very high FCOC ratio suggests that a company is efficiently 
                    converting its operating cash flow into free cash flow. This can be viewed positively, indicating that the company has significant 
                    surplus cash after covering its operational needs, providing opportunities for reinvestment, debt reduction, or distribution to 
                    shareholders. On the flip side, an excessively low FCOC ratio may raise concerns about the company's ability to generate free cash 
                    flow from its operating activities. This could limit its capacity for strategic investments or returning value to shareholders after 
                    meeting essential operational requirements. Readers should consider industry benchmarks and company-specific circumstances for a 
                    nuanced interpretation.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Table 8</Box> presents statistics summarizing the distribution 
                    of these metrics across the sector, providing insights into sector norms. On average, a company in the {sector} sector 
                    generates <Box fontWeight='fontWeightMedium' display='inline'>${Statistics['FCOC ratio']['mean']}</Box> of free cash flow 
                    for every dollar of operating cashflow. Additionally, the data reveals that the average company has a {mysign} operating 
                    cashflow which is <Box fontWeight='fontWeightMedium' display='inline'>{ Math.abs(Statistics['CFD ratio']['mean'])}</Box> times its 
                    total debt. Furthermore, it presents that for every $1,000 of operating cashflow, an average company carries <Box 
                    fontWeight='fontWeightMedium' display='inline'>${1000 * Statistics['OCF ratio']['mean'] }</Box> of current liabilities on 
                    their balance sheets.
                </Typography>

                <Figurecaption caption={'Table 8: Statistics of cashflow ratios distribution'}/>

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats table={Statistics}/>
                </Box>

                <Sectionheader head={'3.6.3 Scoring Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The total (composite) cashflow score is calculated as the weighted average of the individual scores assigned to each cashflow 
                    metric. <Box fontWeight='fontWeightMedium' display='inline'>Figure 38</Box> presents the distribution of these composite scores 
                    against winsorized 1-year return values for companies in the sector.
                </Typography>

                <ScoreSector series={cashflow} labels={cashflowTick}/>

                <Figurecaption caption={'Figure 38: Composite (total) cashflow score vs. 1-year return'}/>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Figure 39</Box> showcases the top 10 companies that have achieved the highest 
                    composite cashflow scores. Currently, ticker <Box fontWeight='fontWeightMedium' display='inline'>{highScore.names[0]}</Box> holds 
                    the highest overall score of <Box fontWeight='fontWeightMedium' display='inline'>{highScore.data[0]}</Box> among these stocks.
                </Typography>

                <ScoreTop10 data={highScore.data} names={highScore.names} label={''}/>

                <Figurecaption caption={'Figure 39: Companies with highest total cashflow score'} />

                {/* <Figurecaption caption={'Companies with highest market value score'}/>

                <Box>
                    <ScoreTable table={scoreBreakdown} score={['P/B', 'P/S', 'P/CF']}/>
                </Box> */}

            </Box>
            }
        </div>
    )

}