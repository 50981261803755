import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";

import { Sectionheader, Figurecaption } from './reportBlocks';
import { MetricsStats, ScoreTable } from './tableblocks';
import { MetricSector, ScoreSector, ScoreTop10 } from './figureBlocks';

export default function Efficiency({children, sector, input}) {

    // const theme = useTheme()
    const [loading, setLoading] = useState(true)

    const EFFS = input.data
    const Statistics = input.stats
    const highScore = input.highScore
    // const scoreBreakdown = input.topScores

    const [atRatio, setatRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [atTick, setatTick] = useState({
        "Series1": []
        }
    )

    const [ptRatio, setptRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [ptTick, setptTick] = useState({
        "Series1": []
        }
    )

    const [rtRatio, setrtRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [rtTick, setrtTick] = useState({
        "Series1": []
        }
    )

    const [efficiency, setefficiency] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [effTick, seteffTick] = useState({
        "Series1": []
        }
    )

    useEffect( () => {
        let at_ratio_dataset = []
        let at_ratio_tickers = []

        let pt_ratio_dataset = []
        let pt_ratio_tickers = []

        let rt_ratio_dataset = []
        let rt_ratio_tickers = []

        let eff_score_dataset = []
        let eff_score_tickers = []
        for (const [key, value] of Object.entries(EFFS)) {
            
            at_ratio_dataset.push([value[3], value[0], value[4]])
            at_ratio_tickers.push({'ticker': key})

            pt_ratio_dataset.push([value[3], value[1], value[4]])
            pt_ratio_tickers.push({'ticker': key})
            
            rt_ratio_dataset.push([value[3], value[2], value[4]])
            rt_ratio_tickers.push({'ticker': key})

            eff_score_dataset.push([value[3], value[8], value[4]])
            eff_score_tickers.push({'ticker': key})
        }

        setatRatio({
            series: [{  
                    name: 'AT Ratio',
                    data: at_ratio_dataset,
                    }],
                }
            )
        setatTick({
            "Series1": at_ratio_tickers,
        })

        setptRatio({
            series: [{  
                name: 'PT Ratio',
                data: pt_ratio_dataset,
            }],            
        })
        setptTick({
            "Series1": pt_ratio_tickers,
        })

        setrtRatio({
            series: [{  
                name: 'RT Ratio',
                data: rt_ratio_dataset,
            }],            
        })
        setrtTick({
            "Series1": rt_ratio_tickers,
        })

        setefficiency({
            series: [{  
                name: 'efficiency Score',
                data: eff_score_dataset,
            }], 
        })
        seteffTick({
            "Series1": eff_score_tickers,
        })

        setLoading(false)
        return (() => {})
    }, [])


    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md: 18}, 
                        fontWeight: '300', 
                        mt: '1%',
                        lineHeight: 1.5}}>
                        As the analysis of profitability margins concludes, this section shifts its focus to efficiency ratios. These ratios
                        simplify the comparison between expenses and revenues, reflecting a company's proficiency in generating profits for 
                        each dollar spent on operations (day to day activities). Operating efficiently often leads to increased efficiency, 
                        providing desirable returns to owners and shareholders over time. This section investigates three commonly used 
                        efficiency metrics: asset turnover (AT), accounts receivable turnover (ART), and accounts payable turnover (APT) ratios.
                </Typography>

                <Sectionheader head={'3.4.1 Metric Definition'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Asset Turnover Ratio (AT): </Box>The asset turnover ratio, also known as 
                    the total asset turnover ratio, measures the efficiency with which a company uses its assets to produce sales. A company with 
                    a high asset turnover ratio operates more efficiently as compared to competitors with a lower ratio. 
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Asset Turnover Ratio = Revenue &frasl; Total Assets
                    </Typography>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Accounts Payables Turnover Ratio (PT): </Box>Accounts payables turnover ratio is a 
                    financial metric that measures how efficiently a company pays its suppliers and vendors for the goods and services it purchases on credit. 
                    The payables turnover ratio indicates how many times a company pays off its accounts payable balance during a particular period. 
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Accounts Payables Turnover Ratio = Cost of Good Sold &frasl; Average Accounts Payable
                    </Typography>
                </Box>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Accounts Receivables Turnover Ratio (RT): </Box>Accounts Rreceivables turnover ratio 
                    measures how efficiently a company collects payments from its customers for goods and services provided on credit. The receivables turnover 
                    ratio indicates how many times a company collects its outstanding accounts receivable balance during a particular period. 
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Receivables Turnover Ratio = Total Revenue &frasl; Average Accounts Receivable
                    </Typography>
                </Box>

                <Sectionheader head={'3.4.2 Sector Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The relationship between the asset turnover, accounts payable and accounts receivable turnover ratios and winsorized 1-year return of the 
                    companies in the {sector} sector is shown in <Box fontWeight='fontWeightMedium' display='inline'>Figures 25-27</Box>. Readers must note that a
                    very high asset turnover ratio may be indicative of a company that is efficiently using its assets, but it could also signify that the 
                    company has low-profit margins. Conversely, a very low asset turnover ratio may indicate that a company is not utilizing its assets 
                    efficiently or that it operates in a capital-intensive industry.
                </Typography>

                <MetricSector data={atRatio} name={''} mjsn={atTick}/>

                <Figurecaption caption={'Figure 25: AT ratio vs. 1-year return across the sector'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>NOTE:</Box> A higher payables turnover ratio indicates that a company is paying off 
                    its debts to suppliers and vendors more frequently, which is generally considered favorable as it can improve the company's relationship
                    with its suppliers and potentially negotiate better payment terms in the future. However, an extremely high
                    payables turnover ratio may indicate that a company is paying off its suppliers too quickly, which could lead
                    to cash flow issues. A low payables turnover ratio may suggest that a company is struggling to meet its
                    payment obligations, which could damage its creditworthiness and supplier relationships.
                </Typography>

                <MetricSector data={ptRatio} name={''} mjsn={ptTick}/>

                <Figurecaption caption={'Figure 26: PT ratio vs. 1-year return across the sector'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>NOTE:</Box> A higher receivables turnover ratio indicates that a company is collecting 
                    payments from its customers more quickly, which is generally considered favorable as it can improve the company's cash flow and liquidity.
                    However, an extremely high receivables turnover ratio may indicate that a company is offering very short credit
                    terms or that it is experiencing difficulties in attracting new customers. On the contrary, a low receivables
                    turnover ratio may suggest that a company is struggling to collect payments from its customers, which could
                    indicate potential credit risks and liquidity issues.
                </Typography>

                <MetricSector data={rtRatio} name={''} mjsn={rtTick}/>

                <Figurecaption caption={'Figure 27: RT ratio vs. 1-year return across the sector'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Efficiency metrics for companies in the {sector} sector are calculated and winsorized at the top and bottom 2 percentile. The resulting 
                    statistics, including average ({'\u03BC'}) and standard deviation ({'\u03C3'}), are displayed in <Box fontWeight='fontWeightMedium' 
                    display='inline'>Table 6</Box> for reference. Generally, a high asset turnover ratio and accounts receivable are considered favorable,
                    indicating prompt collection of receivables and minimal excess inventory. However, a low liability turnover ratio, typically concerning 
                    accounts payable, is viewed as positive since it suggests that a company is taking the longest possible time to settle its obligations.
                </Typography>

                <Figurecaption caption={'Table 6: Statistics of efficiency ratios distribution'} />

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats table={Statistics}/>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The information provides insights into a company's management and operational efficiency, as well as {sector} sector's typical values. 
                    For instance, the sector's average asset turnover ratio indicates that a company generates <Box fontWeight='fontWeightMedium' 
                    display='inline'>${Statistics['Asset Turnover']['mean']}</Box> in revenue for every dollar of assets it possesses. It can be 
                    demonstrated that for every $1,000 on cost of goods, which represents the direct costs associated with the production of goods or services 
                    that a company sells, an average company keeps <Box fontWeight='fontWeightMedium' display='inline'>${
                    1000 * Statistics['Payables Turnover']['mean']}</Box> on their accounts payable.
                </Typography>

                <Sectionheader head={'3.4.3 Scoring Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    In line with the previous sections, the efficiency ratios alone do not provide a definitive or conclusive evaluation of a company's 
                    financial health unless they are compared with their peers and their respective average values within the sector. The ideal benchmark 
                    ratio significantly varies depending on the industry, and some sectors, such as retailers, tend to have higher ideal ratios than others.
                    Based on the weighted average of individual scores, a composite efficiency score for each company is computed. <Box 
                    fontWeight='fontWeightMedium' display='inline'>Figure 28</Box> displays the distribution of the composite efficiency score versus 
                    winsorized 1-year return across the sector.
                </Typography>

                <ScoreSector series={efficiency} labels={effTick}/>

                <Figurecaption caption={'Figure 28: Composite efficieny score vs. 1-year return'}/>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The companies with highest composite efficiency score are displayed in <Box fontWeight='fontWeightMedium' display='inline'>Figure 29
                    </Box>. By examining this figure, it is evident that ticker <Box fontWeight='fontWeightMedium' display='inline'>{highScore.names[0]}
                    </Box> currently holds the highest efficiency score of <Box fontWeight='fontWeightMedium' display='inline'>{highScore.data[0]}</Box> in 
                    the sector.
                </Typography>


                <ScoreTop10 data={highScore.data} names={highScore.names} label={''}/>
                <Figurecaption caption={'Figure 29: Companies with highest composite efficiency score'} />

                {/* <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Efficiency ratios, composite efficiency score and its score breakdown for the top 20 companies are shown in <Box 
                    fontWeight='fontWeightMedium' display='inline'>Tables 18 and 19</Box>.
                </Typography>

                <Box>
                    <ScoreTable table={scoreBreakdown} score={['GPM', 'OPM', 'NPM']}/>
                </Box> */}

            </Box>
            }
        </div>
    )

}