import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'
import { 
    Button, 
    Box, 
    Toolbar, 
    AppBar,
    IconButton, 
    Menu, 
    MenuItem, 
    Container, 
    } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { UserAuth } from "../context/userContext";
import AccountCircle from '@mui/icons-material/AccountCircle';
import { grey } from "@mui/material/colors";

const Header = () => {

    const theme = useTheme()

    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElCompact, setAnchorElCompact] = useState(null)

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuCompact = (event) => {
        setAnchorElCompact(event.currentTarget);
    };
    
    const handleCloseCompact = () => {
        setAnchorElCompact(null);
    };

    const { singer, isUserSignedIn } = UserAuth()

    const handleLogout = () => {
        singer()
    };

    useEffect( () => {
        // console.log('User signed in: ', isUserSignedIn())

    }, [isUserSignedIn])

    return(
        <Container 
        maxWidth='lg'
        sx={{
            borderBottom: 2, 
            borderColor: grey[900],
            background: theme.palette.background.light
            }}>
            <Box sx={{display: 'flex'}}>
                <AppBar position='static' color='transparent' elevation={0}>

                    <Toolbar 
                        component={Box} 
                        sx={{  
                            display: {xs: 'flex', md: 'none'},
                            alignItems: 'center', 
                            justifyContent: 'center',
                            }}>

                            <Box sx={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'left',
                                alignItems: 'center',
                                width: {
                                    xs: '20%', 
                                    sm: '25%',
                                    }
                                }}>

                                <IconButton 
                                    size='large' 
                                    onClick={handleMenuCompact}>
                                    <MenuIcon />
                                </IconButton>

                                <Menu 
                                    anchorEl={anchorElCompact} 
                                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElCompact)}
                                    onClose={handleCloseCompact}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}>

                                    <MenuItem sx={{ 
                                        width: '100%',
                                        background: 'white',
                                        '&.MuiMenuItem-root:hover': {
                                            background: 'white'
                                            },
                                        }}>
                                        <Box sx={{width: '100%', background: 'white'}}>
                                            <Button 
                                                fullWidth
                                                disableRipple 
                                                component={Link} 
                                                to="/" 
                                                style={{justifyContent: "flex-start"}}
                                                sx={{ 
                                                    color: 'black', 
                                                    background: 'white',
                                                    '&.MuiButton-root:hover': {
                                                        background: 'white',
                                                        fontStyle: 'oblique'
                                                    },
                                                    }}>
                                                HOME
                                            </Button>
                                        </Box>
                                    </MenuItem>

                                    <MenuItem sx={{ 
                                        width: '100%',
                                        background: 'white',
                                        '&.MuiMenuItem-root:hover': {
                                            background: 'white'
                                            },
                                        }}>
                                        <Button 
                                            fullWidth
                                            disableRipple 
                                            component={Link} 
                                            to="/shop"
                                            style={{justifyContent: "flex-start"}}
                                            sx={{ 
                                                color: 'black', 
                                                background: 'white',
                                                '&.MuiButton-root:hover': {
                                                    background: 'white',
                                                    fontStyle: 'oblique'
                                                },
                                                }}>
                                            SHOP
                                        </Button>
                                    </MenuItem>

                                    <MenuItem sx={{ 
                                        width: '100%',
                                        background: 'white',
                                        '&.MuiMenuItem-root:hover': {
                                            background: 'white'
                                            },
                                        }}>
                                        <Box sx={{width: '100%', background: 'white'}}>
                                            <Button 
                                                fullWidth
                                                disableRipple 
                                                component={Link} 
                                                to="/solutions" 
                                                style={{justifyContent: "flex-start"}}
                                                sx={{ 
                                                    color: 'black', 
                                                    background: 'white',
                                                    '&.MuiButton-root:hover': {
                                                        background: 'white',
                                                        fontStyle: 'oblique'
                                                    },
                                                    }}>
                                                SOLUTIONS
                                            </Button>
                                        </Box>
                                    </MenuItem>

                                    <MenuItem sx={{ 
                                        width: '100%',
                                        background: 'white',
                                        '&.MuiMenuItem-root:hover': {
                                            background: 'white'
                                            },
                                        }}>
                                        <Box sx={{width: '100%', background: 'white'}}>
                                            <Button 
                                                fullWidth
                                                disableRipple 
                                                component={Link} 
                                                to="/sample" 
                                                style={{justifyContent: "flex-start"}}
                                                sx={{ 
                                                    color: 'black', 
                                                    background: 'white',
                                                    '&.MuiButton-root:hover': {
                                                        background: 'white',
                                                        fontStyle: 'oblique'
                                                    },
                                                    }}>
                                                SAMPLE
                                            </Button>
                                        </Box>
                                    </MenuItem>

                                    { isUserSignedIn() ? 

                                    <div>
                                        <MenuItem 
                                            onClick={handleClose} 
                                            sx={{ 
                                                width: '100%',
                                                background: 'white',
                                                '&.MuiMenuItem-root:hover': {
                                                    background: 'white'
                                                    },
                                                }}>
                                            <Box sx={{width: '100%', background: 'white'}}>
                                                <Button 
                                                    disableRipple 
                                                    component={Link} 
                                                    // onClick={handleLogout}
                                                    to="/user" 
                                                    style={{justifyContent: "flex-start"}}
                                                    sx={{ 
                                                        color: 'black', 
                                                        background: 'white',
                                                        '&.MuiButton-root:hover': {
                                                            background: 'white',
                                                            fontStyle: 'oblique'}}}>
                                                    MY ACCOUNT
                                                </Button> 
                                            </Box>
                                        </MenuItem>
                                        
                                        <MenuItem onClick={handleClose} sx={{
                                            width: '100%', 
                                            background: 'white',
                                            '&.MuiMenuItem-root:hover': {
                                                background: 'white'
                                                },
                                            }}>
                                            <Button 
                                                disableRipple 
                                                component={Link} 
                                                onClick={handleLogout}
                                                to="/" 
                                                style={{justifyContent: "flex-start"}}
                                                sx={{ 
                                                    background: 'white',
                                                    color: 'black', 
                                                    '&.MuiButton-root:hover': {
                                                        fontStyle: 'oblique', 
                                                        background: 'white'}}}>
                                                SIGN OUT
                                            </Button> 
                                        </MenuItem>                                     
                                    </div>
                                        : 
                                        <MenuItem sx={{ 
                                            width: '100%',
                                            background: 'white',
                                            '&.MuiMenuItem-root:hover': {
                                                background: 'white'
                                                },
                                            }}>
                                            <Box sx={{width: '100%', background: 'white'}}>
                                                <Button 
                                                    fullWidth
                                                    disableRipple 
                                                    component={Link} 
                                                    to="/signin" 
                                                    style={{justifyContent: "flex-start"}}
                                                    sx={{ 
                                                        color: 'black', 
                                                        background: 'white',
                                                        '&.MuiButton-root:hover': {
                                                            background: 'white',
                                                            fontStyle: 'oblique'
                                                        },
                                                    }}>
                                                    SIGN IN
                                                </Button>
                                            </Box>
                                        </MenuItem>
                                    }
                                </Menu>
                            </Box>

                            <Box sx={{
                                width: {xs:'60%'}, 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                display: {xs: 'flex', sm:'none'},
                                }}>
                                <img 
                                src={require("../assets/preview.png")} 
                                alt="Farsight Logo" 
                                width={'80%'} 
                                style={{
                                    maxHeight: '100px',
                                    maxWidth: '100px'
                                }}
                                />
                            </Box>

                            <Box sx={{
                                width: {sm: '50%'}, 
                                alignItems: 'center', 
                                justifyContent: 'center', 
                                display: {xs: 'none', sm: 'flex'},
                                }}>
                                <img 
                                src={require("../assets/preview.png")} 
                                alt="Farsight Logo" 
                                width={'50%'}
                                style={{
                                    maxHeight: '100px',
                                    maxWidth: '120px'
                                }}
                                />
                            </Box>

                            <Box sx={{ 
                                height: '100%',
                                width: {
                                    xs: '20%', 
                                    sm: '25%'
                                    }}}>

                            </Box>

                    </Toolbar>

                    <Toolbar 
                        component={Box} 
                        sx={{  
                            display: {xs: 'none', md: 'flex'},
                            alignItems: 'center', 
                            justifyContent: 'center'}}>

                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%'
                                }}>

                                <Box sx={{
                                    width: '15%', 
                                    display: 'flex',  
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    }}>
                                    <Button 
                                        disableRipple 
                                        component={Link} 
                                        to="/" 
                                        sx={{ color: 'black', width: '33%', '&.MuiButton-root:hover': {bgcolor: 'transparent', fontStyle: 'oblique'}}}>
                                        HOME
                                    </Button>
                                </Box>

                                <Box sx={{
                                    width: '15%',
                                    display: 'flex',
                                    justifyContent: 'center', 
                                    alignItems: 'center', }}>
                                    <Button 
                                        disableRipple 
                                        component={Link} 
                                        to="/shop" 
                                        sx={{ color: 'black', width: '34%', '&.MuiButton-root:hover': {bgcolor: 'transparent', fontStyle: 'oblique'}}}>
                                        SHOP
                                    </Button>
                                </Box>

                                <Box sx={{
                                    width: '30%', 
                                    height: '100%',
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    justifyContent: 'center'}}>
                                    <img 
                                        src={require("../assets/preview.png")} 
                                        alt="Farsight Logo" 
                                        width={'66%'}
                                        style={{maxHeight: '400px'}}
                                        />
                                </Box>

                                <Box sx={{
                                    width: '15%', 
                                    display: 'flex',  
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    }}>
                                    <Button 
                                        disableRipple 
                                        component={Link} 
                                        to="/solutions" 
                                        sx={{ color: 'black', width: '34%', '&.MuiButton-root:hover': {bgcolor: 'transparent', fontStyle: 'oblique'}}}>
                                        SOLUTIONS
                                    </Button>
                                </Box>

                                <Box sx={{
                                    width: '15%', 
                                    display: 'flex',  
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    }}>
                                    <Button 
                                        disableRipple 
                                        component={Link} 
                                        to="/sample" 
                                        sx={{ color: 'black', width: '34%', '&.MuiButton-root:hover': {bgcolor: 'transparent', fontStyle: 'oblique'}}}>
                                        SAMPLE
                                    </Button>
                                </Box>


                                    { isUserSignedIn() ? 

                                        <Box sx={{
                                            width: '10%', 
                                            display: 'flex',  
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            }}>

                                            <IconButton onClick={handleMenu} size='large'>
                                                <AccountCircle />
                                            </IconButton>

                                            <Menu
                                                // sx={{background: 'white'}}
                                                id="menu-appbar"
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                    }}
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}>
                                                    <MenuItem onClick={handleClose}>
                                                        <Button 
                                                            disableRipple 
                                                            component={Link} 
                                                            // onClick={handleLogout}
                                                            to="/user" 
                                                            style={{justifyContent: "flex-start"}}
                                                            sx={{ color: 'black', '&.MuiButton-root:hover': {bgcolor: 'transparent', fontStyle: 'oblique'}}}>
                                                            MY ACCOUNT
                                                        </Button> 
                                                    </MenuItem>
                                                    <MenuItem onClick={handleClose}>
                                                        <Button 
                                                            disableRipple 
                                                            component={Link} 
                                                            onClick={handleLogout}
                                                            to="/" 
                                                            style={{justifyContent: "flex-start"}}
                                                            sx={{ color: 'black', '&.MuiButton-root:hover': {bgcolor: 'transparent', fontStyle: 'oblique'}}}>
                                                            SIGN OUT
                                                        </Button> 
                                                    </MenuItem>
                                            </Menu>

                                        </Box>
                                        : 
                                        <Box sx={{width: '17%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        
                                            <Button 
                                                disableRipple 
                                                component={Link} 
                                                to="/signin" 
                                                style={{justifyContent: "flex-start"}}
                                                sx={{ color: 'black', '&.MuiButton-root:hover': {bgcolor: 'transparent', fontStyle: 'oblique'}}}>
                                                SIGN IN
                                            </Button>

                                        </Box>

                                    }

                            </Box>
                    </Toolbar>

                </AppBar>
            </Box>
        </Container>

    )
}

export default Header