import React, {useRef, useState, useEffect} from "react";
import { Box, Button, 
    Grid, TextField, 
} from "@mui/material";
import { grey } from "@mui/material/colors";
// import { InlineWidget } from "react-calendly";
// import { PopupWidget } from "react-calendly";
// import { PopupButton } from "react-calendly";
import { useTheme } from '@mui/material/styles';
import { functions } from "../firebase/index";
import {httpsCallable} from "firebase/functions";

export default function Emailform() {

    const nameRef = useRef()
    const emailRef = useRef()
    const msgRef = useRef()
    const theme = useTheme()
    const addMail = httpsCallable(functions, 'addMessage')

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')

    useEffect(()=> {

        return () => {}

    }, [])


    return(
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column',
            width: {xs: '100%', md: '80%'},
            alignItems: 'center',
            justifyContent: 'space-evenly',
            }}>

            <Grid container spacing={3} sx={{width: '90%'}}>

                <Grid item xs={12}>
                    <TextField 
                        onChange={(e) => setName(e.target.value)}
                        size='medium'
                        required
                        fullWidth
                        type={'text'}
                        variant="outlined" 
                        label="Name" 
                        sx={{
                            color: theme.palette.primary,
                            borderColor: theme.palette.border.main,
                            "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                }
                            },
                        }}
                        inputRef={nameRef} 
                        InputProps={{sx: {bgcolor: 'white'}}}>
                            Name
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField 
                        onChange={(e) => setEmail(e.target.value)}
                        size='medium'
                        required
                        fullWidth
                        type={'text'}
                        variant="outlined" 
                        label="Email" 
                        sx={{
                            borderColor: theme.palette.border.main,
                        //     "& .MuiOutlinedInput-root.Mui-focused": {
                        //         "& > fieldset": {
                        //             borderColor: grey[500],
                        //         }
                        //     }, 
                        }}
                        inputRef={emailRef} 
                        InputProps={{sx: {bgcolor: 'white'}}}>
                            Email
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField 
                        onChange={(e) => setMsg(e.target.value)}
                        size='medium'
                        required
                        multiline
                        fullWidth
                        type={'text'}
                        variant="outlined" 
                        label="Message" 
                        inputRef={msgRef} 
                        sx={{
                            borderColor: theme.palette.border.main,
                        }}
                        InputProps={{
                            sx: {
                                bgcolor: 'white', 
                                height: {xs: 120, sm: 96}
                                }
                            }}>
                            Message
                    </TextField>
                </Grid>

                <Grid item xs={12} 
                    sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center'}}>

                    <Button 
                        variant='contained' 
                        onClick={() => {
                            addMail({
                                name: nameRef.current.value, 
                                email: emailRef.current.value, 
                                msg: msgRef.current.value
                            })
                            .catch(error => {
                                console.log(error);
                                console.log(error.code);
                                console.log(error.message);
                            })
                        }}
                        disableElevation
                        disabled={
                            !((name.length > 0 && email.length > 0) && msg.length > 0)
                        }
                        sx={{
                            fontFamily: 'inherit',
                            fontSize: 16,
                            fontWeight: 400,
                            color: grey[800], 
                            background: '#13ecff',
                            '&:hover': {background: '#d827ff', color: 'white'},
                            }}>
                            Submit Inquary
                    </Button>

                </Grid>

            </Grid>

        </Box>
    )
}