import React, { useState } from "react";
// import Layout from "../components/layout";
// import FeaturedProduct from "../components/featured-product";
// import { ProductContext } from "../context/product-context";
// import { UserContext } from "../context/user-context";
import { Typography, Box, Divider, Container, Grid, Button } from "@mui/material";
// import { setDoc, doc, getDocs, onSnapshot, collection } from "firebase/firestore"
// import { auth, db } from '../firebase/index'
// import {useParams} from 'react-router-dom';
// import { alpha, useTheme } from '@mui/material/styles';
// import Liquidity from "../components/liquidity";
// import Solvency from "../components/solvency";

import Accordion from '@mui/material/Accordion';
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { orange } from "@mui/material/colors";

const Section = ({children, title}) => {

    return(
        <Accordion 
            TransitionProps={{ unmountOnExit: true }}
            elevation={0} 
            sx={{
                mb: '1%',
                borderRadius: 2,
                '&::before': {
                    display: 'none',
                }, 
                border: 0
                }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{backgroundColor: orange[100],}}
                aria-controls="panel1-content"
                id="panel1-header">
                <Typography sx={{fontWeight: 500, fontSize: 24}}>
                    {title}
                </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{backgroundColor: orange[50],}}>
                {children}
            </AccordionDetails>

        </Accordion>
    )

}

export default Section