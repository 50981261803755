import { createTheme } from "@mui/material/styles";
import { grey, orange, purple, yellow } from "@mui/material/colors";
import { alpha } from '@mui/material/styles';

const theme = (mode) =>
  createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#7689ff',
      },
      info: {
        main: yellow['A400'],
      },
      border: {
        main: grey[300]
      },
      sector1: {
        main: '#00ffff',
      },
      background: {
        // default: alpha(grey[50], 0.66),
        // default: alpha('#fffaf0', 0.25), // floralwhite
        default: alpha('#faf0e6', 0.4), // floralwhite
        light: orange[50],
        dark: alpha(purple[100], 0.50),
        card: 'white',
        // card: alpha('#fffaf0', 0.25),
        // paper: alpha('#fffaf0', 0.25),
      },
      tonalOffset: 0.2
    },
    typography: {
        fontFamily: [
            // "Source Serif 4"
            '-apple-system', 
            'BlinkMacSystemFont',
            'Source Serif 4', 'serif'
        ].join(','),
    },
      // primary: {
        // main: orange[500]
      // },

    //   secondary: {
    //     light: red[500],
    //     main: red[700],
    //     dark: red[900],
    //     contrastText: grey[50]
    //   },
    //   error: {
    //     light: red[400],
    //     main: red[500],
    //     dark: red[300],
    //     contrastText: grey[800]
    //   },
    //   success: {
    //     main: green[500]
    //   },
    //   warning: {
    //     main: yellow[500],
    //     contrastText: grey[800]
    //   },
    //   info: {
    //     main: lightBlue[500]
    //   },
    //   text: {
    //     primary: grey[900],
    //     secondary: grey[700],
    //     disabled: grey[500]
    //   },
    //   action: {
    //     active: red[200],
    //     activeOpacity: 1,
    //     disabled: grey[700],
    //     disabledBackground: grey[200],
    //     hover: red[100],
    //     hoverOpacity: 0.7,
    //     focus: red[600],
    //     focusOpacity: 1,
    //     selected: red[300],
    //     selectedOpacity: 1
    //   },
    //   background: {
    //     default: orange[300],
    //     paper: grey[200]
    //   },
    //   common: {
    //     black: grey[900],
    //     white: grey[200]
    //   },

  });

export default theme;