import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";
import { Sectionheader, Figurecaption } from './reportBlocks';
import { MetricsStats, ScoreTable } from './tableblocks';
import { MetricSector, DivTop10, MetricSector2 } from './figureBlocks';

export default function Dividened({sector, input}) {

    const [loading, setLoading] = useState(true)

    const [dividend, setDividend] = useState({
        series: [{
            data : []
            },
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [dvdTick, setDvdTick] = useState({
        "Series1":[]
        }
    )

    const [divYield, setDivYield] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [yldTick, setYldTick] = useState({
        "Series1": []
        }
    )

    const DVD = input.data
    const overall = input.overall
    const companiesNo = overall.companies
    const divPaying = overall.div_payers
    const divPayingRatio = Math.round(100 * overall.paying_ratio)
    const skewness = overall.skew
    const top10_div = input.top_dividend
    const top10_yld = input.top_yield
    const Statistics = input.stats
    // console.log(overall)

    let magnitude

    if (divPayingRatio >= 60) {
        magnitude = 'significant'
    } else if (divPayingRatio >= 45) { 
        magnitude = 'substantial'
    } else if (divPayingRatio >= 30) {
        magnitude = 'modest'
    } else if (divPayingRatio >= 15) {
        magnitude = 'small'
    } else {
        magnitude = 'negligible'
    }

    let direction
    let dirText

    if (skewness >= 0) {
        direction = 'right'
        dirText = 'This means that the outliers of the distribution curve are further out towards the right, while dividends lower than average are closer to the mean on the left.'
    } else {
        direction = 'left'
        dirText = 'This means that the outliers of the distribution curve are further out towards the left, while dividends higher than average are closer to the mean on the right.'
    }

    useEffect( () => {

        let dvd_value_dataset = []
        let dvd_value_tickers = []
        let dvd_yield_dataset = []
        let dvd_yield_tickers = []

        for (const [key, value] of Object.entries(DVD)) {
            
            dvd_value_dataset.push([
                Math.round(100 * value[2]) /100, 
                // Math.round(100 * Math.log10(value[0])) /100, 
                Math.round(100 * value[0] ) / 100, 
                value[3]
            ])
            dvd_value_tickers.push({'ticker': key})

            dvd_yield_dataset.push([Math.round(100 * value[2]) /100, value[1], value[3]])
            dvd_yield_tickers.push({'ticker': key})

        }

        // console.log(dvd_value_dataset)

        setDividend({
            series: [{  
                    name: 'Dividened per share (ttm) $',
                    data: dvd_value_dataset,
                    }],
                }
            )
        setDvdTick({
            "Series1": dvd_value_tickers,
        })

        setDivYield({
            series: [{  
                    name: 'Dividened yield (%)',
                    data: dvd_yield_dataset,
                    }],
                }
            )
        setYldTick({
            "Series1": dvd_yield_tickers,
        })

        setLoading(false)

        return( () => {})
    }, [DVD])

    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 

            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The term dividend refers to a form of reward that companies pay to their shareholders as a share of their profits. 
                    This practice is common not only among individual companies but also among mutual funds and exchange-traded funds 
                    (ETFs). While cash dividends are frequently distributed, it is also possible to issue dividends in the form of stocks. 
                    This week's report delves into dividend payouts within the {sector} sector.
                </Typography>

                <Sectionheader head={'5.1. Overview of dividend payout'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Out of the <Box fontWeight='fontWeightMedium' display='inline'>{companiesNo}</Box> companies in the sector, <Box 
                    fontWeight='fontWeightMedium' display='inline'>{divPaying}</Box> announced dividends in the past trailing twelve months 
                    (TTM), accounting for a {magnitude} <Box fontWeight='fontWeightMedium' display='inline'>{divPayingRatio}%</Box> of the 
                    sector. <Box fontWeight='fontWeightMedium' display='inline'>Figure 49</Box> presents the distribution of the total dollar
                    amount of dividends per share (TTM) in relation to the winsorized 1-year return of the companies.
                </Typography>

                <MetricSector2 data={dividend} name={''} ticks={dvdTick}/>

                <Figurecaption caption={'Figure 49: Total dollar amount of dividend per share versus winsorized 1-year return values'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Generally, companies in mature industries with stable cash flows, such as utilities and consumer goods,
                    tend to pay dividends regularly to reward shareholders and maintain investor confidence. On the other
                    hand, companies in growth-oriented sectors like technology and biotechnology often reinvest their profits 
                    into research, development, and expansion initiatives rather than distributing them as dividends.
                    Additionally, some companies may choose not to pay dividends if they believe they can generate higher returns
                    by retaining earnings and reinvesting them into the business. Ultimately, the dividend policy of a company
                    reflects its management's priorities and its assessment of future growth opportunities.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Top 10 companies with highest dividend are shown in <Box fontWeight='fontWeightMedium' display='inline'>Figure 50</Box>.
                    Currently, tickers <Box fontWeight='fontWeightMedium' display='inline'>{top10_div.tickers[0]}</Box> and <Box display='inline' 
                    fontWeight='fontWeightMedium' >{top10_div.tickers[1]}</Box> have the highest dividend payouts in the {sector} sector, offering
                    <Box fontWeight='fontWeightMedium' display='inline'> ${top10_div.values[0]}</Box> and <Box fontWeight='fontWeightMedium' 
                    display='inline'>${top10_div.values[1]}</Box> per share, respectively. 
                </Typography>

                <DivTop10 data={top10_div.values} names={top10_div.tickers} label={'Dividened ($)'}/>

                <Figurecaption caption={'Figure 50: Companies with highest dividened in the sector'} />

                <Sectionheader head={'5.2. Metric Definition'} />
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Dividend yield: </Box> In addition to actual payouts, dividend yield 
                    can be used to calculate investment income. It's calculated by dividing a company's current annual dividend by its current 
                    share price and then multiplying the result by 100. This metric offers a standardized way to compare dividends across 
                    different stocks. If a company's dividend remains constant but its share price falls, the dividend yield increases. Conversely, 
                    if only the stock price rises, the yield decreases.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Dividend Yield Ratio = Annual dividend &frasl; Current share price
                    </Typography>
                </Box>   

                <Sectionheader head={'5.3. Dividend yeild lanscape across the sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Readers who prioritize stable fixed income often find companies with high dividend yields appealing. When combined with a 
                    stable or increasing stock price, dividend payments can offer additional income. However, it's essential to note that high 
                    dividend payouts might limit potential growth, as companies may allocate these funds to reinvest in their business. <Box 
                    fontWeight='fontWeightMedium' display='inline'>Table 11</Box> outlines the statistics of dividend payout and yield distribution 
                    across the sector, offering insights into companies' dividend policies.
                </Typography>

                <Figurecaption caption={'Table 11: Statistics parameters of dividened distribution'} />

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats table={Statistics}/>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Current study indicates that the average ({'\u03BC'}) dividend yield in the sector is <Box fontWeight='fontWeightMedium' display='inline'>
                    {Statistics['Dividend Yield (%)']['mean']}%</Box>, but the distribution is skewed towards the {direction}, {dirText} <Box display='inline'
                    fontWeight='fontWeightMedium'>Figure 51</Box> illustrates the distribution of dividend yield across the entire {sector} sector.
                </Typography>
                
                <MetricSector2 data={divYield} ticks={yldTick}/>

                <Figurecaption caption={'Figure 51: Dividened yield versus winsorized 1-year return values'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Tickers <Box fontWeight='fontWeightMedium' display='inline'>{top10_yld['tickers'][0]}</Box>, <Box fontWeight='fontWeightMedium' 
                    display='inline'>{top10_yld['tickers'][1]}</Box> and <Box fontWeight='fontWeightMedium' display='inline'>{top10_yld['tickers'][2]}
                    </Box> currently offer the highest dividend yield at <Box fontWeight='fontWeightMedium' display='inline'>{top10_yld['values'][0]}%
                    </Box>, <Box fontWeight='fontWeightMedium' display='inline'>{top10_yld['values'][1]}%</Box> and <Box fontWeight='fontWeightMedium' 
                    display='inline'>{top10_yld['values'][2]}%</Box>, respectively. Furthermore, <Box fontWeight='fontWeightMedium' display='inline'>
                    Figure 52</Box> provides details on the top 10 companies with the highest dividend yield in the sector. As readers notice, highest 
                    dividend yeild is <Box fontWeight='fontWeightMedium' display='inline'>
                    {Math.round((top10_yld['values'][0]/top10_yld['values'][1]) * 100) / 100}</Box> times higher than the next company in the {sector} 
                    sector.
                </Typography>

                <DivTop10 data={top10_yld.values} names={top10_yld.tickers} label={'Dividened yield (%)'}/>

                <Figurecaption caption={'Figure 52: Companies with highest dividened in the sector'} />

            </Box>}

        </div>

    )

}