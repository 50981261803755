import React, { useState } from "react";
// import Layout from "../components/layout";
// import FeaturedProduct from "../components/featured-product";
// import { ProductContext } from "../context/product-context";
// import { UserContext } from "../context/user-context";
import { Typography, Box, Divider, Container, Grid, Button } from "@mui/material";
// import { setDoc, doc, getDocs, onSnapshot, collection } from "firebase/firestore"
// import { auth, db } from '../firebase/index'
// import {useParams} from 'react-router-dom';
// import { alpha, useTheme } from '@mui/material/styles';
// import Liquidity from "../components/liquidity";
// import Solvency from "../components/solvency";

import Accordion from '@mui/material/Accordion';
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { orange } from "@mui/material/colors";

export default function Disclaimer() {

    return(

        <Accordion 
            TransitionProps={{ unmountOnExit: true }}
            defaultExpanded
            elevation={0} 
            sx={{
                mb: '1%',
                borderRadius: 2,
                '&::before': {
                    display: 'none',
            }, 
            border: 0}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{backgroundColor: orange[100],}}
                aria-controls="panel1-content"
                id="panel1-header">
                <Typography sx={{fontWeight: 500, fontSize: 24}}>
                    DISCLAIMER AND LEGAL NOTICE
                </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{backgroundColor: orange[50],}}>
                <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        The information presented in this study is for educational purposes only and should not be interpreted as an 
                        offer to sell or a solicitation to buy any securities or financial instruments. It should not be considered a 
                        recommendation to buy, hold, or sell any investment or financial product, nor should it prompt any specific 
                        investment actions. This information is not personalized, does not constitute a research report, and should not 
                        be the sole basis for any investment decisions.
                    </Typography>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        FARSIGHT is not authorized to provide financial advice, recommendations, or consultations. The purpose of this 
                        report is not to offer such services. FARSIGHT disclaims any liability for financial losses that may result from 
                        the use of the information contained within this study. All investments involve risks, including the potential loss 
                        of capital. The content of this study does not forecast future performance or returns and should not be interpreted 
                        as such. Past performance does not guarantee future results or returns.
                    </Typography>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            The scoring system introduced in this study is for informational purposes only and is in a beta (testing) 
                            phase. It is subject to change without notice. The scores provided are not guaranteed to be accurate and 
                            should not be the sole basis for making financial decisions. Before making decisions with legal, tax, or 
                            accounting ramifications, consulting with qualified professionals is recommended. Although the information 
                            in this report is believed to be reliable as of its publication date, FARSIGHT makes no representation as 
                            to its accuracy.
                    </Typography>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        This disclaimer aims to clarify the nature and limitations of the information provided. Investors are advised 
                        to proceed with caution and seek professional financial advice before making any investment decisions. 
                        Additionally, investors should be aware that trading in financial instruments involves significant risk and is 
                        not suitable for all investors. Before trading, consider your level of experience, investment objectives, and 
                        seek independent financial advice if necessary.
                    </Typography>

                </Box>
            </AccordionDetails>

        </Accordion>
    )
}





