import React , { useEffect, useState } from "react";
// import Layout from "../components/layout";
import { Box, Typography, CircularProgress} from "@mui/material";
import Chart from "react-apexcharts";
import { Sectionheader, Figurecaption } from './reportBlocks';
import { createData, SimpleTable, MetricsStats, ScoreTable, SectorStats, IndustryTable } from './tableblocks';
import { MetricSector, ScoreTop10, McapHist } from './figureBlocks';
import { Capitalize } from "./utils";
import { chartColors } from "../theme/colors";

export default function Overview({input, sector}) {

    const Sector = Capitalize(sector)
    const formater = new Intl.NumberFormat('en-US')

    const [loading, setLoading] = useState(true)

    const companiesNo = input.pie.numbers
    const companiesCap = input.pie.caps
    const total_cap = Math.round(companiesCap.reduce((partialSum, a) => partialSum + a, 0))
    const industriesNo = input.pie.indNumbers
    const industriesCaps = input.pie.indCaps
    const Statistics = input.stats
    const tops = input.industry

    const dataMean = Statistics['mean']
    const dataMed = Statistics['med']

    let verdict

    if (dataMean < dataMed) {
        verdict = 'the distribution of data is skewed to the left (more companies with higher market caps)'
    } else {
        verdict = 'the distribution of data is skewed to the right (more companies with lower market caps)'
    }

    const histogram = input.histo
    const nbins = histogram.nos.length
    

    const pieColors = chartColors.slice(0, companiesNo.length)

    const totalCompanies = companiesNo.reduce((accumulator, currentValue) => {
        return accumulator + currentValue
    }, 0)

    

    const compMax = Math.max.apply(Math, companiesNo)

    const series = input.pie.numbers

    const laregst_como_no_per = Math.round( 1000 * (series[0] / totalCompanies) ) / 10
    const series2 = companiesCap
    const options = {
        chart: {
          type: 'pie',
          toolbar: {
            show: false,
            },
        },
        plotOptions: {
            pie: {
                startAngle: 0,
            },
        },
        labels: industriesNo,
        grid: {
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: '12px',
                fontWeight: 'bold',
            },
            dropShadow: {
                enabled: false,
                top: 1,
                left: 1,
                blur: 1,
                // color: '#000',
                opacity: 0.45
            }
        },
        colors: pieColors,
      }

    useEffect( () => {
        setLoading(false)
    }, [])

    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{mt: '1%', fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                    The first chapter examines the composition of the {sector} sector and its underlying industries. 
                    Current study investigates a total of <Box fontWeight='fontWeightMedium' display='inline'>
                    {totalCompanies}</Box> tickers actively listed on the New York Stock Exchange (NYSE) and National 
                    Association of Securities Dealers Automated Quotation System (NASDAQ) exchanges. These tickers are 
                    spread across <Box fontWeight='fontWeightMedium' display='inline'>{companiesNo.length}</Box> distinct 
                    industries that comprise the {sector} sector. <Box fontWeight='fontWeightMedium' display='inline'>
                    {industriesNo[0]}</Box> is the largest industry within the sector, comprising <Box display='inline'
                    fontWeight='fontWeightMedium' >{compMax}</Box> companies, equivalent of <Box fontWeight='fontWeightMedium' 
                    display='inline'>{laregst_como_no_per}%</Box> of the sector share. <Box fontWeight='fontWeightMedium' 
                    display='inline'>Figure 1</Box> presents a visual breakdown of the sector's share for each industry 
                    based on the number of companies.
                </Typography>

                <Box sx={{
                    border:1,
                    borderRadius: 2,
                    background: 'white',
                    width: {xs: '98%', sm: '85%', md: '75%'},   
                    }}>
                    <Box sx={{m: 1}}>
                        <Chart series={series} options={options} type="pie"/>
                    </Box>
                </Box>

                <Figurecaption 
                    caption={'Figure 1: Industry share (number of the companies) of the sector'}
                />
                
                <Sectionheader head={'1.1. Market Capitalization'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                    While helpful, the distribution of companies in an industry doesn't fully represent the market significance of 
                    each industry. Market significance is commonly expressed in terms of market capitalization (or market cap., for 
                    short). Therefore, it is helpful to consider the market capitalization of the companies within an industry to 
                    better understand their relative significance within the sector and broader economy. Each company's market 
                    capitalization is calculated by multiplying its current share price by the number of outstanding shares available 
                    on the market. The industry market capitalization, on the other hand, is the sum of the market capitalizations 
                    of all the companies within that industry. <Box fontWeight='fontWeightMedium' display='inline'>Figure 2</Box> shows 
                    the sector share of each industry based on  its market capitalization.
                </Typography>

                <Box sx={{
                    border:1,
                    borderRadius: 2,
                    background: 'white',
                    width: {xs: '98%', sm: '85%', md: '75%'},
                    }}>
                    <Box sx={{m: 1}}>
                        <Chart series={series2} options={options} type="pie"/>
                    </Box>
                </Box>

                <Figurecaption 
                    caption={'Figure 2: Industry share (market capitalization) of the sector'}
                />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                    Evaluating the market capitalization of a sector and its underlying industries provides valuable insights 
                    into global capital market trends and investor appetite. <Box fontWeight='fontWeightMedium' display='inline'
                    >Table 1</Box> offers a holistic view of the energy sector by breaking down the distribution of market 
                    capitalization in the sector.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                    It depicts that the average (mean) market capitalization of a company in the sector is <Box 
                    fontWeight='fontWeightMedium' display='inline'> ${formater.format(Statistics.mean)}M</Box>, while the median is<Box 
                    fontWeight='fontWeightMedium' display='inline'> ${formater.format(Statistics.med)}M</Box>. These metrics indicate 
                    that {verdict}. This is because the mean is sensitive to extreme values, whereas the median is not.
                </Typography>

                <Figurecaption 
                    caption={'Table 1: Statistics of market capitalization distribution'}
                />

                <Box sx={{border: 1, mb: '2%', width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <SectorStats sector={Sector} table={Statistics} />
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Figure 3</Box> shows this distribution for the companies in 
                    the {sector} sector. The companies were grouped into <Box fontWeight='fontWeightMedium' display='inline'>{nbins} </Box> 
                    bins based on the logarithm of their market capitalization (in terms of $M). For instance, companies in bin 2-3 have a 
                    market cap between $100M to $1B ($1,000M). Additionally, <Box fontWeight='fontWeightMedium' display='inline'>Table 2</Box> illustrates 
                    the companies with highest market capitalization in each industry within the sector.
                </Typography>

                <McapHist histo={histogram} name={'Companies'} />

                <Figurecaption caption={'Figure 3: Logarithm of market cap vs. number of companies'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                    Finally, we computed the total market capitalization of the sector to be <Box fontWeight='fontWeightMedium' display='inline'
                    >${formater.format(total_cap)}B</Box> by summing up the market cap of the companies in the sector. With this introduction, we can proceed to the 
                    next chapter, which focuses on the return of the sector.
                </Typography>

                <Figurecaption 
                    caption={'Table 2: Highest market capitalization in each industry'}
                />

                <Box sx={{border: 1, mb: '2%', width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <IndustryTable tops={tops}/>
                </Box>

            </Box>
            }
        </div>
    )

}