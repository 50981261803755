import { grey } from "@mui/material/colors";
import { createTheme, alpha, getContrastRatio } from '@mui/material/styles';

// NOTE: These botoon colors (botColors) are generated by using Matplotlib Color Map
// Cool, to resemble the color patern in the report.
const buttonColors = 
[
    '#00ffff',
    '#13ecff',
    '#27d8ff',
    '#3bc4ff',
    '#4eb1ff',
    '#629dff',
    '#7689ff',
    '#8976ff',
    '#9d62ff',
    '#b14eff',
    '#c43bff',
    '#d827ff',
    '#ec13ff'
]

const contrastTextColors = [
    grey[800],
    grey[800],
    grey[800],
    grey[200],
    grey[100],
    grey[200],
    grey[100],
    grey[50],
    grey[50],
    'white',
    'white',
    'white',
    'white']

const chartColors = [

    '#7f0000', // maroon2
    '#7cfc00', // lawn green
    '#4682b4', // Steelblue
    '#d2691e', // Chocolate

    '#dc143c', // crimson
    '#9acd32', // yellowgreen
    '#483d8b', // darkslateblue
    '#ffa500', // orange
    
    '#ff00ff', // fuchsia
    '#2e8b57', // seagreen
    '#7b68ee', // mediumslateblue
    '#f5deb3', // wheat
    
    '#b03060', // maroon3
    '#808000', // olive
    '#1e90ff', // dodgerblue
    '#e9967a', // darksalmon

    '#ff0000', // red
    '#0000ff', // blue
    '#ffff00', // yellow
    '#008000', // green
    
    '#00008b', // darkblue
    '#00ff7f', // springgreen
    '#dda0dd', // plum
    '#696969', // dimgrey

    '#00ffff', // aqua
]    

export {
    buttonColors,
    contrastTextColors,
    chartColors
}