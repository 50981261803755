import React from "react";
import Layout from '../components/layout';
import Main from "../components/main";
import { Container } from "@mui/material";
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';
import SiteAlert from "../components/sitealert";

export default function Homepage() {

    const theme = useTheme()

    return(
        <div >
            <Helmet>
                <title>Welcome to FARSIGHT!</title>
                <meta name="description" content={'homepage for affordable sector reports for purchase'} />
                <meta name="keywords" content="sector report, ratio analysis, CONVENIENT SCORING, weekly update, online purchase, subscription, peer review" />

                <meta property="og:title" content={'Welcome to FARSIGHT Reports!'} />
                <meta property="og:description" content={'homepage for affordable sector reports for purchase'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://farsight.report'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Welcome to FARSIGHT Reports!'} />
                <meta name="twitter:description" content={'homepage for affordable sector reports for purchase'} />
            </Helmet>
            <Layout>

                <SiteAlert />

                <Container maxWidth='lg' sx={{background: theme.palette.background.default}}>
                    <Main />
                </Container>

            </Layout>
        </div>
    )
}