import React, { createContext, useState, useContext, useEffect } from "react";
import { onSnapshot, collection, query, getDoc, doc, getDocs } from "firebase/firestore"
import { CircularProgress, Box } from "@mui/material";
import { db, payments } from '../firebase/index';
// import { getPrices, getProducts } from "@stripe/firestore-stripe-payments";
import { getPrices, getProducts } from "@invertase/firestore-stripe-payments";

const ProductContext = createContext()

export function ProductFirebase() {
    return useContext(ProductContext)
}

export default function ProductContextProvider({ children }) {

    const [products, setProducts] = useState(null)
    const [loading, setLoading] = useState(true)

    async function StripePrices(payments, prodId) {
        const prices = await getPrices(payments, prodId)

        return prices
    }

    async function StripeProducts(payments) {
        const products = await getProducts(payments, {
            includePrices: true,
            activeOnly: true,
        });

        return products
    }

    async function priceData(id) {
        const colRef = collection(db, 'products/' + id + '/prices')
        const docsnap = await getDocs(colRef)
        return docsnap
    }

    async function AAAA(querySnapshot){
        var n = 1
        var available_products = []
        querySnapshot.forEach((doc) => {
            var pid = doc.id
            var prod_info = doc.data()
            priceData(doc.id)
            .then(qq => { 
                    qq.forEach((doc) => {
                    var payload = {
                        id: pid,
                        index: n,
                        active: prod_info.active,
                        description: prod_info.description,
                        images: prod_info.images,
                        metadata: prod_info.metadata,
                        name: prod_info.name,
                        role: prod_info.role,
                        stripe_metadata_frequency: prod_info.stripe_metadata_frequency,
                        stripe_metadata_sector: prod_info.stripe_metadata_sector,
                        tax_code: prod_info.tax_code,
                        prices: doc.data(),
                    }
                    available_products.push(payload)
                })
                n = n + 1
            })               
        })
    }

    useEffect(() => {

        // 2.36 pm

        // const q = query(collection(db, "products"))
        // const unsubscribe = onSnapshot(q, (querySnapshot) => {
        //     var n = 1
        //     var available_products = []
        //     querySnapshot.forEach((doc) => {
        //         var pid = doc.id
        //         var prod_info = doc.data()
        //         priceData(doc.id)
        //         .then(querySnapshot => {
        //             querySnapshot.forEach((doc) => {
        //                 available_products.push({
        //                     id: pid,
        //                     index: n,
        //                     active: prod_info.active,
        //                     description: prod_info.description,
        //                     images: prod_info.images,
        //                     metadata: prod_info.metadata,
        //                     name: prod_info.name,
        //                     role: prod_info.role,
        //                     stripe_metadata_frequency: prod_info.stripe_metadata_frequency,
        //                     stripe_metadata_sector: prod_info.stripe_metadata_sector,
        //                     tax_code: prod_info.tax_code,
        //                     prices: doc.data(),
        //                 })
        //             })
        //             n = n + 1
        //         })               
        //     })
            // setProducts(available_products)
            // setProducts([])
            setLoading(false)
            // console.log("Available products are: ", available_products)
        // })

        const SPrice = [
            {
                "billing_scheme": "per_unit",
                "currency": "usd",
                "product": "prod_OuC2OBLDKmjgbX",
                "tax_behavior": "unspecified",
                "tiers": null,
                "interval": "year",
                "tiers_mode": null,
                "transform_quantity": null,
                "trial_period_days": null,
                "metadata": {},
                "description": null,
                "type": "recurring",
                "interval_count": 1,
                "active": true,
                "recurring": {
                    "interval_count": 1,
                    "aggregate_usage": null,
                    "trial_period_days": null,
                    "usage_type": "licensed",
                    "interval": "year"
                },
                "unit_amount": 49999,
                "id": "price_1O6NejHZvwT3HUdTDXWnnA2t"
            },
            {
                "type": "one_time",
                "active": true,
                "trial_period_days": null,
                "description": null,
                "tax_behavior": "unspecified",
                "recurring": null,
                "product": "prod_OuC2OBLDKmjgbX",
                "tiers": null,
                "currency": "usd",
                "tiers_mode": null,
                "billing_scheme": "per_unit",
                "transform_quantity": null,
                "metadata": {},
                "interval_count": null,
                "interval": null,
                "unit_amount": 1999,
                "id": "price_1O6NejHZvwT3HUdTMMYqnYL4"
            },
            {
                "product": "prod_OuC2OBLDKmjgbX",
                "billing_scheme": "per_unit",
                "recurring": {
                    "interval": "month",
                    "aggregate_usage": null,
                    "trial_period_days": null,
                    "usage_type": "licensed",
                    "interval_count": 1
                },
                "metadata": {},
                "tiers_mode": null,
                "transform_quantity": null,
                "interval": "month",
                "active": true,
                "currency": "usd",
                "trial_period_days": null,
                "interval_count": 1,
                "type": "recurring",
                "description": null,
                "unit_amount": 4999,
                "tiers": null,
                "tax_behavior": "unspecified",
                "id": "price_1O6NejHZvwT3HUdTTamzeS2l"
            }
        ]

        // SPrice.forEach(price => {
        //     let cats = []
        //     if (price['interval'] === null) {
        //         cats.push(price['type'])
        //     } else {
        //         cats.push(price['interval'])
        //     }
        //     console.log('Price categories are: ', cats)

        // })


        // StripePrices(payments, 'prod_OuC2OBLDKmjgbX')
        // .then( res => console.log(res))

        StripeProducts(payments)
        .then( res => {
            res.forEach( (product) => {
                
            })
            // console.log(res)
            setProducts(res)
            setLoading(false)
            // console.log("Available products are: ", res)
            }
        )

        return () => {
            // unsubscribe()
        }

    }, [])

    // useEffect(() => {
    //     StripeProducts(payments)
    //     .then(
    //         // prds => console.log(prds)
    //         prds => {
    //             setProducts(prds)
    //             setLoading(false)
    //         }
    //     ).catch((error) => {
    //         console.log(error.code)
    //         console.log(error.message)
    //         }
    //     )
    //     // const unsubscribe = onSnapshot(collection(db, ""), () => {})
    //     return(() => {})
    // })

    if (loading) { 
        return (
            <Box sx={{ 
                display:'grid',                     
                justifyContent:'center', 
                height: '100vh', 
                alignContent: 'center' }}>
                <CircularProgress size={48} />
            </Box>
    )} else { 
        return(
            <ProductContext.Provider value={{products}}>
                { 
                    children 
                }
            </ProductContext.Provider>
        )
    }
}

// export const ProductContext = createContext();

// async function Products(payments) {
//     const products = await getProducts(payments, {
//         includePrices: true,
//         activeOnly: true,
//       });

//     return products
// }

// function filp(arr) {
//     const fil = arr.filter(p => p.active === true && p.type === "one_time")
//     return [fil[0].unit_amount, fil[0].id]

// }

// export default function ProductContextProvider({ children }) {

//     let RE_DAT = []
//     var n = 1
//     Products(payments)
//     .then(products => {

        
//         products.forEach(p => {

//             RE_DAT.push({
//                 id: n,
//                 description: p.description,
//                 title: p.name,
//                 image: p.images,
//                 metadata: p.metadata,
//                 amount: filp(p.prices)[0],
//                 price: filp(p.prices)[1],
//             })
//             n = n + 1
//             // console.log()
//         })
//     })
//     .catch((error) => {
//         console.log(error.code)
//         console.log(error.message)
//     })

//     const [products] = useState(RE_DAT)
     
//     return (
//         <ProductContext.Provider value={{products}}>
//             {
//                 children
//             }
//         </ProductContext.Provider>
//     )
// }
