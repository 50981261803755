import React, { useEffect, useState }  from "react";
import Layout from "../components/layout";
import { Box, Container, Typography } from "@mui/material";
// import { useNavigate, useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from '../firebase/index';
import { grey } from "@mui/material/colors";
// import { chargeStripe } from "../../functions";
import CircularProgress from '@mui/material/CircularProgress';


function CancelPage() {

    const charge = httpsCallable(functions, 'chargeStripe')
    // const ret = httpsCallable(functions, 'retrieve')

    const[name, setName] = useState("")
    const[msg, setMsg] = useState("")
    const[code, setCode] = useState("")
    const[loading, setLoading] = useState(true)

    const [canceled, setCanceled] = useState(false)

    useEffect( () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('session_id')
        charge(id)
        .then(res => {
            // console.log(res.data)
            const pi = res.data.paymentIntent
            if (pi === null) {               
                // There was no payment intent, so transaction was canceled.
                setCanceled(true)
                setLoading(false)
            } else {

                // console.log(pi.last_payment_error)
                // setMsg(pi.last_payment_error.message)
                // setName(pi.last_payment_error.payment_method.billing_details.name)
                // if (pi.last_payment_error.code === 'card_declined') {
                //     setCode('card declined')
                //     }
                // else {
                //     if ( pi.last_payment_error.code === 'expired_card'){
                //         setCode('card is expired')
                //         }
                //     }
                // setLoading(false)

                }

            }
        )

        return () => {}
    }, [charge])

    function Waiting() {

        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '80vh'}}>
                <CircularProgress size={'4rem'}/>
            </Box>
            )
        } 

    return(
            <Layout>
                <Container maxWidth='lg'>

                    { loading && 

                        <Waiting />

                    } { !loading && canceled && 
                        <div>
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%',  height: '80vh'}}>

                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                    <Typography textAlign={'center'} sx={{fontSize:{ xs: 24, md: 48}, fontWeight: 600, color: grey[900]}}>
                                        Your transaction has been canceled!
                                    </Typography>
                                </Box>

                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mt: '10%'}}>
                                    <Typography sx={{fontSize: {xs: 18, md: 30}, fontWeight: 400, color: grey[700]}}>
                                        You can return to shop to choose another product.
                                    </Typography>
                                </Box>
                            </Box>

                        </div>
                    } { !loading && !canceled && 
                        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>

                            <Box sx={{display: 'flex', height: '25vh', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                <Typography sx={{fontSize: 48, fontWeight: 600, color: grey[900]}}>
                                    {name}, Oh no!
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', height: '30vh', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                <Typography sx={{fontSize: 30, fontWeight: 400, color: grey[700]}}>
                                    {msg}
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex', height: '25vh', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
                                <Typography sx={{fontSize: 26, fontWeight: 400, color: grey[800]}}>
                                    You can return to shop and use different payment method.
                                </Typography>
                            </Box>

                        </Box>
                    }

                </Container>
            </Layout>
    )
}

export default CancelPage