import React , { useEffect, useState } from "react";
// import Layout from "../components/layout";
import { Box, Typography, CircularProgress} from "@mui/material";
// import { alpha, useTheme } from '@mui/material/styles';

// import { setDoc, doc, getDocs, getDoc, onSnapshot, collection } from "firebase/firestore";
// import { auth, db } from '../firebase/index';
// import Chart from "react-apexcharts";
import { Sectionheader, Figurecaption } from './reportBlocks';
import { MetricsStats, } from './tableblocks';
import { MetricSector, ScoreSector, ScoreTop10 } from './figureBlocks';

export default function Profitability({children, sector, input}) {

    // const theme = useTheme()
    const [loading, setLoading] = useState(true)

    const PROFS = input.data
    const Statistics = input.stats
    const highScore = input.highScore
    // const scoreBreakdown = input.topScores

    let pron
    if (highScore.data[0] === 10) {
        pron = 'a perfect '
    } else {
        if (highScore.data[0] >= 8.5) {
            pron = 'an impressive '
        } else {
            pron = 'a considerable '
        }
    }

    const [gpmRatio, setGpmRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [gpmTick, setgpmTick] = useState({
        "Series1": []
        }
    )

    const [opmRatio, setOpmRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [opmTick, setopmTick] = useState({
        "Series1": []
        }
    )

    const [npmRatio, setNpmRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [npmTick, setnpmTick] = useState({
        "Series1": []
        }
    )

    const [profitability, setProfitability] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [profTick, setProfTick] = useState({
        "Series1": []
        }
    )

    useEffect( () => {
        let gpm_ratio_dataset = []
        let gpm_ratio_tickers = []

        let opm_ratio_dataset = []
        let opm_ratio_tickers = []

        let npm_ratio_dataset = []
        let npm_ratio_tickers = []

        let prof_score_dataset = []
        let prof_score_tickers = []
        for (const [key, value] of Object.entries(PROFS)) {
            
            gpm_ratio_dataset.push([value[3], value[0], value[4]])
            gpm_ratio_tickers.push({'ticker': key})

            opm_ratio_dataset.push([value[3], value[1], value[4]])
            opm_ratio_tickers.push({'ticker': key})
            
            npm_ratio_dataset.push([value[3], value[2], value[4]])
            npm_ratio_tickers.push({'ticker': key})

            prof_score_dataset.push([value[3], value[8], value[4]])
            prof_score_tickers.push({'ticker': key})
        }

        setGpmRatio({
            series: [{  
                    name: 'GPM Ratio',
                    data: gpm_ratio_dataset,
                    }],
                }
            )
        setgpmTick({
            "Series1": gpm_ratio_tickers,
        })

        setOpmRatio({
            series: [{  
                name: 'OPM Ratio',
                data: opm_ratio_dataset,
            }],            
        })
        setopmTick({
            "Series1": opm_ratio_tickers,
        })

        setNpmRatio({
            series: [{  
                name: 'NPM Ratio',
                data: npm_ratio_dataset,
            }],            
        })
        setnpmTick({
            "Series1": npm_ratio_tickers,
        })

        setProfitability({
            series: [{  
                name: 'Profitability Score',
                data: prof_score_dataset,
            }], 
        })
        setProfTick({
            "Series1": prof_score_tickers,
        })

        setLoading(false)
        return (() => {})
    }, [])


    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md: 18}, 
                        fontWeight: '300', 
                        mt: '1%',
                        lineHeight: 1.5}}>
                    Following previous sections on profitability and solvency ratios, we now shift our focus to profitability ratios.
                    Profit margins are metrics of a company's financial health and performance, reflecting its ability to generate
                    profit over a given period. They are typically presented as percentages and are computed by dividing a
                    subset of profits by the revenue. Profit margins are crucial for investors and analysts as they provide insights
                    into a company's efficiency, competitiveness, and profitability. 
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md: 18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>                    
                    Higher profit margins often indicate better financial health and effective cost management, making them a key 
                    indicator for evaluating a company's overall performance and potential investment value. The financial community 
                    primarily relies on three key profitability ratios: Gross Profit Margin (GPM), Operating Profit Margin (OPM), and 
                    Net Profit Margin (NPM) when evaluating a company's financial performance.
                </Typography>

                <Sectionheader head={'3.3.1 Metric Definition'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Gross Profit Margin (GPM): </Box> Gross profit margin is a 
                    financial metric that measures a company's profitability by calculating the percentage of revenue left after 
                    deducting the cost of goods sold. A higher margin indicates better financial health and efficiency. It is useful 
                    for comparing companies in the same industry, as it provides insights into their pricing strategies, production 
                    costs, and supply chain management.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Gross Profit Margin =  Gross Profit &frasl; Revenue
                    </Typography>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Operating Profit Margin (OPM): </Box> The operating profit 
                    margin is a measure that indicates a company's profitability derived from core business activities after 
                    subtracting operating expenses, such as salaries, leases, and advertising costs. It is expressed as a percentage 
                    of operating profit to total revenue. OPM provides an accurate view of a company's ability to produce profits 
                    from its primary revenue-generating operations, irrespective of other factors like taxes, interest, and one-time 
                    gains or losses.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Operating Profit Margin = Operating Income &frasl; Revenue
                    </Typography>
                </Box>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Net Profit Margin (NPM): </Box> Net profit margin is a metric 
                    that measures a company's operating income, after deducting operating expenses such as wages, taxes, and depreciation, 
                    as a percentage of total revenue. It provides insights into a company's ability to generate profits from its core 
                    operations.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Net Profit Margin = Net Profit &frasl; Revenue
                    </Typography>
                </Box>

                <Sectionheader head={'3.3.2 Sector Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Profitability margin distributions are typically sensitive to extreme values, given their narrow fluctuation
                    range. Consequently, the calculation of distribution's statistics can be significantly affected by these 
                    outliers, potentially distorting the input values for scoring calculations. In alignment with our established 
                    methodology and to create a reliable benchmark, statistical parameters were computed using winsorized data, 
                    with upper and lower limits (not the percentiles) set at <Box fontWeight='fontWeightMedium' display='inline'> 3 
                    </Box> and <Box fontWeight='fontWeightMedium' display='inline'>-0.02</Box>, respectively. <Box fontWeight='fontWeightMedium' 
                    display='inline'>Figure 20</Box> to <Box fontWeight='fontWeightMedium' display='inline'>22</Box> offer a visual representation of profitability metrics in comparison to a 
                    company's winsorized 1-year returns.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>

                </Typography>

                <MetricSector data={gpmRatio} name={''} mjsn={gpmTick}/>

                <Figurecaption caption={'Figure 20: GPM vs. 1-year return across the sector'}/>

                <MetricSector data={opmRatio} name={''} mjsn={opmTick}/>

                <Figurecaption caption={'Figure 21: OPM vs. 1-year return across the sector'}/>

                <MetricSector data={npmRatio} name={''} mjsn={npmTick}/>

                <Figurecaption caption={'Figure 22: NPM vs. 1-year return across the sector'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Note: </Box> While high profitability ratios are generally 
                    considered positive indicators, there are situations where extremely high profitability ratios might not be great, 
                    and this could be due to various reasons. A company might achieve a very high profitability ratio through 
                    unsustainable practices. For example, neglecting essential maintenance or research and development to cut costs in 
                    the short term can lead to problems later on. In certain industries, extremely high profitability might be unusual. 
                    It could suggest that the company has a unique advantage or is operating in a niche market, but it might also raise 
                    questions about competition and the sustainability of such profitability in the industry context.
                </Typography>

                <Figurecaption caption={'Table 5: Statistics of profitability ratios distribution'} />

                <Box sx={{mb: '2%', border:1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats table={Statistics}/>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    {/* <Box fontWeight='fontWeightMedium' display='inline'>Note: </Box> */}
                    The distribution's statistics are presented in <Box fontWeight='fontWeightMedium' display='inline'>Table 5</Box>. 
                    Based on these values, readers can compare the financial performance of companies with sector-wide norms. For 
                    instance, on average, companies in the {sector} sector report a gross gain of <Box fontWeight='fontWeightMedium' 
                    display='inline'> ${ 1000 * Statistics['GPM'].mean}</Box> for every $1,000 earned in their revenue. 
                    In addition, it can be concluded that sector norm is to gain <Box fontWeight='fontWeightMedium' display='inline'>
                    $ {Statistics['NPM'].mean}</Box> in net profit for of every dollar in revenue . 
                </Typography>

                <Sectionheader head={'3.3.3 Scoring Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The comparison of profit margins across different businesses is challenging, due to the distinctive nature
                    of each business. In addition, the wide range of profit margins observed in the sector makes it difficult to
                    determine if a particular business has a promising profit margin. Therefore, it is vital to adopt a thorough
                    approach to evaluate and understand the distribution of profit margins across the sector that would allow for
                    a more subtle analysis of the factors contributing to the profitability of a business. The scoring system aims 
                    to provide an objective comparison of profitability metrics across the sector. The composite profitability 
                    score, as weigthed average of each profitability metric, versus winsorized one-year return for the companies 
                    in the sector is shown in <Box fontWeight='fontWeightMedium' display='inline'>Figure 23</Box>.
                </Typography>

                <ScoreSector series={profitability} labels={profTick}/>

                <Figurecaption caption={'Figure 23: Composite profitability score vs. 1-year return'} />
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The top 10 companies with the highest composite profitability scores in the {sector} sector are shown 
                    in <Box fontWeight='fontWeightMedium' display='inline'>Figure 24</Box>. Ticker <Box fontWeight='fontWeightMedium' 
                    display='inline'>{highScore.names[0]}</Box> secured the top rank in the list, with {pron} composite score of 
                    <Box fontWeight='fontWeightMedium' display='inline'> {highScore.data[0]}</Box>. Tickers <Box fontWeight='fontWeightMedium' 
                    display='inline'> {highScore.names[1]}</Box> and <Box fontWeight='fontWeightMedium' display='inline'>{highScore.names[2]}
                    </Box> secured the second and third positions with total scores of <Box fontWeight='fontWeightMedium' display='inline'>
                    {highScore.data[1]}</Box> and <Box fontWeight='fontWeightMedium' display='inline'>{highScore.data[2]}</Box>, respectively.
                </Typography>

                <ScoreTop10 data={highScore.data} names={highScore.names} label={''}/>

                <Figurecaption caption={'Figure 24: Companies with highest total profitability score'} />


                {/* <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Reader is refered to <Box fontWeight='fontWeightMedium' display='inline'>Tables ZZ and XY</Box> for a more detailed 
                    view of profitability ratios and their respective scores across the sector.
                </Typography>

                <Box>
                    <ScoreTable table={scoreBreakdown} score={['GPM', 'OPM', 'NPM']}/>
                </Box> */}

            </Box>
            }
        </div>
    )

}