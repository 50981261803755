import React, { useContext } from "react"
import { Navigate, useLocation } from "react-router-dom";
import { UserAuth } from "../context/userContext";
// import { UserContext } from "../context/user-context";


export default function PrivateRoute({ children }) {
    let { user } = UserAuth()
    // console.log(user)
    // const location = useLocation()
    // const { user } = useContext(UserContext)

    return user ? children : <Navigate to={'/signin'} replace />
}