import React from "react";
import { grey } from "@mui/material/colors";
import { Box, CardMedia, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import aio from '../assets/Designer.gif'

export default function Intro() {

    return(
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            }}>

            <Box sx={{
                display: "flex", 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100%', 
                width: '100%'}}>
                <Typography 
                    textAlign={'center'} 
                    sx={{
                        fontSize: {xs: 36, md: 48}, 
                        fontWeight: 800, 
                        lineHeight: 2,
                        color: grey[900],
                        pt: '4%',
                        width: '100%', 
                        height: '100%'}}>
                        {/* 👋 Welcome to FARSIGHT! */}
                        Welcome to FARSIGHT!
                </Typography>
            </Box>

            <Box sx={{
                display: "flex", 
                flexDirection: {xs: 'column', md: 'row'},
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100%', 
                width: '100%'}}>
                    <Box sx={{width: '100%', display: "flex", flexDirection: 'column'}}>
                        <Typography 
                            paragraph
                            textAlign={'justify'} 
                            sx={{
                                fontSize: {xs: 16, md:18}, 
                                fontWeight: 300, 
                                pt: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                We offer high-quality, data-driven, and affordable financial market sector studies tailored for 
                                savvy investors like you. Each study evaluates 21 financial ratios for all companies within the 
                                given sector. To simplify the assessment, we assign a proprietary score to each company, 
                                ranking their overall performance relative to their peers. These studies set the gold standard for 
                                benchmarking various aspects of financial health and metrics across different market sectors.
                        </Typography>
                        <Typography 
                            paragraph
                            textAlign={'justify'} 
                            sx={{
                                fontSize: {xs: 16, md:18}, 
                                fontWeight: 300, 
                                pt: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                Our reports can be purchased as individual sector studies in PDF format or through a monthly or 
                                annual subscription. PDF version of the study spans more than 60 pages. A subscription plan 
                                provides access to our latest, weekly updated reports via a web app, enhancing interaction with 
                                the data. You can download a free study via our {<Link to={'/sample'} style={{color: 'inherit', 
                                fontWeight: 'fontWeightMedium'}}><Box fontWeight='fontWeightMedium' display='inline'>
                                Sample</Box></Link>} page, to evaluate it yourself!
                        </Typography>
                    </Box>
                    <Box sx={{
                        display:'flex', 
                        alignContent: 'center', 
                        justifyContent: 'center',
                        // maxHeight: {xs: '480px'},
                        // height: {xs: '480px', sm: '800px', md: '420px', lg: '500px'}, 
                        //     width: {xs: '480px', sm: '800px', md: '420px', lg: '500px'}, 
                        // width: '95%'
                        width: '95%',
                        height: '95%',
                        ml: {xs: 0, md: 1},
                        mt: {xs: 1, md: 0}
                        }}>
                            <Box sx={{
                                height: {xs: '400px', sm: '550px', md: '400px', lg: '400px'},
                                width: {xs: '400px', sm: '550px', md: '400px', lg: '400px'},
                            }}>
                                <CardMedia image={aio} sx={{width: '100%', height: '100%'}}/>
                            </Box>
                        
                        {/* // sx={{ 
                        //     height: {xs: '480px', sm: '800px', md: '420px', lg: '500px'}, 
                        //     width: {xs: '480px', sm: '800px', md: '420px', lg: '500px'},
                        //     }} */}
                            
                    </Box>

            </Box>

            <Box sx={{
                display: "flex", 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100%', 
                width: '100%'}}>
                <Typography 
                    textAlign={'center'} 
                    sx={{
                        fontSize: {xs: 28, md: 32}, 
                        fontWeight: 400, 
                        lineHeight: 2,
                        pt: '2%',
                        width: '100%', 
                        height: '100%'}}>
                        {/* What is a good P/E ratio? 🤨 */}
                        Questions that no one clearly answers
                </Typography>
            </Box>

            <Box sx={{
                display: "flex", 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100%', 
                width: '100%'}}>

                <Typography 
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: 300, 
                        pt: '2%',
                        width: '100%', 
                        height: '100%'}}>
                        Let's start by asking what is a good P/E ratio? or if a 25% net profit margin is outstanding or, well, downright awful? These seemingly simple questions become complex to answer. While 
                        various financial resources are readily available online, finding a precise answer isn't always easy. Most free and paid websites out there 
                        will tell you that it depends on the sector and industry a company belongs to, and some will hit you with vague, robotic responses like, 
                        "Generally, a value of X is considered good, but it depends on the sector...". 
                    </Typography>

                <Typography 
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: 300, 
                        pt: '2%',
                        width: '100%', 
                        height: '100%'}}>
                        Surprisingly, very few provide specific information on what's 
                        poor, good, or just normal for a financial metric within a particular market sector at any given time. But you deserve better! You need 
                        real-time data to gauge where your investments stand against their peers, explain distribution of various metrics at sector and industry level 
                        and that's precisely what we provide.
                </Typography>

            </Box>

            <Box sx={{
                display: "flex", 
                alignItems: 'center', 
                justifyContent: 'center',
                width: '100%', 
                height: '100%'}}>
                <Typography 
                    textAlign={'center'} 
                    sx={{
                        fontSize: {xs: 28, md: 32}, 
                        fontWeight: 400, 
                        lineHeight: 2,
                        pt: '2%',
                        pb: '2%',
                        width: '100%', 
                        height: '100%'}}>
                    Enter FARSIGHT ...
                </Typography>
            </Box>

            <Box sx={{
                display: "flex", 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center', 
                width: '100%',
                height: '100%'}}>

                <Typography 
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: 300, 
                        width: '100%', 
                        height: '100%'}}>
                        Farsight studies were born to provide answers to questions like these. We meticulously analyze the entire financial market on a weekly basis, 
                        sector by sector. The central focus of each study is dedicated to ratio analysis. We conduct detailed statistical analyses and present the 
                        distribution of currently 35 financial metrics for each and every stock within these sectors. These metrics span from liquidity (for example, 
                        quick or cash ratio) to profitability margins, to management performance (return on assets) ratios. Additional details on weekly studies can be 
                        found at {<Link to={'/solutions'} style={{color: grey[900], fontWeight: 'bold'}}>Solution</Link>} page. You can also 
                        find a free sample of a study by visiting {<Link to={'/sample'} style={{color: grey[900], fontWeight: 'bold'}}>Sample
                        </Link>} page.
                </Typography>

            </Box> 

        </Box>
    )
}