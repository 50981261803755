import React from "react";
import { Box } from "@mui/material";
// import teest1 from '../assets/teest1.jpg';
import Benefits from "./features";
import Explanation from "./explanation";
import Intro from "./intro";

export default function Main(){

    return(
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            justifyContent: 'center', 
            }}>

            <Intro />

            <Explanation />

            <Benefits />

        </Box>
    )

}
