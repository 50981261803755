import React from "react";
import { Container, Alert, AlertTitle, Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

export default function SiteAlert() {

    const theme = useTheme()

    return(
        <Container maxWidth='lg' disableGutters sx={{background: theme.palette.background.default}}>
            <Alert 
                icon={false} 
                variant='filled' 
                color="info" 
                severity="info" 
                sx={{marginTop: '2rem', display: 'block'}}>
                <AlertTitle 
                    sx={{
                        fontFamily: 'inherit', 
                        fontSize: {xs: 20, md: 24}, 
                        fontWeight: 700,
                        textAlign: 'center'}}>
                    Summer Sales
                </AlertTitle>
                <Box 
                    sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center', 
                        width: '100%'}}>
                    <Typography sx={{
                        fontFamily: 'inherit', 
                        fontSize: {xs: 16, md: 20}, 
                        fontWeight: 500,
                        textAlign: 'center'}}>
                        For a limited time, select studies are offered at $4.99!
                    </Typography>
                    <Typography sx={{
                        fontFamily: 'inherit', 
                        fontSize: {xs: 16, md: 20}, 
                        fontWeight: 500,
                        textAlign: 'center'}}>
                        Head to the Shop and get yours today!
                    </Typography>
                </Box>
            </Alert>
        </Container>
    )
}