import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import theme from './theme/theme';
import reportWebVitals from './reportWebVitals';
import ProductContextProvider from './context/product-context';
// import CartContextProvider from './context/cart-context';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import UserContextProvider from './context/userContext';
import { ThemeProvider } from '@mui/material/styles';
import Scroller from './components/scroller';
// import theme from './theme/theme';

// let theme = createTheme();

// theme = createTheme(theme, {
//     // Custom colors created with augmentColor go here
//     palette: {
//       salmon: theme.palette.augmentColor({
//         color: {
//           main: '#FF5733',
//         },
//         name: 'salmon',
//       }),
//     },
//   })

// const theme = createTheme({
//     palette: {
//         ochre: {
//             main: '#E3D026',
//             light: '#E9DB5D',
//             dark: '#A29415',
//             contrastText: '#242105',
//           },
//         lightblue: {
//             main: '#FF5733',
//             light: alpha('#00FFFF', 0.5),
//             dark: alpha('#00FFFF', 0.8),
//         },
//         tonalOffset: 0.25,
//     },
    // typography: {
    //     fontFamily: [
    //         '-apple-system', 
    //         'BlinkMacSystemFont',
    //         'Source Serif 4', 'serif'
    //     ].join(','),
    // }
// })

// theme = responsiveFontSizes(theme, {breakpoints: ['xs', 'sm', 'md', 'lg'], factor: 3});

const root = ReactDOM.createRoot(document.getElementById('root'));

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)

// const options = {clientSecret: [{CLIENT_SECRET}]}



root.render(

    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Scroller />
            {/* <ProductContextProvider> */}
                {/* <CartContextProvider> */}
                    <Elements stripe={stripePromise}>
                        <UserContextProvider>
                            <ProductContextProvider>
                                <App />
                            </ProductContextProvider>
                        </UserContextProvider>
                    </Elements>
                {/* </CartContextProvider> */}
            {/* </ProductContextProvider> */}
        </BrowserRouter>
    </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
