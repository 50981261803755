import React, { useEffect, useState } from "react";
import { Typography, Box, CardMedia, Button, Grid } from "@mui/material";
import { buttonColors, contrastTextColors } from '../theme/colors';
import { UserAuth } from "../context/userContext";
import { useNavigate } from "react-router-dom";
import { functions } from '../firebase/index';
import { useStripe, } from '@stripe/react-stripe-js';
import { httpsCallable } from "firebase/functions";
import { grey } from "@mui/material/colors";
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';


function ProductCard(props) {

    const priceArray = props.product.prices

    const monthlyPrice = priceArray.filter(
        (pr) => pr.active === true && pr.type === "recurring" && pr.interval === "month"
        )

    const yearlyPrice = priceArray.filter(
        (pr) => pr.active === true && pr.type === "recurring" && pr.interval === "year"
        )

    const purchasePrice = priceArray.filter(
        (pr) => pr.active === true && pr.type === "one_time"
        )

    const [per, setPer] = useState('Month')
    const [price, setPrice] = useState(monthlyPrice[0])
    const [stripeMod, setStripeMod] = useState('subscription')
    const [buttonText, setButtonText] = useState('Subscribe to sector review')

    const { isUserSignedIn } = UserAuth()
    const stripe = useStripe();
    const CreateStripeCheckoutA = httpsCallable(functions, 'CreateStripeCheckoutA')
    const navigate = useNavigate()
    const theme = useTheme()



    useEffect(() => {

        if(props.plan.type === "recurring") {

            setButtonText('Subscribe to sector review')
            setStripeMod('subscription')

            if (props.plan.interval === 'month') {
                setPer('Month')
                setPrice(monthlyPrice[0])
                // console.log(monthlyPrice[0])
            } else {
                setPer('Year')
                setPrice(yearlyPrice[0])
            }

        } else {
            setPer('Study')
            setPrice(purchasePrice[0])
            setButtonText('Buy sector review')
            setStripeMod('payment')
            
        }

        // console.log(props.plan)

    }, [props.plan])

    return(
        <Grid 
            item 
            key={props.product.id} 
            xs={12} sm={9} md={6} lg={4} 
            sx={{ 
                height: '100%',
                ml: {xs: 0, sm: '12.5%', md: 0},
                }}>

            <Box sx={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: {xs: '550px', sm: '650px', md: '600px'},
                // width: {xs: '100%', sm: '66%'},
                minWidth: '350px',
                border: 0.5,
                borderColor: grey[400],
                background: 'white',
                borderRadius: '0.4em',
                }}>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    width: '100%',
                    height: '100%',
                    mt: '5%',
                    }}>
                    <CardMedia 
                        component={'img'}
                        image={props.product.images[0]} 
                        sx={{ 
                            maxHeight: 270,
                            maxWidth: {xs: '480px', sm: '540px', md: '500px'},
                            // height: '100%', 
                            width: '80%', 
                            alignSelf: 'center', 
                            borderRadius: '6px'}}/>
                </Box>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    width: '100%',
                    mt: '5%', 
                    }}>
                    <Typography 
                        textAlign={'center'} 
                        sx={{fontSize: 20, fontWeight: '500', lineHeight: 2}}>
                        {props.product.name}
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    width: '100%', 
                    mt: '5%',
                    }}>
                    <Typography 
                        paragraph 
                        textAlign={'justify'} 
                        sx={{
                            width: '80%',
                            fontSize: 16, 
                            fontWeight: 300, 
                            }}>
                        {props.product.description}
                    </Typography>
                </Box>
                
                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    width: '100%',
                    mt: '2%',
                    mb: '2%',
                    height: {xs: '50px', sm: '60px'},
                    }}>

                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center', 
                        justifyContent: 'right', 
                        // background: 'yellow',
                        width: '100%',
                        height: '100%',
                        }}>
                        <Typography 
                            sx={{
                                fontSize: {xs: 30, md: 34, lg: 32}, 
                                fontWeight: 700, 
                                mr: '5%'
                                }}>
                                ${price.unit_amount/100}
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'center',
                        width: '100%',
                        height: '100%',
                        }}>

                        <Typography sx={{fontSize: {xs: 16, md:18}, ml: '5%'}}>
                            per
                        </Typography>

                        <Typography sx={{fontSize: {xs: 16, md:18}, ml: '5%'}}>
                            {per}
                        </Typography>

                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    width: '100%', 
                    mt: '5%',
                    mb: '5%'
                    }}>

                    <Button                                 
                        disableElevation
                        variant='outlined'  
                        value={price.id}
                        onClick={(e) => {
                            if (!isUserSignedIn()) {
                                navigate('/signin')
                                console.log('user is not signed in.')
                            } else {
                                const paydata = e.currentTarget.value
                                const data = {
                                    mode: stripeMod, 
                                    price: paydata, 
                                    sector: props.product.metadata.sector, 
                                    name: props.product.name
                                }
                                console.log(data)
                                CreateStripeCheckoutA(data)
                                .then(response => {
                                    console.log(response)
                                    const session_Id = response.data.id
                                    stripe.redirectToCheckout({sessionId: session_Id})
                                })
                                .catch((error) => {
                                    const code = error.code;
                                    const message = error.message;
                                    const details = error.details;
                                    console.log('Error code: ', code)
                                    console.log('Msg: ', message)
                                    console.log('Details: ', details)
                                })
                                    }
                                }}
                        sx={{
                            border: 1.6,
                            borderColor: buttonColors[props.index],
                            // backgroundColor: grey[100],
                            // borderColor: buttonColors[props.index],
                            // backgroundColor: alpha(buttonColors[props.index], 0.75),
                            // color: contrastTextColors[props.index],
                            backgroundColor: alpha(buttonColors[props.index + 1], 0.2),
                            color: grey[700],
                            '&:hover': {
                                border: 2,
                                borderColor: alpha(buttonColors[props.index], 0.75),
                                // backgroundColor: alpha(buttonColors[props.index + 1], 0.2),
                                // color: grey[700],
                                backgroundColor: alpha(buttonColors[props.index], 0.75),
                                color: contrastTextColors[props.index],
                            },
                            width: '80%', 
                            height: 40, 
                            fontFamily: 'inherit',
                            borderRadius: 20}}
                            >

                            <Typography 
                                textAlign={'center'} 
                                sx={{
                                    fontSize: 16, 
                                    fontWeight: 400, 
                                    // color: grey[700]
                                    // color: contrastTextColors[props.index]
                                    }}>
                                {buttonText}
                            </Typography>


                    </Button>

                </Box>

            </Box>

        </Grid>
    )
}

export default ProductCard

