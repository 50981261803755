import React , { useEffect, useState } from "react";
// import Layout from "../components/layout";
import { Box, Typography, CircularProgress} from "@mui/material";
import Chart from "react-apexcharts";
import { Sectionheader, Figurecaption } from './reportBlocks';
// import { GeneralTable } from './tableblocks';
import { WeightedAverage, ReturnTop10 } from './figureBlocks';
import { Capitalize } from "./utils";
import { Link } from "react-router-dom";
// import { AverageReturn } from "./tableblocks";
// import { chartColors } from "../theme/colors";

export default function Returns({input, sector}) {

    const Sector = Capitalize(sector)
    const [loading, setLoading] = useState(true)

    const averages = input.average
    const rets = input.performance

    const dataytd = {
        data: rets.ytd.win.slice(0, 10),
        names: rets.ytd.tickers.slice(0, 10),
    }

    const data1yr = {
        data: rets.oneyr.win.slice(0, 10),
        names: rets.oneyr.tickers.slice(0, 10),
    }

    const data3yr = {
        data: rets.threeyr.win.slice(0, 10),
        names: rets.threeyr.tickers.slice(0, 10),
    }

    const aa = {
        series: [
            {
                name: 'raw data',
                data: [15, 18, 20, 22, 25, 30, 100],
            },
        ],
    }

    const bb = {
        series: [
            {
                name: 'Winsorized data',
                data: [15, 18, 20, 22, 25, 30, 30],
            },
        ],
    }

    const options = {
        options: {
            chart: {
                type: "bar",
                toolbar: {
                    show: false,
                },
              },
            plotOptions: {
                bar: {
                    columnWidth: '55%',
                    borderRadiusApplication: 'end',
                    borderRadius: 7,
                    distributed: true,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                      },
                }
            },
            dataLabels: {
                enabled: false,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                x: {
                    formatter: function(val, {w}){
                        return 'Age = ' + val
                    }
                },
            },
            xaxis: {
                categories: [1, 2, 3, 4, 5, 6, 7],
                axisBorder: {
                    show: false,
                },
            },
            legend: {
                show: false,
            },
            colors: ['#e01fff', '#c03fff', '#a05fff', '#807fff', '#609fff', '#40bfff', '#20dfff'],
        }
    }

    const caption = 'Figure 5: '.concat(Sector, ' sector simple and weighted-average returns, winsorized at 98%')

    useEffect( () => {

        setLoading(false)
        return( () => {})
    }, [])

    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
                : 
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    width: '100%',}}>

                    <Sectionheader head={'2.1. Metric Definition'} />

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{mt: '1%', fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Rate of return (ROR)</Box>: Rate of return is 
                        the percentage change in an investment's initial cost over a specified period, indicating its net 
                        gain or loss. It essentially quantifies the change in value from the beginning to the end of that 
                        period. To calculate the rate of return, we take into account the investment price at the start of 
                        the period (P<sub>0</sub>), the price at the end of the period (P<sub>1</sub>), and any dividends 
                        received (D). The formula for this calculation
                        is as follows:
                    </Typography>

                    <Box sx={{ width: '90%', alignSelf: 'center' }}>
                        <Typography
                            component='div'
                            paragraph
                            textAlign={'justify'} 
                            sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                                Rate of Return (%) = (P<sub>1</sub> - P<sub>0</sub> + D) &frasl; P<sub>0</sub>
                        </Typography>
                    </Box>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{mt: '1%', fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                        In the context of stocks, rate of return (or simply return) reflects the fluctuations in a company's shares' market 
                        value over time. It's an essential indicator influenced by a multitude of factors, such as the company's performance, 
                        sector and industry trends, economic conditions, and investor sentiment. Examining a stock's return offers investors 
                        insights into the financial well-being of the company and the overall performance of the market. Additionally, these 
                        stock returns can be a useful benchmark for comparing different companies or investment strategies (though it's
                        important to note that this study doesn't offer such comparisons).
                    </Typography>

                    <Sectionheader head={'2.2. Winsorization: Handling Outlier Values'}/>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{mt: '1%', fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                        Extreme values, either exceptionally high or low, are common in financial data and can distort overall analysis 
                        accuracy. It's crucial to use appropriate methods to ensure meaningful and unbiased results. One such method, is 
                        known as <Box fontWeight='fontWeightMedium' display='inline'>winsorization</Box>. This technique revolves around 
                        the setting limits for these extreme values. Instead of removing these values from the dataset, winsorization 
                        adjusts them by replacing them with values that align better with the remaining data points. This maintains the 
                        dataset's integrity and prevents distortions caused by outliers.
                    </Typography> 
                        
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{mt: '1%', fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                        For instance, let's consider a dataset of ages: 15, 18, 20, 22, 25, 30, and an outlier at 100, as demonstrated in 
                        <Box fontWeight='fontWeightMedium' display='inline'> Figure 4</Box>. This outlier, with its exceptionally 
                        high value, can significantly distort the dataset's results. It may lead to an unrealistic average (mean) for the 
                        dataset, in this case, an improbable 32.85.
                    </Typography>

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: {xs: 'column', sm: 'row'},
                        justifyContent: 'space-between', 
                        width: {xs: '98%', md: '80%'}, 
                        }}>
                        <Box sx={{
                            border:1,
                            borderRadius: 2,
                            background: 'white',
                            mb: {xs: '2%', sm: '0%'},
                            width: {xs: '95%', sm: '49%'}}}>
                            <Chart series={aa.series} options={options.options} type="bar"/>
                        </Box>
                        <Box sx={{                        
                            border:1,
                            borderRadius: 2,
                            background: 'white',
                            width: {xs: '95%', sm: '49%'}}}>
                            <Chart series={bb.series} options={options.options} type="bar"/>
                        </Box>
                    </Box>

                    <Figurecaption caption={'Figure 4: Sample age dataset, the original and winsorized values'}/>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{mt: '1%', fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                        To handle this through winsorization, it is decided to replace the outlier with a value within a specified range. 
                        For example, if the highest value within this range is 30, we use that as the replacement for the outlier. As a 
                        result of winsorization, the dataset is modified to 15, 18, 20, 22, 25, 30, and another 30, as presented in <Box 
                        fontWeight='fontWeightMedium' display='inline'>Figure 4</Box>. This simple adjustment brings the average of the 
                        modified dataset to a more realistic and representative value of 22.85. In this way, winsorization helps in 
                        preserving the accuracy and reliability of data analysis. For more in-depth information, please visit the 
                        corresponding {<Link to={'https://en.wikipedia.org/wiki/Winsorizing'} style={{color: 'inherit', 
                        fontWeight: 'fontWeightMedium'}}>Winsorization</Link>} page on Wikipedia.
                    </Typography>

                    <Sectionheader head={'2.3. Return Study Framework'}/>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{mt: '1%', fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                        Selecting a specific timeframe for analyzing a stock's return is crucial as it provides a standardized 
                        period for readers to compare different stocks consistently and objectively. Examining multiple time 
                        periods can aid readers in identifying potential anomalies in a company's performance over time. In this 
                        regard, the study investigates the returns of the {sector} sector over three distinct timeframes: 
                        year-to-date (ytd), 1-year, and 3-years. All returns are expressed as percentages (for example, a return 
                        of 4.2% corresponds to a value of 0.042). 
                    </Typography>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{mt: '1%', fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                        Due to the nature of return values, which cannot fall below 
                        -100%, the process of winsorization focuses solely on adjusting extreme values at the upper end of the 
                        distribution. In this context, and analyze the sector's average return, the return values are 
                        winsorized at the <Box fontWeight='fontWeightMedium' display='inline'>98th</Box> percentile to mitigate 
                        the impact of outliers without significantly altering the overall distribution. By applying winsorization 
                        in this way, the analysis retains a more accurate representation of the data's tendency and variability, 
                        allowing for more robust statistical inferences and insights.
                    </Typography>

                    <Sectionheader head={'2.4. Sector Average Return'} />

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{mt: '1%', fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                        To better understand overall trends in the market, both simple and weighted average returns for each
                        time-horizon are calculated. These values are presented in <Box fontWeight='fontWeightMedium' 
                        display='inline'>Figure 5</Box>. The sector's weighted average return for each time-horizon is
                        computed by summing up the multiple of the return of each company by its market capitalization and divide
                        it by the sector market cap (sum of market capitalization of companies in the sector).
                    </Typography>

                    <WeightedAverage averages={averages} />
                    
                    <Figurecaption caption={String(caption)} />

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{mt: '1%', fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                        A weighted average of stock returns in a sector can provide a more accurate representation of sector performance
                        compared to a simple average. This is because a weighted average assigns varying degrees of importance to
                        each stock based on factors like market capitalization. By doing so, it accounts for the influence of larger,
                        more impactful companies within the sector, offering a more balanced and representative measure of overall
                        sector performance, making it less susceptible to distortion by extreme values of smaller companies. <Box 
                        fontWeight='fontWeightMedium' display='inline'>Figures 6</Box> to <Box fontWeight='fontWeightMedium' 
                        display='inline'>8</Box> display the top 10 companies with the highest returns for each time horizon.
                    </Typography>

                    <ReturnTop10 data={dataytd} name={''}/>

                    <Figurecaption caption={'Figure 6: Top 10 stocks with highest year-to-date return (winsorized)'} />

                    <ReturnTop10 data={data1yr} name={''}/>

                    <Figurecaption caption={'Figure 7: Top 10 stocks with highest 1-year return (winsorized)'} />

                    <ReturnTop10 data={data3yr} name={''}/>

                    <Figurecaption caption={'Figure 8: Top 10 stocks with highest 3-year return (winsorized)'} />

                </Box>
            }
        </div>
    )

}