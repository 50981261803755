import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { grey, purple } from "@mui/material/colors";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import UpdateIcon from '@mui/icons-material/Update';
import MoneyIcon from '@mui/icons-material/Money';
import { buttonColors, contrastTextColors } from '../theme/colors';
import { useTheme } from '@mui/material/styles';

export default function Benefits() {

    const theme = useTheme()

    const Data = [
        {
            header: 'Frequent Updates', 
            text: 'Weekly update to stay on top of market movements', 
            icon: <UpdateIcon sx={{fontSize: 40, color: buttonColors[2]}}/>
            },
        {
            header: 'Peer Comparison', 
            text: 'Each company is throughly compared with its peers across the sector', 
            icon: <Diversity3Icon sx={{fontSize: 40, color: buttonColors[4]}}/>
            },
        {
            header: 'Full Picture',
            text: '21 financial metrics investigated throughly in each report',
            icon: <FullscreenIcon sx={{fontSize: 40, color: buttonColors[8]}}/>,
        }, 
        {
            header: 'Affordable Price',
            text: 'Our studies deliver top-notch insights, without breaking the bank',
            icon: <MoneyIcon sx={{fontSize: 40, color: buttonColors[10]}}/>,
        }
    ]

    const disc = <Typography sx={{
        fontSize: {xs: 16, md:18}, 
        fontWeight: 500,}}>
            Absolutely NOT!
    </Typography>


    return(
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'center',
            width: '100%',
            }}>

            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                width: '100%',  
                }}>
 
                <Typography 
                    textAlign={'center'} 
                    sx={{
                        fontSize: {xs: 36, md: 48}, 
                        fontWeight: 800, 
                        lineHeight: 2,
                        mb: '2%',
                        color: grey[900]
                        }}>
                    How do we stand out?
                </Typography>

            </Box>

            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '5%', marginTop: '2.5%'}}>
                <Grid container spacing={2} sx={{display: 'flex', height: '100%'}}>
                    {Data.map((item) => (
                        <Grid key={item.header} item xs={12} sm={6} lg={3} sx={{display: 'flex'}}>
                            <Box sx={{
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    height: '100%', 
                                    width: '100%', 
                                    alignItems: 'center', 
                                    justifyContent: 'center',
                                    borderRadius: '0.5em', 
                                    border: 1, 
                                    background: theme.palette.background.card,
                                    borderColor: theme.palette.border.main}}>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center', 
                                    justifyContent: 'center', 
                                    mt: '5%'}}>
                                    {item.icon}
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center', 
                                    justifyContent: 'center', 
                                    mt: '2.5%'}}>
                                    <Typography 
                                        textAlign={'center'} 
                                        sx={{
                                            fontSize: 20, 
                                            fontWeight: 600,
                                            color: grey[800], 
                                            lineHeight: 1.5
                                            }}>
                                        {item.header}
                                    </Typography> 
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center', 
                                    width: '100%',
                                    mt: '2.5%', 
                                    mb: '5%', 
                                    // height: {xs: '4vh', md: '6vh'},
                                    }}>
                                    <Typography 
                                        paragraph 
                                        textAlign= 'center' 
                                        sx={{
                                            fontSize: 18, 
                                            fontWeight: 300, 
                                            mr: {
                                                xs: '5%', sm: '10%', md: '5%', lg: '10%'}, 
                                            ml: {
                                                xs: '5%', sm: '10%', md: '5%', lg: '10%'}
                                            }}>
                                        {item.text}
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid> 
                    ))}

                </Grid>
            </Box>

            <Box sx={{
                display: "flex", 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100%', 
                width: '100%'}}>
                <Typography 
                    textAlign={'center'} 
                    sx={{
                        fontSize: {xs: 28, md: 32}, 
                        fontWeight: 800, 
                        lineHeight: 2,
                        color: grey[900],
                        pb: '2%',
                        width: '100%', 
                        height: '100%'}}>
                        So, does FARSIGHT handpick stocks?
                </Typography>
            </Box>

            <Box sx={{
                display: "flex", 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'center', 
                pb: '4%',
                height: '100%', 
                width: '100%'}}>
                <Typography 
                    textAlign={'center'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: 500, 
                        width: '100%', 
                        height: '100%'}}>
                        Absolutely NOT! 
                </Typography>
                <Typography 
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: 400, 
                        width: '100%',
                        mt: '2%', 
                        height: '100%'}}>
                        At FARSIGHT, where we're all about the facts and none of the fiction! Just to be clear, we're not in the business of handing out financial advice, recommendations, or consultations. Nope, 
                        not our gig. We're not financial gurus or advisors. So, if you're looking for someone to hold your hand through investment decisions, that's not us. We're here to serve 
                        up the info, but whether you cash in or not, well, that's all on you. Investments? They're a bit like a tightrope walk - you gotta watch your step and keep your balance.
                </Typography>

                <Typography 
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: 300, 
                        mt: '2%',
                        width: '100%', 
                        height: '100%'}}>

                        We're all about putting the power in your hands to make those savvy decisions. We're like your data wizards, crunching numbers and running complex analyses every week. And the 
                        best part? Our stats are top-notch, and you won't find a better deal anywhere else.

                        Now, let's get this straight - what we're dishing out here is purely for educational kicks. This isn't us waving a sales pitch or asking you to pull out your wallet. We're not here 
                        to tell you what to buy, sell, or hang onto when it comes to investments. No siree, that's not our game. We're not creating personalized investment plans, and this isn't some fancy 
                        research report you should stake your life on.
                </Typography>

                <Typography 
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: 300, 
                        mt: '2%',
                        width: '100%', 
                        height: '100%'}}>

                        Now, that scoring system you see in this report? It's like our experiment in a lab, still in its beta testing phase. We could switch things up without sending you an invite. So, those 
                        scores you see? They're not etched in stone, and you shouldn't bet the farm on 'em. If you've got important legal, tax, or accounting choices to make, you'd better have a chat with the 
                        pros. While we've tried to get our info from good sources, we're not promising it's bulletproof.

                        And hey, don't look to this crystal ball for predicting the future; it's all about the past, which isn't a time machine to tomorrow. Past performance? Yeah, it's like looking in the 
                        rearview mirror. What's coming up? That's anyone's guess!
                </Typography>
            </Box>

        </Box>
    )
}