import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import { Container, Box, Typography, Divider, CardMedia } from "@mui/material";
// import { ref, getDownloadURL } from "firebase/storage";
import { functions, db } from '../firebase/index';
import CircularProgress from '@mui/material/CircularProgress';
import { httpsCallable } from "firebase/functions";
// import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { grey } from "@mui/material/colors";
import { useTheme } from '@mui/material/styles';
// import CapStone from '../components/capStone';
// import Thank from '../assets/ThankYou.jpg';
import TM from '../assets/thanks_modern.jpg';


async function God(sector, name, email) {

    const SignedURL = httpsCallable(functions, 'SignedURL')
    const reportMail = httpsCallable(functions, 'reportMail')

    const fullName = sector.replace(' ', '')
    const docRef = doc(db, "updates", fullName)
    const docSnap = await getDoc(docRef)

    console.log(fullName)

    if (docSnap.exists()) {
        console.log('report exists.')
        const report = docSnap.data().latestReport

        const storagePath = `${fullName}/${report}`
        console.log(storagePath)

        SignedURL({path: storagePath})
        .then( result => {
            const url = result.data.url[0]
            const data = {email: email, sector: sector, url: url, name: name}
            console.log(data)
            reportMail(data)
        })
        .catch( (error) => {} )

    } else {
        console.log('Report does not exist.')
    }

}

// async function SideOpen(sector) {

//     const SignedURL = httpsCallable(functions, 'SignedURL')
//     // const reportMail = httpsCallable(functions, 'reportMail')

//     const fullName = sector.replace(' ', '')
//     const docRef = doc(db, "updates", fullName)
//     const docSnap = await getDoc(docRef)

//     if (docSnap.exists()) {
//         const report = docSnap.data().latestReport

//         const storagePath = `${fullName}/${report}`
//         // console.log(storagePath)

//         SignedURL({path: storagePath})
//         .then( result => {
//             const url = result.data.url[0]
//             window.open(url, "_blank")
//             console.log('Signed URL is: ', url)
//             // reportMail({email: email, sector: sector, url: url})
//         })
//         .catch( (error) => {} )

//     } else {

//     }

// }

// async function Emailfb(sector, url, email) {

//     const emailRef = await addDoc(collection(db, "mail"), {
//         to: email,
//         template : {
//             name: 'reportMail',
//             data: {
//                 report: sector,
//                 link: url,
//             },
//         }
//     })
//     console.log("Document written with ID: ", emailRef.id);

// }

// async function GetReport(sector) {

//     const fullName = sector.replace(' ', '')
//     // const fullName = 'RealEstate'

//     const docRef = doc(db, "updates", fullName);
//     const docSnap = await getDoc(docRef);

//     if (docSnap.exists()) {
//         // console.log("Document data:", docSnap.data().latestReport);
//         const report = docSnap.data().latestReport
//         // console.log('Here we go:', `${fullName}/${report}`)
//         getDownloadURL(ref(storage, `${fullName}/${report}`))
//         .then( (url) => {
//             window.open(url, "_blank")
//         })
//         .catch( (error) => {})
//     } else {
//     // docSnap.data() will be undefined in this case
//         console.log("No such document!");
//     }

// }

function SuccessPage() {

    const theme = useTheme();
    // const navigate = useNavigate()

    const ret = httpsCallable(functions, 'retrieve')
    // const SignedURL = httpsCallable(functions, 'SignedURL')


    const [isLoading, setIsLoading] = useState(true)

    // const [customer, setCustomer] = useState()
    const [total, setTotal] = useState()
    const [paymentStatus, setPaymentStatus] = useState()
    const [sector, setSector] = useState()
    const [email, setEmail] = useState()
    const [payType, setPayType] = useState("payment")

    useEffect( () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('session_id')
        ret(id)
        .then(res => {
            const session = res.data.session
            console.log(session)
            // console.log(session.mode)
            setPayType(session.mode)
            // setCustomer(session.customer_details.name)
            setEmail(session.customer_details.email)
            setTotal(session.amount_total*0.01)
            setPaymentStatus(session.status)
            setSector(session.metadata.sector)
            if (session.mode === "payment") {
                God(
                    session.metadata.sector, 
                    session.customer_details.name, 
                    session.customer_details.email
                )
            }
            setIsLoading(false)
            }
        )

        return () => {}
    }, [])

    return(
            <Layout>
                
                    { isLoading && 
                        <Container maxWidth='lg' sx={{background: theme.palette.background.default}}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '85vh'}}>
                                <CircularProgress size={'4rem'}/>
                            </Box>
                        </Container>
                    } { !isLoading &&
                        <Container maxWidth='lg' sx={{background: theme.palette.background.default}}>

                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                justifyContent: 'center'}}>
                                <Typography 
                                    textAlign={'center'} 
                                    sx={{
                                        fontSize: 48, 
                                        fontWeight: 800, 
                                        pt: '4%',
                                        pb: '2%',
                                        width: '100%', 
                                        height: '100%'}}>
                                        Thank you!
                                </Typography>
                            </Box>

                            <Box sx={{display: "flex", width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>

                                <Box sx={{
                                    display: 'flex', 
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    maxWidth: {xs: '550px', sm: '800px', md: '1000px'},
                                    minWidth: '350px',
                                    // height: '25%',
                                    // maxHeight: {xs: '550px', sm: '800px', md: '1000px'},
                                    // border: 0.5,
                                    // borderColor: grey[400],
                                    // background: 'white',
                                    // borderRadius: '0.5em',
                                    // backgroundImage: `url(${TM})`,
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundPosition: 'center',
                                    // backgroundSize: 'cover',
                                    }}>
    
                                    <CardMedia 
                                        component={'img'}
                                        image={TM} 
                                        sx={{  
                                            width: '100%', 
                                            alignSelf: 'center', 
                                            borderRadius: '6px'}}/>
                                    
                                </Box>

                                { payType === "payment" ?
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: {xs: '550px', sm: '800px', md: '1000px'},
                                        height: '100%',
                                        }}>

                                        <Typography component={'div'}
                                            paragraph
                                            textAlign={'left'}
                                                sx={{
                                                    mt: '8%',
                                                    mb: '4%',
                                                    fontSize: {xs: 16, md: 28}, 
                                                    fontWeight: 400, 
                                                    color: grey[800]
                                                }}>
                                            Your payment for our weekly {sector} sector study for <Box display={'inline'} sx={{fontWeight: 500, fontSize: 32}}>${Math.round(total * 100) / 100}</Box> is now {paymentStatus}.
                                        </Typography>

                                        <Divider flexItem variant='middle' sx={{mt: {xs: '4%', md: '2%'}}}/>

                                        <Typography 
                                            textAlign={'justify'}
                                            paragraph  
                                            sx={{
                                                mt: '4%',
                                                mb: '8%',
                                                fontSize: {xs: 14, md: 28}, 
                                                fontWeight: 400, 
                                                color: grey[800]
                                                }}>
                                                An email, containing a link to download the study was sent to {email}. The link will expire in 24 hours.
                                        </Typography>

                                    </Box>
                                : 
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: {xs: '550px', sm: '800px', md: '1000px'},
                                        height: '100%',
                                        }}>

                                        <Typography 
                                            paragraph
                                            textAlign={'left'}
                                                sx={{
                                                    mt: '8%',
                                                    mb: '4%',
                                                    fontSize: {xs: 16, md: 30}, 
                                                    fontWeight: 500, 
                                                    color: grey[800]
                                                }}>
                                            Your subscription to our weekly {sector} sector study for ${Math.round(total * 100) / 100} is now complete.
                                        </Typography>


                                        <Typography 
                                            textAlign={'justify'}
                                            paragraph  
                                            sx={{
                                                mt: '4%',
                                                mb: '4%',
                                                fontSize: {xs: 16, md: 28}, 
                                                fontWeight:400, 
                                                color: grey[800]
                                                }}>
                                                Welcome aboard, and enjoy your insightful journey into the world of market studies and financial analysis. 
                                        </Typography>

                                        <Divider flexItem variant='middle' sx={{mt: {xs: '4%', md: '2%'}}}/>

                                        <Typography 
                                            textAlign={'justify'}
                                            paragraph  
                                            sx={{
                                                mt: '4%',
                                                mb: '8%',
                                                fontSize: {xs: 16, md: 28}, 
                                                fontWeight:400, 
                                                color: grey[800]
                                                }}>
                                                You can access and manage your active subscriptions by visting My account section.
                                        </Typography>

                                    </Box>
                                }

                            </Box>

                        </Container>
                    }
            </Layout>
    )
}

export default SuccessPage