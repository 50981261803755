import React from "react";
import { Typography, Box } from "@mui/material";

export default function CapStone(props) {

    return(
        <Box sx={{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center'}}>
            <Typography 
                textAlign={'center'} 
                sx={{
                    fontSize: 48, 
                    fontWeight: 800, 
                    pt: '4%',
                    pb: '2%',
                    width: '100%', 
                    height: '100%'}}>
                {props.title}
            </Typography>
        </Box>
    )
}