import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";
// import { alpha, useTheme } from '@mui/material/styles';
// import { setDoc, doc, getDocs, getDoc, onSnapshot, collection } from "firebase/firestore";
// import { auth, db } from '../firebase/index';
import { Sectionheader, Figurecaption } from './reportBlocks';
import { MetricsStats, ScoreTable } from './tableblocks';
import { MetricSector, ScoreSector, ScoreTop10 } from './figureBlocks';

export default function MarketValue({sector, input}) {

    // const theme = useTheme()
    const [loading, setLoading] = useState(true)

    const MKVS = input.data
    const Statistics = input.stats
    const highScore = input.highScore
    // const scoreBreakdown = input.topScores

    const [pbRatio, setpbRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [pbTick, setpbTick] = useState({
        "Series1": []
        }
    )

    const [psRatio, setpsRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [psTick, setpsTick] = useState({
        "Series1": []
        }
    )

    const [pcfRatio, setpcfRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [pcfTick, setpcfTick] = useState({
        "Series1": []
        }
    )

    const [mkv, setmkv] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [mkvTick, setmkvTick] = useState({
        "Series1": []
        }
    )

    useEffect( () => {
        let pb_ratio_dataset = []
        let pb_ratio_tickers = []

        let ps_ratio_dataset = []
        let ps_ratio_tickers = []

        let pcf_ratio_dataset = []
        let pcf_ratio_tickers = []

        let mkv_score_dataset = []
        let mkv_score_tickers = []
        for (const [key, value] of Object.entries(MKVS)) {
            
            pb_ratio_dataset.push([value[3], value[0], value[4]])
            pb_ratio_tickers.push({'ticker': key})

            ps_ratio_dataset.push([value[3], value[1], value[4]])
            ps_ratio_tickers.push({'ticker': key})
            
            pcf_ratio_dataset.push([value[3], value[2], value[4]])
            pcf_ratio_tickers.push({'ticker': key})

            mkv_score_dataset.push([value[3], value[8], value[4]])
            mkv_score_tickers.push({'ticker': key})
        }

        setpbRatio({
            series: [{  
                    name: 'P/B Ratio',
                    data: pb_ratio_dataset,
                    }],
                }
            )
        setpbTick({
            "Series1": pb_ratio_tickers,
        })

        setpsRatio({
            series: [{  
                name: 'P/S Ratio',
                data: ps_ratio_dataset,
            }],            
        })
        setpsTick({
            "Series1": ps_ratio_tickers,
        })

        setpcfRatio({
            series: [{  
                name: 'P/CF Ratio',
                data: pcf_ratio_dataset,
            }],            
        })
        setpcfTick({
            "Series1": pcf_ratio_tickers,
        })

        setmkv({
            series: [{  
                name: 'Market Value Score',
                data: mkv_score_dataset,
            }], 
        })
        setmkvTick({
            "Series1": mkv_score_tickers,
        })

        setLoading(false)
        return (() => {})
    }, [])


    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md: 18}, 
                        fontWeight: '300', 
                        mt: '1%',
                        lineHeight: 1.5}}>
                        This section switches from looking at a company's current market value and how it makes money to assessing its 
                        chances for future growth and profitability. This task involves looking into market prospect or value ratios, 
                        which are commonly used to compare companies within the same sector and identify undervalued or overvalued ones. 
                        While numerous market value ratios are available, this section specifically concentrates on three widely used 
                        metrics: price-to-book (P/B), and price-to-sales (P/S) and price-to-cashflow (P/CF) ratios.
                </Typography>

                <Sectionheader head={'3.5.1 Metric Definition'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Price to Book ratio (P/B): </Box> The price-to-book is a metric 
                        that compares a company's market value per share to its book value per share. The book value of a company is the value 
                        of its assets minus its liabilities, as stated on its balance sheet. The P/B ratio is often used by investors to evaluate 
                        the relative value of a stock, especially for companies that hold a lot of tangible assets, such as real estate or machinery. 
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            P/B Ratio = Share Price &frasl; Stock Holder Equity per Share 
                    </Typography>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Price to Sale Ratio (P/S): </Box> The price-to-sales ratio is an important 
                        metric used to evaluate the value of a company's stock by comparing its market capitalization to its revenue. It is calculated 
                        by dividing the current market capitalization of a company by its annual sales revenue. The resulting number indicates how much 
                        investors are willing to pay per dollar of revenue generated by the company.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            P/S Ratio = Share Price &frasl; Revenue per Share
                    </Typography>
                </Box>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{
                        fontSize: {xs: 16, md:18}, 
                        fontWeight: '300', 
                        lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Price to Cashflow Ratio (P/CF): </Box> The Price-to-Cash Flow is a financial ratio 
                        that compares a company's market price per share, current market value of one share of the company's stock, to its operating cash flow 
                        per share. It is calculated by dividing the market price per share by the operating cash flow, cash generated or used by a company's 
                        core operating activities per share.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            P/CF Ratio = Share Price &frasl; Operating cashflow per share
                    </Typography>
                </Box>

                <Sectionheader head={'3.5.2 Sector Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    P/B ratio compares a company's market cap to its book value on per share basis, while P/S ratio evaluates a 
                    company's stock price in relation to its revenue per share. P/CF ratio provides investors with insights into 
                    how the market values a company's revenue prospects (operating cash flow). Each of these metrics assess whether 
                    a company is overvalued or undervalued in relation to its intrinsic value, company's sales performance and cash 
                    generated by its operations, respectively. <Box fontWeight='fontWeightMedium' display='inline'>Figure 30</Box> to
                    <Box fontWeight='fontWeightMedium' display='inline'> 32</Box> show these metrics across the {sector} sector.
                </Typography>

                <MetricSector data={pbRatio} name={''} mjsn={pbTick}/>

                <Figurecaption caption={'Figure 30: P/B ratio vs. 1-year return across the sector'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>NOTE:</Box> A notably high Price-to-Book (P/B) ratio may imply that the market is 
                    attributing a significant premium to the company compared to its book value, indicating a potential overvaluation. Investors should 
                    exercise caution, particularly if the company's fundamentals or earnings fail to justify such an elevated ratio. This scenario might 
                    also indicate high growth expectations, accompanied by an increased risk of a correction in stock price if the company falls short of 
                    meeting these expectations.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Conversely, a very low P/B ratio might signal financial distress. In industries undergoing rapid changes, the book value may not accurately 
                    portray the company's current worth. Companies heavily reliant on intangible assets, such as brand or intellectual property, may exhibit 
                    a low P/B ratio, potentially leading to an underestimation of their true value.
                </Typography>

                <MetricSector data={psRatio} name={''} mjsn={psTick}/>

                <Figurecaption caption={'Figure 31: P/S ratio vs. 1-year return across the sector'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>NOTE:</Box> Both very high and very low Price to Sales (P/S) ratios can 
                    indicate potential issues. A very high P/S ratio may suggest that the market is valuing the company's sales disproportionately 
                    higher than its actual revenue generation. This could be due to high market expectations, speculative trading, or even a market 
                    bubble, leading to overvaluation. Investors might be anticipating continuous, rapid growth in sales. If the company fails to 
                    meet these high expectations, it could lead to a significant correction in the stock price. On the other hand, a very low P/S 
                    ratio may signal financial distress or a lack of investor confidence. Investors and analysts might be skeptical about the 
                    company's ability to generate revenue or foresee sustainable growth. In some cases, a low P/S ratio might indicate that the 
                    market is undervaluing the company, potentially missing its true growth potential. This could present an opportunity for value 
                    investors.
                </Typography>

                <MetricSector data={pcfRatio} name={''} mjsn={pcfTick}/>

                <Figurecaption caption={'Figure 32: P/CF ratio vs. 1-year return across the sector'}/>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>NOTE:</Box> A low P/CF ratio may suggest that the stock is undervalued relative to 
                    its operating cash flow. Readers might interpret this as an opportunity, especially if the company's cash flow is robust and sustainable.
                    A high P/CF ratio may indicate that the stock is trading at a premium relative to its operating cash flow. Analysts may scrutinize the 
                    company's financials to determine whether the high ratio is justified by strong fundamentals and growth prospects. Investors often look 
                    at the historical trend of a company's P/CF ratio to identify patterns or changes in market sentiment. P/CF ratios vary across 
                    significantly industries, so it's essential to compare a company's P/CF to those of its industry peers.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Table 7</Box> presents statistical distribution criteria for the {sector} sector, offering 
                    valuable insights for readers interested in assessing a company's relative value. 
                </Typography>

                <Figurecaption caption={'Table 7: Statistics of market value ratios distribution'}/>

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats table={Statistics}/>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    For instance, the mean P/S ratio across the sector suggests that, on average, investors are willing to pay <Box fontWeight='fontWeightMedium' 
                    display='inline'>${Statistics['P/S Ratio']['mean']}</Box> for every dollar of a company's sales per share. Using this information, one can 
                    estimate that a company with $1,000 in sales per share should have a price of approximately <Box fontWeight='fontWeightMedium' display='inline'>
                    ${1000 * Statistics['P/S Ratio']['mean']}</Box>. These insights are particularly useful for identifying undervalued or overvalued companies 
                    within the sector. The mean price-to-book ratio indicates that, on average, investors pay <Box fontWeight='fontWeightMedium' display='inline'>
                    ${1000 * Statistics['P/B Ratio']['mean']}</Box> for every $1,000 of equity in the company. Simultaneously, every $1,000 of operational cash flow 
                    is priced at <Box fontWeight='fontWeightMedium' display='inline'>${1000 * Statistics['P/CF Ratio']['mean']}</Box>.
                </Typography>

                <Sectionheader head={'3.5.3 Scoring Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Based on the distribution of each metric across the sector, an individual score is assigned to each market value ratio. A composite 
                    market value score is then developed based on the weighted average of these scores, with different weights assigned to various ratios 
                    based on their significance in valuing the company. <Box fontWeight='fontWeightMedium' display='inline'>Figure 33</Box> presents the
                    distribution of market value composite scores against winsorized 1-year return values for companies in the {sector} sector.
                </Typography>

                <ScoreSector series={mkv} labels={mkvTick}/>

                <Figurecaption caption={'Figure 33: Composite (total) market value score vs. 1-year return'}/>
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    In conclusion, we have collected the composite market value scores for the entire sector and ranked them. The top 10 highest composite 
                    scores are shown in <Box fontWeight='fontWeightMedium' display='inline'>Figure 34</Box>. As of now, ticker <Box fontWeight='fontWeightMedium' 
                    display='inline'>{highScore.names[0]}</Box> holds the highest overall score of <Box fontWeight='fontWeightMedium' display='inline'>
                    {highScore.data[0]}</Box> among these stocks. 
                    {/* <Box fontWeight='fontWeightMedium' display='inline'>Tables 21</Box> and <Box fontWeight='fontWeightMedium' display='inline'>22</Box> present 
                    the market value scores and metrics for the top 20 companies, respectively. */}
                </Typography>

                <ScoreTop10 data={highScore.data} names={highScore.names} label={''}/>

                <Figurecaption caption={'Figure 34: Companies with highest total market value score'} />

                {/* <Figurecaption caption={'Companies with highest market value score'}/>

                <Box>
                    <ScoreTable table={scoreBreakdown} score={['P/B', 'P/S', 'P/CF']}/>
                </Box> */}

            </Box>
            }
        </div>
    )

}