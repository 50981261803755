import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";
import { Sectionheader, Figurecaption } from './reportBlocks';
import { MetricsStats, ScoreTable } from './tableblocks';
import { MetricSector, ScoreTop10, ScoreSector } from './figureBlocks';

export default function Solvency({children, sector, input}) {

    // const theme = useTheme()
    const [loading, setLoading] = useState(true)

    const SOLV = input.data
    const Statistics = input.stats
    const highScore = input.highScore
    let pron
    // const scoreBreakdown = props.input.topScores

    const debt_times = Math.round(
        10 * (Statistics['D/C Ratio']['mean'] / (1 - Statistics['D/C Ratio']['mean']))
    ) / 10

    if (highScore.data[0] === 10) {
        pron = 'a perfect '
    } else {
        if (highScore.data[0] >= 8.5) {
            pron = 'an impressive '
        } else {
            pron = 'a considerable '
        }
    }

    const [daRatio, setDaRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })

    const [daTick, setDaTick] = useState({
        "Series1": []
        }
    )

    const [deRatio, setDeRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })

    const [deTick, setDeTick] = useState({
        "Series1": []
        }
    )

    const [dcRatio, setDcRatio] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })

    const [dcTick, setDcTick] = useState({
        "Series1": []
        }
    )

    const [solvScore, setSolvScore] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [solvTick, setSolvTick] = useState({
        "Series1": [],
    })

    useEffect( () => {

        // Blank variables
        let da_ratio_dataset = []
        let da_ratio_tickers = []

        let de_ratio_dataset = []
        let de_ratio_tickers = []

        let dc_ratio_dataset = []
        let dc_ratio_tickers = []

        let solv_score_dataset = []
        let solv_score_tickers = []

        for (const [key, value] of Object.entries(SOLV)) {
            // Solvency ratios
            da_ratio_dataset.push([value[3], value[0], value[4]])
            da_ratio_tickers.push({'ticker': key})
        
            de_ratio_dataset.push([value[3], value[1], value[4]])
            de_ratio_tickers.push({'ticker': key})
           
            dc_ratio_dataset.push([value[3], value[2], value[4]])
            dc_ratio_tickers.push({'ticker': key})
           
            solv_score_dataset.push([value[3], value[8], value[4]])
            solv_score_tickers.push({'ticker': key})
        }

        setDaRatio({
            series: [{  
                        name: 'D/A Ratio',
                        data: da_ratio_dataset,
                    }],
                }
            )
        setDaTick({
            "Series1": da_ratio_tickers,
            }
        )

        setDeRatio({
            series: [{  
                        name: 'D/E Ratio',
                        data: de_ratio_dataset,
                    }],
                }
            )
        setDeTick({
            "Series1": de_ratio_tickers,
            }
        )

        setDcRatio({
            series: [{  
                        name: 'D/C Ratio',
                        data: dc_ratio_dataset,
                    }],
                }
            )
        setDcTick({
            "Series1": dc_ratio_tickers,
            }
        )

        setSolvScore({
            series: [{  
                        name: 'Liquidiy Score',
                        data: solv_score_dataset,
                    }],
                }
            ) 
        setSolvTick({
            "Series1": solv_score_tickers,
        })

        setLoading(false)
    }, [])


    return(
        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md: 18}, fontWeight: '300', lineHeight: 1.5}}>
                    Having analyzed the liquidity ratios of companies in the sector, we now shift our focus to solvency ratios, which 
                    are vital financial metrics for evaluating a company's ability to meet its long-term financial obligations. These 
                    ratios are crucial for investors and analysts to gauge the financial risk associated with a company. This section
                    explores three key solvency metrics: Debt to Assets (D/A), Debt to Equity (D/E), and Debt to Capitalization (D/C) 
                    ratios. 
                </Typography>

                <Sectionheader head={'3.2.1 Metric Definition'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Debt to Asset (D/A) ratio: </Box> D/A metric ratio serves as a 
                    crucial indicator of a company's financial stability and risk, measuring its ability to meet long-term debt obligations. 
                    In essence, the debt to asset ratio measures the proportion of a company's assets effectively owned by creditors, 
                    representing the share of assets encumbered by debt obligations.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Debt to Asset Ratio =  Total Debt &frasl; Total Assets
                    </Typography>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Debt-to-Equity (D/E) ratio: </Box> The D/E, also referred to as 
                    “risk ratio”, is a metric that computes the proportion of total debt and financial liabilities relative to total shareholders' 
                    equity. This ratio provides insight into a company's capital structure, indicating whether it leans more towards debt 
                    or equity financing. Total debt includes short-term debt, long-term debt, and other fixed payment obligations that arise 
                    during normal operating cycles for the company.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Debt to Equity Ratio = Total Debt &frasl; Shareholder’s Equity
                    </Typography>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Debt-to-Capitalization (D/C) ratio: </Box> The D/C ratio is another 
                    vital financial metric used to gauge a company's capital structure. It assesses the proportion of a company's capital 
                    financed by debt, including both short-term and long-term liabilities. This ratio offers insights into the degree of 
                    financial leverage a company utilizes to sustain its operations and investments.
                </Typography>

                <Box sx={{ width: '90%', alignSelf: 'center' }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Debt to Capitalization Ratio = Total Debt &frasl; (Total Debt + Shareholder's Equity)
                    </Typography>
                </Box>

                <Sectionheader head={'3.2.2 Sector Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    To align with our methodology, these three ratios for the companies in the {sector} sector were computed and winsorized at 
                    the top and bottom 2 percentiles. <Box fontWeight='fontWeightMedium' display='inline'> Figures 15-17</Box> present each ratio 
                    versus the company's 1-year return for all tickers in the sector. Reader must note that very low solvency ratios do not 
                    inherently signal a positive or negative verdict. For instance, low debt to asset (D/A) ratio signifies that the company has 
                    chosen to prioritize capital generation through issuing stocks to investors rather than obtaining loans. A lower ratio means lower 
                    interest payments, but it also means less profit left for owners, as shareholders have a claim to some of the company's earnings. 
                    It's worth noting that what's considered a good debt to asset ratio can vary between industries. Capital-intensive industries 
                    like manufacturing or utilities might naturally have higher debt-to-asset ratios due to the substantial investments required in 
                    assets.
                </Typography>

                <MetricSector data={daRatio} name={''} mjsn={daTick}/>

                <Figurecaption caption={'Figure 15: D/A ratio vs. 1-year return across the sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Note: </Box>A negative D/E ratio indicates negative shareholder equity, 
                    implying that the company's liabilities surpass its assets. Generally, such a scenario is regarded as indicative of substantial risk 
                    and may prompt consideration of seeking bankruptcy protection.
                </Typography>

                <MetricSector data={deRatio} name={''} mjsn={deTick}/>

                <Figurecaption caption={'Figure 16: D/E ratio vs. 1-year return across the sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Note: </Box>A high D/C ratio indicates a greater reliance on debt financing, 
                    while a lower ratio suggests a more equity-oriented capital structure. A lower D/C ratio can also indicate that a company is better 
                    positioned to handle financial downturns or market uncertainties since a smaller proportion of its capital is tied to debt that 
                    needs servicing. However, what is considered a good ratio differs among industries. For example, capital-intensive sectors like 
                    utilities may have higher acceptable D/C ratios due to the long-term nature of their investments.
                </Typography>

                <MetricSector data={dcRatio} name={''} mjsn={dcTick}/>

                <Figurecaption caption={'Figure 17: D/C ratio vs. 1-year return across the sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Table 4</Box> presents the maxima, mean ({'\u03BC'}), standard deviation 
                    ({'\u03C3'}) of the solvency metric distributions, providing insights into the average solvency of and the capital structure of 
                    the companies in the sector.
                </Typography>

                <Figurecaption caption={'Table 4: Statistics of solvency ratios distribution'} />

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats table={Statistics}/>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    The statistics tell a compelling narrative about the {sector} sector. On average, companies in this sector have 
                    <Box fontWeight='fontWeightMedium' display='inline'> ${1000 * Statistics['D/E Ratio']['mean']}</Box> in debt for every 
                    $1,000 in shareholder's equity (1000 x mean D/E), signifying that their total debt amounts to <Box display='inline' 
                    fontWeight='fontWeightMedium'>{debt_times}</Box> times their total equity. Furthermore, companies in the sector currently 
                    hold <Box fontWeight='fontWeightMedium' display='inline'> ${1000 * Statistics['D/A Ratio']['mean']}</Box> in debt for every 
                    $1,000 in assets (1000 x mean D/A) on their balance sheets.
                </Typography>


                <Sectionheader head={'3.2.3 Scoring Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Similar to the previous section, a score between <Box fontWeight='fontWeightMedium' display='inline'>1</Box> to <Box 
                        fontWeight='fontWeightMedium' display='inline'>10</Box> assigned to each company for every solvency metric. Companies 
                        with lower ratios compare to their peers are associated with higher scores. However, the scoring system still panelizes 
                        companies with very low ratios for various reasons. <Box fontWeight='fontWeightMedium' display='inline'>Figure 18</Box> demonstrates  
                        the calculated total (composite) solvency score for the companies across the {sector} sector. The total 
                        score is calculated as the weighted average of the individual solvency metric, according to their significance and the 
                        sector's dynamic. 
                </Typography>

                <ScoreSector series={solvScore} name={''} labels={solvTick}/>

                <Figurecaption caption={'Figure 18: Total solvency scores vs. 1-year return ' + sector + ' sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        While having a low solvency (debt) ratio might seem like a positive sign on the surface, excessively low debt ratios can 
                        also be detrimental. Some level of debt provides financial flexibility. In times of opportunities or emergencies, having 
                        access to debt can be beneficial. It is a source of cheaper capital (at the right time) compared to equity. By avoiding 
                        debt entirely, a company might be missing out on the tax benefits associated with interest payments. 
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Interest on debt is tax-deductible, which can reduce the overall cost of capital for the company. Debt can magnify the 
                        return on equity. When a company earns a return on its investments that is higher than the cost of debt, it enhances 
                        shareholder returns. In Addition, investors and creditors often assess a company's use of debt as a sign of confidence in its 
                        operations. Moreover, by judiciously using debt to finance projects or investments, a company can potentially enhance 
                        shareholder value. If the return on investment exceeds the cost of debt, it can contribute positively to shareholder wealth.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Figure 19</Box> offers representation of the composite solvency scores for the sector's top 
                    10 companies. Notably, ticker <Box fontWeight='fontWeightMedium' display='inline'>{highScore.names[0]}</Box> takes the lead with {pron} 
                    overall score of <Box fontWeight='fontWeightMedium' display='inline'>{highScore.data[0]}</Box>. 
                </Typography>

                <ScoreTop10 data={highScore.data} names={highScore.names} label={''}/>

                <Figurecaption caption={'Figure 19: Top 10 companies with highest total solvency score'} />

            </Box>
            }
        </div>
    )

}