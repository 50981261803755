import React from "react";
import { Typography, Container, Box } from "@mui/material";
import Layout from "../components/layout";
import { Helmet } from 'react-helmet';

export default function Termspage() {

    return(
        <div>
            <Helmet>
                <title>FARSIGHT REPORT terms of service</title>
                <meta name="description" content={'FARSIGHT REPORT TERMS OF SERVICE'} />
                <meta name="keywords" content="sector report, online purchase, FARSIGHT REPORT TERMS OF SERVICE" />

                <meta property="og:title" content={'FARSIGHT REPORT TERMS OF SERVICE'} />
                <meta property="og:description" content={'FARSIGHT REPORT TERMS OF SERVICE'} />
                {/* <meta property="og:image" content={URL of the image you want to use} /> */}
                <meta property="og:url" content={'https://farsight.report/terms'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'FARSIGHT REPORT TERMS OF SERVICE'} />
                <meta name="twitter:description" content={'FARSIGHT REPORT TERMS OF SERVICE'} />
            </Helmet>
            <Layout>
                <Container maxWidth='lg'>

                    <Box sx={{
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center'}}>
                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: 48, 
                                fontWeight: 800, 
                                pt: '4%',
                                pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                FARSIGHT REPORT TERMS OF SERVICE
                        </Typography>

                        <Typography 
                            textAlign={'center'} 
                            sx={{
                                fontSize: 32, 
                                fontWeight: 600, 
                                width: '100%', 
                                height: '100%'}}>
                                Effective Date: May 12, 2023
                        </Typography>
                    </Box>

                    <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: {xs: 16, md: 20}, 
                                fontWeight: 300,
                                pt: '2%', 
                                pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                Welcome to FARSIGHT. These Terms of Service ("Terms") constitute a legally binding agreement between you and FARSIGHT, Inc. ("FARSIGHT") governing 
                                your access and use of farsight.reports ("the Site") and the software, services and information made available through the Site (collectively the 
                                "FARSIGHT Services"). By clicking on the "I agree to your Terms of Service and Privacy Policy" button when establishing an account and/or using the 
                                Site and FARSIGHT Services in any manner, you agree to be bound by these Terms and consent to the use of information collected on the Site as disclosed 
                                in our Privacy Policy. Continued use of the Site or FARSIGHT Services constitutes acceptance of any additional terms or policies posted on the Site. If 
                                you do not want to be bound by our Terms, you are not allowed use the FARSIGHT Services.                               
                            </Typography>
                        </Box>

                    </Box>

                    <Box sx={{display: "flex", width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>

                        <Box  sx={{display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: 20, 
                                fontWeight: 400, 
                                lineHeight: 2,
                                pt: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                1. DEFINITIONS
                            </Typography>
                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Typography textAlign={'justify'} sx={{
                                fontSize: {xs: 14, md: 16}, 
                                fontWeight: 300, 
                                pt: '1%',
                                width: '100%', 
                                height: '100%'}}>
                                User: Anyone who accesses or uses the Site or FARSIGHT Services for any purpose whatsoever.
                            </Typography>

                            <Typography textAlign={'justify'} sx={{
                                fontSize: {xs: 14, md: 16}, 
                                fontWeight: 300, 
                                // lineHeight: 2,
                                pt: '1%',
                                // pb: '2%',
                                width: '100%', 
                                height: '100%'}}>
                                User Content: Any personal information submitted in creating a Farsight Account, as well as any information submitted to website pursuant to use of the 
                                Farsight services, including, but not limited to, studies, subscriptions, and/or digital or photographic images, text, or audio files.

                            </Typography>
                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    2. GENERAL RULES ABOUT CREATING ACCOUNTS
                                </Typography>
                            </Box>
                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Eligibility: To be eligible to create a Farsight Account, you must be at least 18 years of age. If you are under 18, you may not attempt to 
                                    create an account or use the Farsight report services.
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Use of the Farsight report: To access the Farsight report, you must have a Farsight report Account established through the online registration 
                                    process and have agreed to these Terms.                                   
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Account Security: When you register, you will be asked to choose a user name and a password. You are responsible for safeguarding your password 
                                    and agree not to disclose your password to any third party. You are solely responsible for any activities or actions taken under your password, 
                                    including purchasing reports or subscriptions, whether or not you have authorized such activities or actions.
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    We care about your security, so please immediately notify Farsight report of any unauthorized use of your password. 
                                </Typography>
                            </Box>

                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    3. RULES ABOUT YOUR USE OF OUR SITE AND SERVICES
                                </Typography>
                            </Box>
                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography 
                                    textAlign={'justify'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300, 
                                        pt: '1%',
                                        width: '100%', 
                                        height: '100%'}}>
                                    You May Not Misuse Our Site or Services: This means, for example (but without limitation), that you may not do things like data mining, 
                                    database stealing, scraping, reformatting or framing, interfere with FARSIGHT Services' functions, circumvent our security, or help third 
                                    parties misuse the FARSIGHT Services in any way. You are only allowed to use the FARSIGHT Services as permitted by law. If we suspect that 
                                    you are not complying with our Terms, Policies, or the law, we may suspend or stop providing the FARSIGHT Services to you.   
                                </Typography>

                                <Typography                                    
                                    textAlign={'justify'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300, 
                                        pt: '1%',
                                        width: '100%', 
                                        height: '100%'}}>
                                    Removal of Information From Your Account at Any Time: Sometimes we may have to respond quickly to legal, technological, or other situations 
                                    that require us to modify or remove content on our Site. FARSIGHT, in its sole discretion, may remove any User Content from the Site, at any 
                                    time and for any or no reason.                                                  
                                </Typography>

                                <Typography
                                    textAlign={'justify'} 
                                    sx={{
                                        fontSize: {xs: 14, md: 16}, 
                                        fontWeight: 300, 
                                        pt: '1%',
                                        width: '100%', 
                                        height: '100%'}}>
                                    You Cannot Use FARSIGHT Content For Other Purposes Without Our Permission: Except as expressly provided in these Terms, you agree not to use, 
                                    modify, reproduce, distribute, sell, license, reverse engineer, or otherwise exploit FARSIGHT studie's and website's Content without our express 
                                    permission.
                                </Typography>
                            </Box>
                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    4. PURCHASE OF FARSIGHT SERVICES
                                </Typography>
                            </Box>
                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    FARSIGHT offers certain services to users on a subscription or fee-only basis subject to the following:
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Payment: All fee-based and subscription services are quoted on the Site in U.S. dollars. You agree to provide us with your debit or credit card information 
                                    prior to placing an order and you expressly authorize us to charge your debit or credit card to process the transaction. No Refunds: Fees will not be 
                                    prorated upon cancellation and/or termination and all fees paid through the date of termination are nonrefundable.
                                </Typography>

                            </Box>

                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    5. THIRD-PARTY CONDUCT, LINKS, SITES, AND SERVICES
                                </Typography>
                            </Box>
                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    When you use our Site and FARSIGHT Services, you may encounter links to third-party websites, services, events, or activities that are not owned or controlled 
                                    by FARSIGHT. FARSIGHT DOES NOT CONTROL ANY THIRD PARTY LINKS, SERVICES, GOODS, RESOURCES AND INFORMATION ON THE SITE. TO THE EXTENT PERMITTED BY APPLICABLE LAW, 
                                    FARSIGHT MAKES NO WARRANTIES REGARDING THIRD PARTY SERVICES, GOODS, RESOURCES AND INFORMATION INCLUDING, WITHOUT LIMITATION, WARRANTIES OF FITNESS FOR A PARTICULAR 
                                    PURPOSE, MERCHANTABILITY AND NON-INFRINGEMENT AND WILL NOT BE LIABLE FOR YOUR USE OF OR RELIANCE ON SUCH THIRD PARTY SERVICES, GOODS, RESOURCES OR INFORMATION. 
                                    FARSIGHT DISCLAIMS ANY AND ALL LIABILITY FOR THE ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR RELATED TO YOUR USE OF THE SITE AND/OR ANY 
                                    FARSIGHT SERVICES.
                                </Typography>
                            </Box>
                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    6. IDEA, SUGGESTION AND FEEDBACK SUBMISSIONS
                                </Typography>
                            </Box>

                            <Box sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    FARSIGHT welcomes all suggestions to improve our Site and FARSIGHT Services, but considers any feedback from its users to be helpful advice, 
                                    freely given. To ensure against any misunderstandings, we want to emphasize that we do not compensate for any ideas, proposals or suggestions 
                                    provided by its users to FARSIGHT in accordance with the following:                                
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Non-Proprietary: FARSIGHT considers any suggestions, ideas, proposals or other material submitted to it by users, whether solicited or unsolicited, 
                                    (collectively, the "Material") to be non-confidential and non-proprietary. FARSIGHT shall not be liable for the disclosure, use or exploitation of 
                                    such Material.
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    License: You hereby grant and agree to grant FARSIGHT, a worldwide, non-exclusive, perpetual, irrevocable, royalty-free and transferable right and 
                                    license to incorporate, use, publish and exploit the Material for any purpose whatsoever, commercial or otherwise, without compensation or accounting.
                                </Typography>
                            </Box>
                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    7. FARSIGHT's INTELLECTUAL PROPERTY
                                </Typography>
                            </Box>
                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Copyright: The Site and its logos, design, text, graphics, sound, images, content, data, applications, industry studies, and the selection, arrangement and organization 
                                    thereof, are owned by FARSIGHT. You may not use such materials without permission. You may not resell, distribute, publicly share information in the industry studies, 
                                    without written consent from FARSIGHT.
                                    Ownership and Use: Unless otherwise stated herein, nothing in these Terms or your use of the Site gives you a right or license to use any of FARSIGHT's copyrights, trade names, 
                                    trademarks, service marks, logos, domain names, trade secrets, or any other intellectual property rights.
                                    Trademark: FARSIGHT and its logos are trademarks owned by FARSIGHT. All custom graphics and scripts are trademarks or trade dress of FARSIGHT.
                                </Typography>
                            </Box>
                        </Box>     

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    8. WARRANTIES AND DISCLAIMERS
                                </Typography>
                            </Box>
                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    DISCLAIMER OF WARRANTIES: TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SITE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS, AND SERVICES INCLUDED ON 
                                    OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SITE, INCLUDING ANY "BETA" RELEASES, ARE PROVIDED ON AN "AS IS " AND "AS AVAILABLE " BASIS. FARSIGHT DOES 
                                    NOT REPRESENT OR WARRANT THAT THE SITE, ITS SERVICES OR STUDIES OFFERED (I ) WILL BE UNINTERRUPTED, (II ) WILL BE FREE OF DEFECTS, INACCURACIES OR ERRORS, 
                                    (III ) WILL MEET YOUR REQUIREMENTS, OR (IV ) WILL OPERATE IN THE CONFIGURATION OR WITH OTHER HARDWARE OR SOFTWARE YOU USE. EXCEPT WHERE PROHIBITED BY LAW, 
                                    FARSIGHT EXPRESSLY DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES OR CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED , INCLUDING, BUT NOT LIMITED TO, IMPLIED 
                                    WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY AND NON-INFRINGEMENT AND WILL NOT BE LIABLE FOR YOUR USE OF OR RELIANCE ON SUCH INFORMATION, 
                                    CONTENT, MATERIALS, PRODUCTS, AND SERVICES. 
                                </Typography>
                            </Box>
                        </Box>  

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    9. LIMITATION OF LIABILITY
                                </Typography>
                            </Box>
                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    LIMITATION OF LIABILITY: YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITE IS AT YOUR SOLE RISK. TO THE EXTENT PERMITTED BY APPLICABLE LAW, NEITHER 
                                    FARSIGHT NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE CONTENT, SITE, OR FARSIGHT SERVICES WILL BE LIABLE TO YOU OR ANY 
                                    THIRD PARTY FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, PUNITIVE OR SPECIAL DAMAGES (INCLUDING DAMAGES FOR LOST PROFITS, LOST DATA OR 
                                    LOSS OF GOODWILL) ARISING OUT OF, RELATING TO OR CONNECTED WITH THE USE OF THE SITE OR ITS SERVICES, EVEN IF FARSIGHT HAS BEEN ADVISED OF THE 
                                    POSSIBILITY OF SUCH DAMAGES.

                                    TO THE EXTENT PERMITTED BY APPLICABLE LAW , IN NO EVENT WILL FARSIGHT'S AGGREGATE LIABILITY, OR THAT OF ITS DIRECTORS, EMPLOYEES AND AGENTS, ARISING 
                                    OUT OF OR IN CONNECTION WITH YOUR USE OF, OR OF THE INABILITY TO USE, THE SITE OR FARSIGHT SERVICES, EXCEED TEN DOLLARS ($10). THE LIMITATIONS 
                                    OF LIABILITY SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND FARSIGHT.
                                </Typography>
                            </Box>
                        </Box>  

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    10. TERMINATION
                                </Typography>
                            </Box>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Without limitation of any other remedies, FARSIGHT may suspend or terminate any account or its FARSIGHT Services, restrict or prohibit access or use of the 
                                    Site or FARSIGHT Services, remove or restrict content, and/or take any technical or legal steps necessary if FARSIGHT:
                                </Typography>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    - Suspects a breach of these Terms
                                </Typography>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    - Is unable to verify or authenticate a User's information;
                                </Typography>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    - Believes that a User is acting inconsistently with the spirit of FARSIGHT's policies, has engaged in improper or fraudulent activity in connection 
                                    with the Site or that a user's actions may cause legal liability or financial loss to FARSIGHT's users or to FARSIGHT
                                </Typography>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    - Believes a User is infringing the rights of third parties;
                                </Typography>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    - A User fails to pay any applicable fees due to FARSIGHT; or
                                </Typography>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    - If the credit or debit card associated with a transaction should be rejected for any reason.
                                    Effect of Termination: If a User's account is terminated for any reason, User shall remain liable for all financial obligations and debts incurred through the 
                                    date of termination.
                                </Typography>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Survival: The following Sections survive termination of these Terms: Content License, Idea Submissions License, Warranties and Disclaimers, Limitation of 
                                    Liability, Individual Arbitration, Indemnification, and Governing Law.
                                </Typography>                                                                 
                            </Box>
                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    11. INDEMNITY
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    To the maximum extent permitted by law, you agree to indemnify, defend and hold harmless FARSIGHT and its subsidiaries, affiliates, officers, directors, 
                                    shareholders, employees, representatives, agents, volunteers, attorneys, managers, licensors, business partners and each of their respective successors 
                                    and assigns (the "Indemnified Parties") from and against all damages, losses, liabilities, claims, expenses, fees or costs (including, without limitation, 
                                    reasonable attorneys' fees and costs) incurred in connection with any claim, demand or action brought or asserted against any of the Indemnified Parties 
                                    arising out of or relating to (i) your use of the Site and FARSIGHT Services (ii) any dispute between you and any User of the Site and/or the FARSIGHT 
                                    Services, (ii) a breach of these Terms, (iii) a violation of any third party right, including without limitation any intellectual property right, publicity, 
                                    confidentiality, property or privacy right; or (iv) any claim that any User Content caused damage to any third party.
                                </Typography>
                            </Box>

                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    12. TRANSFER OF DATA AND EXPORT CONTROL
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    FARSIGHT's servers are located in this United States. When you establish an account or purchase a study through one-time payment or subscription, you are 
                                    authorizing the transfer of any personal data to the United States for administeration purposes.

                                    Any electronic transmission of information obtained from the Site originates in the United States and may be subject to United States
                                    export laws and regulations. Such software and technology may not be exported or re-exported to certain countries or those persons or entities prohibited from 
                                    receiving exports under U.S. law. In addition, software and technology obtained from FARSIGHT may be subject to the export laws of other countries. It is your 
                                    sole responsibility to comply with all U.S. and foreign export laws and regulations, and you may not, by electronic transmission or otherwise, violate any 
                                    applicable export control laws or regulations.

                                    You shall not access or use the Site or FARSIGHT Services if you are located in any jurisdiction in which the provision of the Site, FARSIGHT Services or other 
                                    components is prohibited under U.S. or other applicable laws or regulations (a "Prohibited Jurisdiction") and you shall not provide access to the Site, FARSIGHT 
                                    Services or other components to any government, entity or individual located in any Prohibited Jurisdiction. You represent, warrant and covenant that you (i) are 
                                    not named on any U.S. government list of persons or entities prohibited from receiving U.S. exports, or transacting with any U.S. person, (ii) are not a resident 
                                    of, or a company registered in, any Prohibited Jurisdiction, (iii) shall not permit agents or end users to access or use the Service in violation of any U.S. or 
                                    other applicable export embargoes, prohibitions or restrictions, and (iv) shall comply with all applicable laws regarding the transmission of technical data 
                                    exported from the United States and the country in which you, agents and end users are located.

                                </Typography>
                            </Box>

                        </Box>
                        
                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    13. COMPLAINTS - CALIFORNIA RESIDENTS
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    If you are a California resident, in accordance with California Civil Code Section 1789.3, you may report complaints to the Complaint Assistance 
                                    Unit of the Division of Consumer Affairs of the California Department of Consumer Affairs by contacting them in writing at: 400 R Street, Sacramento, 
                                    California 95814, or by telephone at (800) 952-5210.
                                </Typography>
                            </Box>

                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    14. FORCE MAJEURE
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    FARSIGHT will not be liable or responsible for any delays in service, for failing to provide the FARSIGHT Services or to operate the Site as a 
                                    result of any event beyond its reasonable control, including, without limitation, adverse weather conditions, internet outage or interruption of 
                                    service, power or telecommunications outage, fire, flood, civil disobedience, labor disruptions, strikes, lockouts, freight embargoes, terrorism, 
                                    natural disaster, war or acts of God.
                                </Typography>
                            </Box>

                        </Box>

                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    15. GENERAL TERMS
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Modification of Terms, Site, or Service: Our employees, volunteers or agents are not authorized to vary these Terms.
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Any authorized modifications to these Terms will be reflected on this page. (We may do so, for example, when changes in the law or the FARSIGHT Services 
                                    require us to alter our agreements with you). You should look at our Terms regularly, to make sure you are up to date. If any modifications are unacceptable 
                                    to you, your only remedy is to discontinue use of the Site and the FARSIGHT Services, and to request that FARSIGHT deactivate your account. If you continue 
                                    to access or use our Site or the FARSIGHT Services after we notify you of these changes, you will have agreed to be bound by those changes.
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    No Waiver: The failure or delay of either party to exercise any right, power or privilege under these Terms will not operate as a waiver thereof.

                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    No Agency: No agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship is intended or created by these Terms.

                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Governing Law: These Terms of Service shall be governed by, construed and enforced in accordance with the laws of the State of California, without regard to 
                                    or application of California's conflict of law principles. The parties consent to the exclusive jurisdiction of the State of California, and venue in th
                                    e County of San Francisco, with regard to any dispute, controversy or claim arising out of or relating to these Terms of Service, or the breach thereof and 
                                    waive all rights to contest this exclusive jurisdiction and venue of such courts. You and FARSIGHT both consent to personal jurisdiction in such courts.

                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Severability: If any term or section of these Terms is unenforceable, this unenforceability will not affect the enforceability of any other terms.
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Assignment: The Terms are not assignable, transferable or sublicenseable by you except with our prior written consent. Any assignment attempted in 
                                    violation of the Terms shall be void.
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Interpretation: Headings are for reference purposes only and do not limit the scope or extent of such section.

                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    Entire Agreement: These Terms are the entire agreement between you and FARSIGHT concerning our Site and the FARSIGHT Services.

                                </Typography>
                            </Box>

                        </Box>
                        
                        <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>

                            <Box  sx={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                                <Typography textAlign={'justify'} sx={{
                                    fontSize: 20, 
                                    fontWeight: 400, 
                                    lineHeight: 2,
                                    pt: '2%',
                                    width: '100%', 
                                    height: '100%'}}>
                                    
                                </Typography>

                                <Typography textAlign={'justify'} sx={{
                                    fontSize: {xs: 14, md: 16}, 
                                    fontWeight: 300, 
                                    pt: '1%',
                                    width: '100%', 
                                    height: '100%'}}>

                                </Typography>
                            </Box>

                        </Box>


                    </Box>
        <p>


            {/* // 3. ACCOUNT RULES FOR SPECIFIC ACCOUNT USERS



            // We care about your privacy and security! By using the FARSIGHT Services, you agree to the collection and use of information as disclosed in our Privacy Policy


            // 9. IDEA SUBMISSIONS




            // 10. FARSIGHT's INTELLECTUAL PROPERTY

            // Copyright: The Site and its logos, design, text, graphics, sound, images, content, data, applications, software and other files, and the selection, arrangement and organization thereof, are owned by FARSIGHT. You may not use such materials without permission.

            // © 2015 FARSIGHT, Inc. ALL RIGHTS RESERVED



            // 11. WARRANTIES AND DISCLAIMERS

            12. LIMITATION OF LIABILITY




            // 13. INDEMNITY


            // 14. Individual Arbitration

            // EXCEPT AS OTHERWISE STATED HEREIN, any claim or controversy with FARSIGHT arising out of or relating to the Site, the Services and/or these Terms of Service (including its formation, interpretation, performance and breach) shall be settled by binding arbitration administered by the International Centre for Dispute Resolution in accordance with its International Arbitration Rules, excluding any rules or procedures governing or permitting class actions. Any judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.

            // The parties acknowledge that these Terms of Service evidences a transaction involving interstate commerce. The Federal Arbitration Act, 9 U.S. C. §§ 1-16, shall govern any arbitration conducted pursuant to these Terms of Service.

            // ANY ARBITRATION UNDER THIS AGREEMENT WILL BE ON AN INDIVIDUAL BASIS ONLY. THE PARTIES EXPRESSLY WAIVE THEIR RIGHT TO FILE OR JOIN A CLASS ACTION OR PRIVATE ATTORNEY GENERAL ACTION, OR TO CONSOLIDATE THEIR ARBITRATION WITH OTHER ARBITRATIONS. YOU ARE WAIVING YOUR RIGHTS TO HAVE YOUR CASE DECIDED BY A JUDGE OR JURY. IF ANY PROVISION OF THIS ARBITRATION AGREEMENT IS FOUND UNENFORCEABLE, THE UNENFORCEABLE PROVISION SHALL BE SEVERED AND THE REMAINING PROVISIONS SHALL REMAIN ENFORCEABLE.

            // The ICDR's rules, as well as forms for initiating arbitration proceedings, are available at www.icdr.org . When initiating a request to arbitrate, you must also send a copy of the completed form to FARSIGHT, 330 Townsend St, Suite1, San Francisco CA 94107 or by email at support@FARSIGHT.com.

            // Unless otherwise agreed to in writing by the parties, any dispute arising from these Terms of Service shall be conducted in English at the following location: San Francisco, California, United States

            // 15. TERMINATION


            // 16. TRANSFER OF DATA AND EXPORT CONTROL


            // 17. FORCE MAJEURE


            // 18. GENERAL TERMS



            // 19. CUSTOMER SUPPORT

            // FARSIGHT is available by email at support@FARSIGHT.com to address any issues you may have regarding your use of the Site. Most concerns can be quickly resolved in this manner.

            // 20. COMPLAINTS - CALIFORNIA RESIDENTS
             */}

        </p>

                </Container>
            </Layout>
        </div>
    )
}