import React , { useEffect, useState } from "react";
// import Layout from "../components/layout";
import { Box, Typography, CircularProgress} from "@mui/material";
// import { alpha, useTheme } from '@mui/material/styles';

// import { setDoc, doc, getDocs, getDoc, onSnapshot, collection } from "firebase/firestore";
// import { storage } from '../firebase/index';
// import { ref, getDownloadURL } from "firebase/storage";
// import Chart from "react-apexcharts";
import { Figurecaption, Sectionheader } from './reportBlocks';
import { MetricsStats } from './tableblocks';
import { MetricSector, ScoreTop10, ScoreSector } from './figureBlocks';

export default function Liquidity({children, sector, input}) {

    // const[CAR, setCAR] = useState('')

    const LIQS = input.data
    const Statistics = input.stats
    const highScore = input.highScore
    // // const scoreBreakdown = input.topScores

    const inventory_percent = Math.round(
        (Statistics['Current Ratio']['mean'] - Statistics['Quick Ratio']['mean']) * 100) / 100

    // const theme = useTheme()
    const [loading, setLoading] = useState(true)

    const [current, setCurrent] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [curTick, setCurTick] = useState({
        "Series1":[]
        }
    )

    const [quick, setQuick] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [qucTick, setQucTick] = useState({
        "Series1": []
        }
    )

    const [cash, setCash] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [cashTick, setCashTick] = useState({
        "Series1": []
        }
    )

    const [liquidity, setLiquidity] = useState({
        series: [{
            data : []
            }
        ],
        options: {
            chart: {
                type: 'bubble',
            },
        }
    })
    const [liqTick, setLiqTick] = useState({
        "Series1": [],
    })

    useEffect(() => {

        // EAMPLE: Replacing the chart with figure
        // const pathReference = ref(
        //     storage, 
        //     'gs://farsight-report.appspot.com/technology/20240204/Cash Ratio'
        //     );
        // getDownloadURL(pathReference)
        // .then( (url) => {setCAR(url)})

        // Current ratios
        let current_ratio_dataset = []
        let current_ratio_tickers = []
        // Quick ratios
        let quick_ratio_dataset = []
        let quick_ratio_tickers = []
        // Cash ratios
        let cash_ratio_dataset = []
        let cash_ratio_tickers = []
        // Liuidity Scores
        let liq_score_dataset = []
        let liq_score_tickers = []
        for (const [key, value] of Object.entries(LIQS)) {
            // Current ratios
            current_ratio_dataset.push([value[3], value[0], value[4]])
            current_ratio_tickers.push({'ticker': key})
            // Quick ratios
            quick_ratio_dataset.push([value[3], value[1], value[4]])
            quick_ratio_tickers.push({'ticker': key})
            // Cash ratios
            cash_ratio_dataset.push([value[3], value[2], value[4]])
            cash_ratio_tickers.push({'ticker': key})
            // Liquidity Score
            liq_score_dataset.push([value[3], value[8], value[4]])
            liq_score_tickers.push({'ticker': key})
        }

        setCurrent({
            series: [{  
                        name: 'Current Ratio',
                        data: current_ratio_dataset,
                    }],
                }
            )
        setCurTick({
            "Series1": current_ratio_tickers,
            }
        )

        setQuick({
            series: [
                    { 
                        name: 'Quick Ratio', 
                        data: quick_ratio_dataset, 
                    },
                ],
            })
        setQucTick({
            "Series1": quick_ratio_tickers,
        })

        setCash({
            series: [
                    { 
                        name: 'Cash Ratio', 
                        data: cash_ratio_dataset, 
                    },
                ],
            })
        setCashTick({
            "Series1": cash_ratio_tickers,
        })

        setLiquidity({
            series: [{  
                        name: 'Liquidiy Score',
                        data: liq_score_dataset,
                    }],
                }
            )
        setLiqTick({
            "Series1": liq_score_tickers,
        })

        setLoading(false)

        return () => {}
    }, [ ])


    return(

        <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
            : 
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center', 
                alignItems: 'center', 
                width: '100%',}}>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    This chapter focuses on liquidity ratios, which are financial metrics used to evaluate a company's ability to meet its 
                    short-term financial obligations. They provide an indication of how well a company manages its current assets and 
                    liabilities. Current assets are a category of assets that are expected to be converted into cash or used up within one 
                    year or one operating cycle (whichever is longer). These assets are considered liquid, meaning they can be easily converted 
                    to cash. Creditors use liquidity ratios to make informed lending decisions, while investors use them to evaluate the 
                    company's financial stability and potential as a sound investment. The three most commonly used liquidity ratios are the 
                    current, quick, and cash ratios. 
                </Typography>

                <Sectionheader head={'3.1.1 Metric Definition'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Current ratio (CUR): </Box>The current ratio serves as a straightforward measure of 
                    liquidity, easily computed and understood. It assesses an organization's near-term solvency by dividing current assets by current 
                    liabilities.
                </Typography>

                <Box sx={{ 
                        width: {xs: '95%', md:'90%'}, 
                        alignSelf: 'center' 
                        }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Current Ratio = Current Assets &frasl; Current Liabilities
                    </Typography>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Quick ratio (QUR): </Box> The quick ratio, in contrast to the current ratio, 
                    applies a more stringent criterion to assess liquidity. It restricts its calculation to a subset of current assets, comprising cash 
                    and cash equivalents, along with accounts receivables, and eliminates certain items such as inventories and investments from consideration.
                </Typography>

                <Box sx={{
                    width: {xs:'95%', md:'90%'}, 
                    alignSelf: 'center' 
                    }}>

                    <Typography
                            component='div'
                            paragraph
                            textAlign={'justify'} 
                            sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                            Quick Ratio = (Current Assets - Inventories) &frasl; Current Liabilities
                    </Typography>
                     
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Cash ratio (CAR): </Box> The cash ratio is a liquidity metric that takes a more rigorous 
                    approach to assessing a company's liquidity. It focuses solely on the company's most liquid current assets, including cash and marketable 
                    securities (cash equivalents), which are readily available to meet the company's short-term obligations. By narrowing thescope of assets under 
                    consideration, the cash ratio offers a more precise measure of a company's ability tomeet its short-term financial obligations than other 
                    liquidity ratios.
                </Typography>

                <Box sx={{
                    width: {xs:'95%', md:'90%'}, 
                    alignSelf: 'center'
                    }}>
                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'} 
                        sx={{fontSize: {xs: 12, sm: 15, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Cash Ratio = (Cash + Marketable Securities) &frasl; Current Liabilities
                    </Typography>
                </Box>

                <Sectionheader head={'3.1.2 Sector Overview'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'} 
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Three above mentioned metrics were computed and winsorized at the top and bottom 2 percentiles for all companies in the sector. 
                    This approach allows us to get an accurate portrayal of the {sector} sector's dynamics and identifies potential outlier companies. 
                    <Box fontWeight='fontWeightMedium' display='inline'> Figures 10</Box> to <Box fontWeight='fontWeightMedium' display='inline'>12 </Box> 
                    present each ratio versus the company's 1-year return for all tickers in the sector. In the following figures, marker size is calculated 
                    based on the logarithm of each company's market capitalization (MCAP) in million dollars.
                </Typography>

                {/* <Box sx={{
                    border:1,
                    borderRadius: 2,
                    background: 'white',
                    width: {xs: '80%', md: '75%'}, 
                    height: {xs: '360px', md: '475px'}, 
                    }}>
                    <Box sx={{m: 2, width: '100%', height: '100%'}}>
                        <img src={CAR} alt="CAR ITEM" width={'95%'} height={'95%'}/>
                    </Box>
                </Box> */}

                <Figurecaption caption={'Figure 10: Current ratios vs. 1-year return across the sector'} />


                <MetricSector data={current} name={''} mjsn={curTick}/>

                <Figurecaption caption={'Figure 10: Current ratios vs. 1-year return across the sector'} />

                <MetricSector data={quick} name={''} mjsn={qucTick}/>

                <Figurecaption caption={'Figure 11: Quick ratios vs. 1-year return (winsorized)'} />

                <MetricSector data={cash} name={''} mjsn={cashTick}/>

                <Figurecaption caption={'Figure 12: Cash ratios vs. 1-year return across the sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    <Box fontWeight='fontWeightMedium' display='inline'>Table 3</Box> summarizes statistical parameters, such as the 
                    mean ({'\u03BC'}), and the standard deviation ({'\u03C3'}), as well as the maximum and minimum of each metric's 
                    distribution.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 12, md:14}, fontWeight: '600', lineHeight: 1.5}}>
                    Table 3: Statistics of liquidity ratios distribution
                </Typography>

                <Box sx={{mb: '2%', border: 1, width: {xs: '98%', sm: '85%', md: '75%'}}}>
                    <MetricsStats table={Statistics}/>
                </Box>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Statistical values provide readers with an understanding of the typical range of liquidity ratios within the sector. For instance, on 
                    average, companies in the sector hold <Box fontWeight='fontWeightMedium' display='inline'>${Statistics['Cash Ratio']['mean']}</Box> in 
                    cash and cash equivalents for every dollar in liabilities. In addition, readers can see that for every dollar of liability, companies
                    can pay off <Box fontWeight='fontWeightMedium' display='inline'>{100 * Statistics['Current Ratio']['mean']}%</Box> (100 times the mean 
                    current ratio) of their liabilities with their liquid assets. Moreover, we can conclude that inventories, on average, constitute <Box 
                    fontWeight='fontWeightMedium' display='inline'> {100*inventory_percent}%</Box> of companies current assets.
                </Typography>

                <Sectionheader head={'3.1.3 Scoring Overview'} />
                
                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Our proprietary scoring system assigns a score ranging from <Box fontWeight='fontWeightMedium' display='inline'>1</Box> to 
                        <Box fontWeight='fontWeightMedium' display='inline'> 10</Box> to each ratio, resulting in three individual scores for each 
                        company. These scores are computed by comparing the company's metrics to the joint actual distribution of each metric and the 
                        annual return across the {sector} sector. Generally, companies with higher ratios compared to their peers receive higher 
                        scores. However, it's important to note that this scoring system penalizes companies with very high liquidity ratios for 
                        various reasons.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        A very high liquidity ratio, particularly when it includes a substantial amount of cash and cash equivalents, may suggest that the 
                        company is not efficiently utilizing its resources. The funds held in highly liquid assets could have been invested in projects or 
                        assets with higher returns. This lack of investment might impact the company's overall profitability and growth potential. Additionally, 
                        it could indicate a deficiency in strategic planning or an inability to identify profitable investment opportunities, as perceived 
                        by analysts or investors. Lastly, it's worth noting that inflation erodes the purchasing power of money over time.
                </Typography>

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        A composite (total) liquidity score is computed for each company by calculating the weighted average of the individual scores. The 
                        assigned weight is determined by its relative importance and the sector's overall characteristics, through advance machine learning 
                        and statistical analyses. <Box fontWeight='fontWeightMedium' display='inline'>Figure 13</Box> illustrates the distribution of total 
                        liquidity scores compared to the winsorized 1-year returns for the entire sector.
                </Typography>

                <ScoreSector series={liquidity} name={''} labels={liqTick}/>

                <Figurecaption caption={'Figure 13: Composite (total) liquidity scores vs. 1-year return ' + sector + ' sector'} />

                <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        <Box fontWeight='fontWeightMedium' display='inline'>Figure 14</Box> shows the top 10 companies with highest total 
                        liquidity score. As of writing, <Box fontWeight='fontWeightMedium' display='inline'>{highScore.names[0]}</Box> is 
                        ranked first among these companies, with a total liquidity score of <Box fontWeight='fontWeightMedium' 
                        display='inline'>{highScore.data[0]}</Box>.
                </Typography>

                <ScoreTop10 data={highScore.data} names={highScore.names} label={'Liquidity score'}/>

                <Figurecaption caption={'Figure 14: Top 10 companies with highest liquidity score'} />

                {/* <Typography
                    component='div'
                    paragraph
                    textAlign={'justify'}
                    sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                    Companies with highest composite liquidity score across the {sector} sector are listed in <Box fontWeight='fontWeightMedium' display='inline'>
                    Table ZZ</Box>.
                </Typography>

                <Box>
                    <ScoreTable table={scoreBreakdown} score={['Current', 'Quick', 'Cash']}/>
                </Box> */}

            </Box>
            }
        </div>
    )
}