import React, { useEffect, createContext, useState, useContext } from "react";
// import { auth, createUserProfileDocument } from '../firebase';
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { CircularProgress, Box } from "@mui/material";
import { auth, db } from '../firebase/index'
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";

const UserAuthContext = createContext()

export function UserAuth() {
    return useContext(UserAuthContext)
}

export default function UserAuthContextProvider ({ children }) {

    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [stripeID, setStripeID] = useState(null)

    async function getStripeID(user) {
        const userRef = doc(db, "customers", user.uid)
        const docSnap = await getDoc(userRef)
        return [docSnap.data()];
    }

    function passwordReset (email) {
        return sendPasswordResetEmail(auth, email)
    }

    function signIn (email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }

    function singer() {
        return signOut(auth)
    }

    // Returns true if a user is signed-in.
    function isUserSignedIn() {
        return !!auth.currentUser;
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {

            setUser(currentUser)
            setLoading(false)

        })
        return () => {unsubscribe()}
    }, [])

    if (loading) { 
        return (
            <Box sx={{ 
                display:'grid',                     
                justifyContent:'center', 
                height: '100vh', 
                alignContent: 'center' }}>
                <CircularProgress size={48} />
            </Box>
    )} else {   
        return (
            <UserAuthContext.Provider value={{
                    user, 
                    stripeID, 
                    signIn, 
                    singer, 
                    isUserSignedIn, 
                    passwordReset
                    }}>
                {
                    children
                }
            </UserAuthContext.Provider>
        ) 
    }
}