import React from "react";
import { Typography, Box, Divider } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import ActiveSubBlock from "../components/activeSubBlock";

function Subscriptions(props) {

    const theme = useTheme()

    return(
        
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'center',
            border: 0.5,
            borderColor: theme.palette.border.main,
            borderRadius: '0.4rem',
            background: 'white',
            width: '90%',
            mt: '2%',
            }}>

            <Box sx={{
                width: '100%', 
                mt: '4%',
                mb: '2%',
                }}>
                <Typography 
                    textAlign={{
                        xs: 'center', 
                        sm: 'left'}}  
                    sx={{
                        ml: {sm: '5%'},
                        width: '100%',
                        fontSize: {xs: 20, sm: 26}, 
                        fontWeight: 500,
                        color: grey[700] 
                        }}>
                    Active Subscriptions
                </Typography>
            </Box>

            <Divider flexItem variant='middle' />
 
            <ActiveSubBlock subLoading={props.subLoading} activeSubs={props.activeSubs}/>

        </Box>
    )
}

export default Subscriptions