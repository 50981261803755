import React , { useEffect, useState } from "react";
import { Box, Typography} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { grey } from "@mui/material/colors";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
    //   backgroundColor: theme.palette.common.black,
      backgroundColor: grey[800],
      color: theme.palette.common.white,
    },
    // [`&.${tableCellClasses.body}`]: {
    //   fontSize: 14,
    // },
  }));

export default function SearchCard({input, ticker}) {

    const data = []
    const metrics = ['Liquidity', 'Solvency', 'Profitability', 'Efficiency', 'Mkt. Value', 'CashFlow', 'Management']
    metrics.forEach( (m) => {
        const name = m.charAt(0) + m.charAt(m.length-1)
        data.push({mt: m, sc: input[m], rk: input[name]})
    })

    return(
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%', 
                // background: 'purple'
                }}>

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '80%', 
                    // background: 'purple'
                    }}>
                    <Box sx={{width: '80%',}}>
                        <Typography
                            textAlign={'center'} 
                            sx={{
                                fontSize: 48, 
                                fontWeight: '500',
                                color: grey[800], 
                                lineHeight: 2}}>
                                {ticker}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', md: 'row'},
                        width: '120%',
                        }}>
                        <Box sx={{width: '100%',}}>
                            {<Typography
                                textAlign={'center'} 
                                sx={{
                                    pl: 1,
                                    fontSize: {xs: 20, md: 28}, 
                                    fontWeight: '400', 
                                    lineHeight: 1.5}}>
                                    Rank: # <Box fontWeight='600' display='inline'>{input.Te}</Box>
                            </Typography>}
                        </Box>

                        <Box sx={{width: '100%',}}>
                            <Typography
                                textAlign={'center'} 
                                sx={{
                                    pl: 1,
                                    fontSize: {xs: 20, md: 28}, 
                                    fontWeight: '400', 
                                    lineHeight: 1.5}}>
                                    Score: <Box fontWeight='600' display='inline'>{Math.round(100 * input['Total Score']) / 100}</Box>
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    display: 'flex', 
                    width: '75%', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    // borderRadius: '0.5rem',
                    mt: '1rem',
                    mb: '1rem',
                    border: 1, 
                    // background: 'orange'
                    }}>

                    <TableContainer component={'div'}>
                        <Table 
                            sx={{ minWidth: 300 }} 
                            aria-label="Ticker individual table">

                            <TableHead>
                                <TableRow>
                                    <StyledTableCell key={1} align='center'>Metric</StyledTableCell>
                                    <StyledTableCell key={2} align='center'>Score</StyledTableCell>
                                    <StyledTableCell key={3} align='center'>Rank</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data.map((row) => (
                                <TableRow
                                    key={row.mt}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        '&:nth-of-type(even)': {
                                            backgroundColor: grey[100],
                                            }, 
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: 'white',
                                            }, 
                                    }}
                                    >
                                    <TableCell align='center' component="th" scope="row">
                                        {row.mt}
                                    </TableCell>
                                    <TableCell align='center'>{Math.round(100 * row.sc) / 100}</TableCell>
                                    <TableCell align='center'>{row.rk}</TableCell>

                                </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Box>

            </Box>
        </>
    )
}