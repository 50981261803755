import React , { useEffect, useState } from "react";
import { Box, Typography, CircularProgress} from "@mui/material";
import { Figurecaption } from './reportBlocks';
import { RatioPie } from "./figureBlocks";

export default function Ratios({sector}) {

    const [loading, setLoading] = useState(true)

    useEffect( () => {

        setLoading(false)
        return( () => {})

    }, [])

    return(
       <div>
            { loading ? 
                <>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        width: '100%',
                        height: '85vh'}}>
                        <CircularProgress size={'4rem'}/>
                    </Box>
                </> 
                : 
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    width: '100%',}}>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        Ratio analysis is a fundamental tool for analyzing a company's financial health and comparing its performance
                        to that of its competitors. This study conducts and documents ratio analysis for seven (7) categories, as
                        illustrated in <Box fontWeight='fontWeightMedium' display='inline'>Figure 9</Box>. Each ratio category assesses 
                        a distinct aspect of the financial health and performance of the companies in the sector, such as their ability 
                        to generate sales, earn profits, or meet debt obligations.
                    </Typography>

                    <Box sx={{
                        border:1,
                        borderRadius: 2,
                        background: 'white',
                        width: {xs: '95%', sm: '85%', md: '65%', lg: '50%'}, 
                        height: {xs: '400px', sm: '440px'},
                        minWidth: '375px'
                        }}>
                        <Box sx={{m: 1, height: '100%'}}>
                            {RatioPie()}
                        </Box>
                    </Box>

                    <Figurecaption caption={'Figure 9: Breakdown of ratio analysis'} />

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        The layout of the analysis sections in this chapter is consistent. Each section begins by introducing financial 
                        metrics, detailing their definitions, and outlining their applications. Subsequently, these metrics are computed and 
                        presented for all companies within the {sector} sector, facilitating a comprehensive comparison and evaluation 
                        of their financial performance. To mitigate the impact of outliers, winsorization techniques are employed, ensuring 
                        the robustness of the analyses. Various statistical parameters for each metric are calculated and presented, 
                        providing readers with a practical benchmark for comparing the performance of any company in the sector.
                    </Typography>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        To facilitate sector-wide comparison, we've devised a proprietary scoring system. This system assigns scores 
                        from <Box fontWeight='fontWeightMedium' display='inline'>1</Box> to <Box fontWeight='fontWeightMedium' 
                        display='inline'>10</Box> to each metric, primarily considering the joint distribution of the metric and annual 
                        return across the sector, with attention to accepted practical values. Following this, a composite score is 
                        computed for each company, representing the weighted average of individual scores within its ratio category. 
                        This composite score acts as a valuable indicator of the overall financial health of the company in the given 
                        area and its performance compared to its peers. Distribution of the composite score versus 1-year return across 
                        the sector is presented at the closing of each section.
                    </Typography>

                    <Typography
                        component='div'
                        paragraph
                        textAlign={'justify'}
                        sx={{fontSize: {xs: 16, md:18}, fontWeight: '300', lineHeight: 1.5}}>
                        These comprehensive analyses not only aid in understanding each company's financial position but also shed
                        light on broader sector trends. By examining how companies within the {sector} sector fare across these ratio
                        categories, readers can gain deeper insights into industry dynamics and identify areas for improvement. The
                        commitment to providing clear, insightful analyses remains at the forefront of this study.
                    </Typography>

                </Box>
            }
        </div>
    )

}