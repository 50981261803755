import React from "react";
import Layout from "../components/layout";
import { Box, Typography } from "@mui/material";
import { Helmet } from 'react-helmet';

const NotFound = () => {
    return(
        <div>
            <Helmet>
                <title>Page not found.</title>
                <meta name="description" content={'page was not found'} />
                <meta name="keywords" content="sector report, online purchase, subscription, peer review" />

                <meta property="og:title" content={'Page not found.'} />
                <meta property="og:description" content={'page was not found'} />
                <meta property="og:type" content="website" />

                <meta name="twitter:title" content={'Page not found.'} />
                <meta name="twitter:description" content={'page was not found'} />
            </Helmet>
            <Layout>
                <Box sx={{height: '70vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    
                    <Typography textAlign={'center'} sx={{mt: '2%', mb: '2%', fontWeight: 400, fontSize: 28}}>
                        Unfortunately, we cannot find that page!
                    </Typography>

                </Box>
            </Layout>
        </div>
    )
}

export default NotFound